import { ContentfulAccountCreationInProgressPage } from "@/typings/contentful";
import { AccountCreationInProgressPageModel } from "@/typings/account-creation-in-progress-page";
import { Entry } from "contentful";
import { ContentfulKeys } from "./contentful-keys";
import ContentfulService from "./ContentfulService";

export default class AccountCreationInProgressPageContentfulService extends ContentfulService {
    
    public getAccountCreationInProgressPageContent(): Promise<AccountCreationInProgressPageModel> {
        return this.getContentfulEntry<ContentfulAccountCreationInProgressPage, AccountCreationInProgressPageModel>(
            ContentfulKeys.AccountCreationInProgressPage,
            (result: Entry<ContentfulAccountCreationInProgressPage>) => {
                return {
                    header: result.fields.header,
                    body: result.fields.body
                };
            }
        );
    }
}
