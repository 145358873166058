















































import { Component, Mixins } from "vue-property-decorator";
import { BRow, BCol } from "bootstrap-vue";
import InvestorZoneHeader from "./components/InvestorZoneHeader.vue";
import NavBar from "./components/navigation/NavBar.vue";
import NavBarButton from "./components/navigation/NavBarButton.vue";
import Overlay from "./components/navigation/Overlay.vue";
import Footer from "./components/footer/Footer.vue";
import Copyright from "./components/Copyright.vue";
import CompanyRegistration from "./components/CompanyRegistration.vue";
import FluidContainer from "./components/FluidContainer.vue";
import SessionExpiry from "./components/SessionExpiry.vue";
import { User } from "oidc-client";
import { environment } from "./environment";

import _ from "lodash";

import { Page } from "./typings/page";
import { Namespaces } from "./store/namespaces";
import { LOAD_NAVIGATION_PAGES, LOAD_INVESTOR_DETAILS } from "./store/action-types";
import { GET_NAVIGATION_PAGES, GET_INVESTOR_DETAILS, GET_COMPANY_INFORMATION } from "./store/getters";
import ErrorHandlerMixin from "./mixins/ErrorHandlerMixin";
import AuthMixin from "./mixins/AuthMixin";
import { mapGetters } from "vuex";
import { InvestorDetails } from "./typings/investor-details";
import { Routes } from "./constants/routes";
import { CompanyInformation } from "./typings/company-information";

@Component({
    components: {
        BRow,
        BCol,
        FluidContainer,
        InvestorZoneHeader,
        NavBar,
        NavBarButton,
        Overlay,
        Footer,
        Copyright,
        CompanyRegistration,
        SessionExpiry,
    },
    computed: mapGetters({
        getInvestorDetails: `${Namespaces.InvestorDetails}/${GET_INVESTOR_DETAILS}`,
        getNavigationPages: `${Namespaces.NavigationPages}/${GET_NAVIGATION_PAGES}`,
        getCompanyInformation: `${Namespaces.CompanyInformation}/${GET_COMPANY_INFORMATION}`,
    }),
})
export default class App extends Mixins(ErrorHandlerMixin, AuthMixin) {
    public navbarCollapseId = "nav-collapse";
    public displayOverlay = false;
    public isLoggedIn = false;
    public displayLogout = false;
    public getInvestorDetails!: InvestorDetails;
    public getCompanyInformation!: CompanyInformation;
    public getNavigationPages!: Page[];
    public sessionTimeoutLengthInSeconds = 1800;
    public sessionTimeoutWarningInSeconds = 60;

    public get shouldDisplayOverlay(): boolean {
        return this.displayOverlay;
    }

    public get displayWelcomeMessage(): boolean {
        return this.isLoggedIn && (this.$route == null || this.$route.name != "NotImpersonating");
    }

    /***********
     * Gets a list of all pages relevant to the investor's profile, enriched with addtional metadata required for page navigation.
     ***********/
    public get mappedNavigationPages(): Page[] {
        if (this.getNavigationPages && this.getInvestorDetails) {
            const navigationPages = _.clone(this.getNavigationPages);
            this.filterPagesToMatchInvestorProfile(navigationPages, this.getInvestorDetails);
            return this.enrich(navigationPages);
        }

        return [];
    }

    /***********
     * Remove all pages not relevant to the investor's profile.
     * @param pages A list of pages to filter on.
     * @param investor The investor currently logged in (or impersonated).
     ***********/
    private filterPagesToMatchInvestorProfile(pages: Page[], investor: InvestorDetails): void {
        if (!investor.hasStockTradeAccount) {
            const stockTradePage = pages.find((page) => page.key == Routes.Stocktrade.name);
            if (stockTradePage) {
                const index = pages.indexOf(stockTradePage);
                if (index > -1) {
                    pages.splice(index, 1);
                }
            }
        }
    }

    /***********
     * Enrich pages with matching metadata from the router.
     * - { href }
     * @pages A list of pages to enrich.
     ***********/
    private enrich(pages: Page[]): Page[] {
        return pages.map((page: Page) => {
            const route = this.$router.resolve({ name: page.key });

            return {
                ...page,
                href: route?.href,
            };
        });
    }

    public get isCompanyInfoLoaded(): boolean {
        return this.$store.state.companyInformation.isWhiteLabelLoaded;
    }

    public closeNavbarClicked(): void {
        this.displayOverlay = false;
    }

    public showNavbarClicked(): void {
        this.displayOverlay = true;
    }

    public created(): void {
        window.addEventListener("resize", this.windowResized);
        this.$store
            .dispatch(`${Namespaces.InvestorDetails}/${LOAD_INVESTOR_DETAILS}`)
            .catch((error) => this.handleError(error.response?.status));
        this.$store
            .dispatch(`${Namespaces.NavigationPages}/${LOAD_NAVIGATION_PAGES}`)
            .catch((error) => this.handleError(error.response?.status));

        this.loadIdleTimeoutConfiguration();
    }

    public destroyed(): void {
        window.removeEventListener("resize", this.windowResized);
    }

    public tagManagerIdTracking(InvestorEntityId: number): void {
        this.$gtm.trackEvent({
            InvestorId: InvestorEntityId,
        });
    }

    public mounted() {
        this.getAuthenticatedUser().then((user: User) => {
            if (user) {
                this.isLoggedIn = true;

                if (user.profile && user.profile.investor_context) {
                    this.displayLogout = false;
                } else {
                    this.displayLogout = true;
                }
                if (user.profile) {
                    this.$datadog.registerGlobalContext(user);
                    this.$pendo.registerPendoUserContext(user);
                    this.tagManagerIdTracking(user.profile.entity_id);
                }
            }
        });

        if (this.$isWealthTime) {
            const links = document.querySelectorAll("link[rel~='icon']");
            
            if (links && links.length > 0) {
                links.forEach((link) => 
                    (link as HTMLAnchorElement).href = `${window.location.origin}/img/icons/wealthtime/favicon.ico`
                )
            }
        }
    }

    private windowResized(e: UIEvent): void {
        _.debounce(() => {
            const currentTarget = e.currentTarget as Window;
            if ((this.displayOverlay = true && currentTarget?.innerWidth > 992)) {
                this.displayOverlay = false;
            }
        }, 500)();
    }

    private loadIdleTimeoutConfiguration() {
        if (!isNaN(environment.maxIdleTimeInMinutes)) {
            this.sessionTimeoutLengthInSeconds = parseInt(environment.maxIdleTimeInMinutes) * 60;
        }

        if (!isNaN(environment.idleTimeoutWarningInSeconds)) {
            this.sessionTimeoutWarningInSeconds = parseInt(environment.idleTimeoutWarningInSeconds);
        }
    }
}
