import { environment } from "@/environment";
import { datadogRum } from "@datadog/browser-rum";
import { User } from "oidc-client";

export default class DataDogService {
    constructor() {
        datadogRum.init({
            applicationId: environment.datadogApplicationId,
            clientToken: environment.datadogClientToken,
            site: environment.datadogSite,
            service: environment.datadogService,
            env: environment.datadogEnv,
            version: environment.datadogVersion,
            resourceSampleRate: 100,
            sampleRate: 100,
            silentMultipleInit: false,
            trackInteractions: true
        });
    }

    public registerGlobalContext(user: User): void {
        if (user) {
            datadogRum.addRumGlobalContext("usr", {
                id: user.profile.id,
                usertype: user.profile.user_type,
                role: user.profile.role,
                entityid: user.profile.entity_id
            });
        }
    }
}
