















































import { Component, Prop, Vue } from "vue-property-decorator";

import WrapperSummaryGroup from "../wrapper-summary/WrapperSummaryGroup.vue";
import WrapperSummary from "../wrapper-summary/WrapperSummary.vue";
import CardHeader from "../CardHeader.vue";
import PanelDetails from "../panel/PanelDetails.vue";
import { WrapperGroup } from "../../typings/api-service";
import { numberToCommaSeparatedString } from "../../helpers/number-helper";
import { RelatedInvestor } from "../../typings/related-investor";

@Component({
    components: {
        WrapperSummaryGroup,
        WrapperSummary,
        PanelDetails,
        CardHeader
    }
})
export default class PortfolioBreakdown extends Vue {
    @Prop()
    wrapperGroups?: Array<WrapperGroup>;

    @Prop()
    relatedInvestors?: Array<RelatedInvestor>;

    @Prop()
    title?: string;

    @Prop()
    consolidatedValueHeading?: string;

    @Prop()
    offPlatformAssetsExcludedTotalMessage!: string;

    public getCommaSeparatedValue(value: number) {
        return numberToCommaSeparatedString(value);
    }

    @Prop({ default: "#NAME#" })
    public relatedInvestorHeader!: string;

    public get relatedInvestorsWithWrappers() {
        return this.relatedInvestors?.filter((relatedInvestor: RelatedInvestor) => {
            return relatedInvestor.wrapperGroups.length > 0;
        });
    }

    public hyphenateForId(input: string): string{
        return input.replaceAll(" ", "-");
    }
}
