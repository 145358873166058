import BrowserNotSupportedContentfulService from "@/services/contentful/BrowserNotSupportedContentfulService";

import { Commit } from "vuex";
import { LOAD_BROWSER_NOT_SUPPORTED_PAGE_CONTENT } from "../action-types";
import { GET_BROWSER_NOT_SUPPORTED_PAGE_CONTENT } from "../getters";
import { SAVE_BROWSER_NOT_SUPPORTED_PAGE_CONTENT, SET_BROWSER_NOT_SUPPORTED_PAGE_CONTENT_LOADED_FLAG } from "../mutation-types";
 import { UnsupportedBrowserPageState } from "../typings/unsupported-browser-page-state";
import { UnsupportedBrowserPageModel } from "@/typings/unsupported-browser-page-model";

const contentful = new BrowserNotSupportedContentfulService();

const pageState = (): UnsupportedBrowserPageState => ({
    content: {
        header: "",
        body: "",
    },
    isLoaded: false
});

const getters = {
    [GET_BROWSER_NOT_SUPPORTED_PAGE_CONTENT]: (state: UnsupportedBrowserPageState) => {
        return state.content;
    }
};

const mutations = {
    [SAVE_BROWSER_NOT_SUPPORTED_PAGE_CONTENT](state: UnsupportedBrowserPageState, model: UnsupportedBrowserPageModel) {
        state.content = model;
    },
    [SET_BROWSER_NOT_SUPPORTED_PAGE_CONTENT_LOADED_FLAG](state: UnsupportedBrowserPageState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    async [LOAD_BROWSER_NOT_SUPPORTED_PAGE_CONTENT]({ commit, state }: { commit: Commit; state: UnsupportedBrowserPageState }) {
        if (!state.isLoaded) {
            contentful.getBrowserNotSupportedPageContent().then((result: UnsupportedBrowserPageModel) => {
                commit(SAVE_BROWSER_NOT_SUPPORTED_PAGE_CONTENT, result);
                commit(SET_BROWSER_NOT_SUPPORTED_PAGE_CONTENT_LOADED_FLAG, true);
            });
        }
    }
};

export const BrowserNotSupportedPageModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state: pageState
};
