export const Namespaces = {
    CompanyInformation: "companyInformation",
    GlobalContent: "globalContent",
    NavigationPages: "navigationPages",
    Portfolio: "portfolio",
    InformationPage: "informationPage",
    WrapperDetails: "wrapperDetails",
    OffPlatformAssets: "offPlatformAssets",
    WrapperStatement: "wrapperStatement",
    Wrapper: "wrapper",
    MoneyInOut: "moneyInOut",
    PortfolioValuation: "portfolioValuation",
    Valuation: "valuation",
    WrapperValuation: "wrapperValuation",
    Documents: "documents",
    WrapperTransactions: "wrapperTransactions",
    NoBalancePage: "noBalancePage",
    AccountCreationInProgress: "accountCreationInProgress",
    BrowserNotSupported: "BrowserNotSupported",
    NotImpersonating: "notImpersonating",
    InvestorDetails: "investorDetails",
    ErrorPage: "errorPage",
    WelcomeMessage: "welcomeMessage",
    SessionExpiry: "sessionExpiry",
    WrapperPortfolioSummaryBanner: "WrapperPortfolioSummaryBanner",
    PortfolioSummaryBanner: "PortfolioSummaryBanner",
};
