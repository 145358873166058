import NoBalancePageContentfulService from "@/services/contentful/NoBalancePageContentfulService";
import { NoBalancePageModel } from "@/typings/no-balance-page";
import { Commit } from "vuex";
import { LOAD_NO_BALANCE_PAGE_CONTENT } from "../action-types";
import { GET_NO_BALANCE_PAGE_CONTENT } from "../getters";
import { SAVE_NO_BALANCE_PAGE_CONTENT, SET_NO_BALANCE_PAGE_LOADED_FLAG } from "../mutation-types";
import { NoBalancePageState } from "../typings/no-balance-page";

const contentful = new NoBalancePageContentfulService();

const state = (): NoBalancePageState => ({
    noBalancePage: {
        pageHeading: "",
        body: ""
    },
    isLoaded: false
});

const getters = {
    [GET_NO_BALANCE_PAGE_CONTENT]: (state: NoBalancePageState) => {
        return state.noBalancePage;
    }
};

const mutations = {
    [SAVE_NO_BALANCE_PAGE_CONTENT](state: NoBalancePageState, noBalancePage: NoBalancePageModel) {
        state.noBalancePage = noBalancePage;
    },
    [SET_NO_BALANCE_PAGE_LOADED_FLAG](state: NoBalancePageState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    async [LOAD_NO_BALANCE_PAGE_CONTENT]({ commit, state }: { commit: Commit; state: NoBalancePageState }) {
        if (!state.isLoaded) {
            contentful.getNoBalancePageContent().then((result: NoBalancePageModel) => {
                commit(SAVE_NO_BALANCE_PAGE_CONTENT, result);
                commit(SET_NO_BALANCE_PAGE_LOADED_FLAG, true);
            });
        }
    }
};

export const NoBalancePageModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
