




import { Component, Mixins, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import _ from "lodash";
import { BTable } from "bootstrap-vue";

import { Namespaces } from "../../store/namespaces";
import { LOAD_VALUATION_CONTENT } from "../../store/action-types";
import { GET_VALUATION_TABLE_CONTENT } from "../../store/getters";
import NumberMixin from "../../mixins/NumberMixin";

import { ValuationTableHeadings } from "../../typings/reporting-period";
import { TableField } from "../../typings/table";
import { ValuationDetails } from "../../typings/valuation-details";
import ErrorHandlerMixin from "../../mixins/ErrorHandlerMixin";

@Component({
    components: {
        BTable
    },
    computed: mapGetters({
        getValuationTableContent: `${Namespaces.Valuation}/${GET_VALUATION_TABLE_CONTENT}`
    })
})
export default class ValuationTable extends Mixins(NumberMixin, ErrorHandlerMixin) {
    @Prop({ default: () => null })
    public valuationDetails!: ValuationDetails;

    private getValuationTableContent!: ValuationTableHeadings;

    public get tableFields(): Array<TableField> {
        return [
            {
                key: "startValue",
                label: this.getValuationTableContent.startValue
            },
            {
                key: "moneyIn",
                label: this.getValuationTableContent.moneyIn,
            },
            {
                key: "moneyOut",
                label: this.getValuationTableContent.moneyOut,
            },
            {
                key: "endValue",
                label: this.getValuationTableContent.endValue,

            },
            {
                key: "changePercentage",
                label: this.getValuationTableContent.changePercentage,
            }
        ];
    }

    public get valuationTableItems() {
        if (!_.isEmpty(this.valuationDetails)) {
            return [
                {
                    startValue: this.numberToCommaSeparatedString(this.valuationDetails.startValue),
                    moneyIn: this.numberToCommaSeparatedString(this.valuationDetails.moneyIn),
                    moneyOut: this.numberToCommaSeparatedString(this.valuationDetails.moneyOut),
                    endValue: this.numberToCommaSeparatedString(this.valuationDetails.endValue),
                    changePercentage: `${this.valuationDetails.change}%`
                }
            ];
        }

        return [];
    }

    public created(): void {
        this.$store.dispatch(`${Namespaces.Valuation}/${LOAD_VALUATION_CONTENT}`).catch(error => this.handleError(error.response?.status));
    }
}
