


























import { User } from "oidc-client";
import { mapGetters } from "vuex";
import { Component, Mixins } from "vue-property-decorator";
import AuthMixin from "../mixins/AuthMixin";
import { WelcomeMessageContent } from "../../src/typings/welcome-message";
import { Namespaces } from "@/store/namespaces";
import { GET_WELCOME_MESSAGE_CONTENT, IS_WHITELABELED } from "@/store/getters";
import { LOAD_WELCOME_MESSAGE_CONTENT } from "@/store/action-types";
import ErrorHandlerMixin from "@/mixins/ErrorHandlerMixin";

@Component({
    components: {},
    computed: mapGetters({
        getWelcomeMessageContent: `${Namespaces.WelcomeMessage}/${GET_WELCOME_MESSAGE_CONTENT}`
    })
})
export default class WelcomeMessage extends Mixins(AuthMixin, ErrorHandlerMixin) {
    private currentUsername = "";
    private impersonatingUsername = "";
    private displayimpersonationMessage = false;
    private getWelcomeMessageContent!: WelcomeMessageContent;

    get username(): string {
        return this.currentUsername;
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.WelcomeMessage}/${LOAD_WELCOME_MESSAGE_CONTENT}`)
            .catch(error => this.handleError(error.response?.status));
    }

    get getImpersonationMessage(): string {
        return this.getWelcomeMessageContent.impersonationMessage.replace("#NAME#", this.impersonatingUsername);
    }

    get getWelcomeMessage(): string {
        if (this.$isWealthTime && !this.isWhitelabeled)
            return this.getWelcomeMessageContent.welcomeMessage.replace(
                "#NAME#",
                `<span class="welcome-username"><span class="primary-text">${this.username}</span></span>`
            );
        if (this.$isWealthTime && this.isWhitelabeled)
            return this.getWelcomeMessageContent.welcomeMessage.replace(
                "#NAME#",
                `<span class="welcome-username"><span>${this.username}</span></span>`
            );
        else
            return this.getWelcomeMessageContent.welcomeMessage.replace(
                "#NAME#",
                '<span class="welcome-username"> <strong>' + this.username + " </strong></span>"
            );
    }

    public async mounted() {
        this.getAuthenticatedUser().then((user: User) => {
            if (user) {
                if (user.profile.investor_context) {
                    this.displayimpersonationMessage = true;
                    this.impersonatingUsername = user.profile.investor_context_name;
                    this.currentUsername = `${user.profile.name}`;
                } else {
                    this.currentUsername = `${user.profile.name} ${user.profile.family_name}`;
                }
            }
        });
    }

    public get isWhitelabeled(): number | boolean {
        return this.$store.getters[`${Namespaces.CompanyInformation}/${IS_WHITELABELED}`];
    }
}
