import TransactionHistoryContentfulService from "@/services/contentful/TransactionHistoryContentfulService";
import {
    TransactionHistoryPageContent,
    TransactionHistoryPageHeadings,
    TransactionHistoryTableHeadings
} from "@/typings/transaction-history-page";
import { Commit } from "vuex";
import { LOAD_WRAPPER_TRANSACTIONS_CONTENT } from "../action-types";
import { GET_WRAPPER_TRANSACTIONS_PAGE_HEADINGS, GET_WRAPPER_TRANSACTIONS_TABLE_CONTENT } from "../getters";
import {
    SAVE_WRAPPER_TRANSACTIONS_PAGE_HEADINGS_CONTENT,
    SAVE_WRAPPER_TRANSACTIONS_TABLE_HEADINGS,
    SET_WRAPPER_TRANSACTIONS_LOADED
} from "../mutation-types";
import { WrapperTransactionsState } from "../typings/wrapper-transactions";

const contentful = new TransactionHistoryContentfulService();

const state = (): WrapperTransactionsState => ({
    pageHeadings: {
        transactionHistoryTitle: "",
        modelPortfolioLabel: "",
        totalValueLabel: "",
        wrapperTransactionsTitle: "",
        searchPlaceholder: "",
        downloadTransactionHistoryButton: "",
        dearCeoLetterLabel: ""
    },
    table: {
        dateTableHeading: "",
        wrapperIdTableHeading: "",
        transactionTableHeading: "",
        investmentNameTableHeading: "",
        unitChangeTableHeading: "",
        transactionValueTableHeading: "",
        transactionTableNoResultsMessage: ""
    },
    isLoaded: false
});

const getters = {
    [GET_WRAPPER_TRANSACTIONS_PAGE_HEADINGS]: (state: WrapperTransactionsState): TransactionHistoryPageHeadings => {
        return state.pageHeadings;
    },
    [GET_WRAPPER_TRANSACTIONS_TABLE_CONTENT]: (state: WrapperTransactionsState): TransactionHistoryTableHeadings => {
        return state.table;
    }
};

const mutations = {
    [SAVE_WRAPPER_TRANSACTIONS_PAGE_HEADINGS_CONTENT](
        state: WrapperTransactionsState,
        model: TransactionHistoryPageHeadings
    ) {
        state.pageHeadings = model;
    },
    [SAVE_WRAPPER_TRANSACTIONS_TABLE_HEADINGS](
        state: WrapperTransactionsState,
        model: TransactionHistoryTableHeadings
    ) {
        state.table = model;
    },
    [SET_WRAPPER_TRANSACTIONS_LOADED](state: WrapperTransactionsState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    [LOAD_WRAPPER_TRANSACTIONS_CONTENT]({ commit, state }: { commit: Commit; state: WrapperTransactionsState }) {
        if (!state.isLoaded) {
            contentful.getTransactionHistoryContent().then((result: TransactionHistoryPageContent) => {
                commit(SAVE_WRAPPER_TRANSACTIONS_PAGE_HEADINGS_CONTENT, {
                    transactionHistoryTitle: result.transactionHistoryTitle,
                    modelPortfolioLabel: result.modelPortfolioLabel,
                    totalValueLabel: result.totalValueLabel,
                    wrapperTransactionsTitle: result.wrapperTransactionsTitle,
                    searchPlaceholder: result.searchPlaceholder,
                    dearCeoLetterLabel: result.dearCeoLetterLabel,
                    downloadTransactionHistoryButton: result.downloadTransactionHistoryButton
                });
                commit(SAVE_WRAPPER_TRANSACTIONS_TABLE_HEADINGS, {
                    dateTableHeading: result.dateTableHeading,
                    wrapperIdTableHeading: result.wrapperIdTableHeading,
                    transactionTableHeading: result.transactionTableHeading,
                    investmentNameTableHeading: result.investmentNameTableHeading,
                    unitChangeTableHeading: result.unitChangeTableHeading,
                    transactionValueTableHeading: result.transactionValueTableHeading,
                    transactionTableNoResultsMessage: result.transactionTableNoResultsMessage
                });
                commit(SET_WRAPPER_TRANSACTIONS_LOADED, true);
            });
        }
    }
};

export const WrapperTransactionsModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
