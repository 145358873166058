

















import { Component, Mixins } from "vue-property-decorator";
import { mapGetters } from "vuex";
import ErrorHandlerMixin from "../../mixins/ErrorHandlerMixin";
import RouteMixin from "../../mixins/RouteMixin";
import { LOAD_WRAPPER_DETAILS_CONTENT, LOAD_WRAPPER_TRANSACTIONS_CONTENT, LOAD_WRAPPER_VALUATION_CONTENT } from "../../store/action-types";
import { GET_WRAPPER_DETAILS_CONTENT, GET_WRAPPER_TRANSACTIONS_PAGE_HEADINGS, GET_WRAPPER_VALUATION_PAGE_HEADINGS } from "../../store/getters";
import { Namespaces } from "../../store/namespaces";

import SummaryBannerActionBar from "../summary-banner/SummaryBannerActionBar.vue";
import SummaryBannerActionButton from "../summary-banner/SummaryBannerActionButton.vue";

import { SummaryBannerActionButtonOptions } from "../../typings/summary-banner-action-bar";
import { WrapperPageContentHeadings } from "../../typings/wrapper-page";
import { WrapperValuationPageContent } from "../../typings/wrapper-valuation";
import { TransactionHistoryPageContent } from "../../typings/transaction-history-page";

@Component({
    components: {
        SummaryBannerActionBar,
        SummaryBannerActionButton,
    },
    computed: mapGetters({
        getWrapperDetailsContent: `${Namespaces.WrapperDetails}/${GET_WRAPPER_DETAILS_CONTENT}`,
        getWrapperValuationPageContent: `${Namespaces.WrapperValuation}/${GET_WRAPPER_VALUATION_PAGE_HEADINGS}`,
        getTransactionHistoryContent: `${Namespaces.WrapperTransactions}/${GET_WRAPPER_TRANSACTIONS_PAGE_HEADINGS}`
    }),
})
export default class WrapperDetailsBannerActionBar extends Mixins(RouteMixin, ErrorHandlerMixin) {
    private getWrapperDetailsContent!: WrapperPageContentHeadings;
    private getWrapperValuationPageContent!: WrapperValuationPageContent;
    private getTransactionHistoryContent!: TransactionHistoryPageContent;

    public get wrapperDetailsOptions(): SummaryBannerActionButtonOptions {
        return {
            title: this.getWrapperDetailsContent.wrapperDetailsHeader,
            href: `/wrapper/${this.idFromRoute}`
        };
    }

    public get wrapperValuationOptions(): SummaryBannerActionButtonOptions {
        return {
            title: this.getWrapperValuationPageContent.wrapperValuation,
            href: `/wrappervaluation/${this.idFromRoute}`
        };
    }

    public get wrapperStatementOptions(): SummaryBannerActionButtonOptions {
        return {
            title: this.getWrapperDetailsContent.wrapperStatementButton,
            href: `/wrapperstatement/${this.idFromRoute}`
        };
    }
   
    public get transactionActionButtonOptions(): SummaryBannerActionButtonOptions {
        return {
            title: this.getTransactionHistoryContent.transactionHistoryTitle,
            href: `/wrappertransaction/${this.idFromRoute}`
        };
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.WrapperDetails}/${LOAD_WRAPPER_DETAILS_CONTENT}`)
            .catch((error) => this.handleError(error.response?.status));
        this.$store
            .dispatch(`${Namespaces.WrapperValuation}/${LOAD_WRAPPER_VALUATION_CONTENT}`)
            .catch((error) => this.handleError(error.response?.status));
        this.$store
            .dispatch(`${Namespaces.WrapperTransactions}/${LOAD_WRAPPER_TRANSACTIONS_CONTENT}`)
            .catch((error) => this.handleError(error.response?.status));
    }

    public checkActivePage(href: string): boolean{
        if(this.$route.path == href){
            return true;
        }

        return false;
    }
}
