


















import { Component, Mixins } from "vue-property-decorator";
import { BRow, BCol } from "bootstrap-vue";
import ContentHeader from "@/components/ContentHeader.vue";
import FluidContainer from "../components/FluidContainer.vue";
import SummaryBanner from "../components/summary-banner/SummaryBanner.vue";
import SummaryBannerHeader from "../components/summary-banner/SummaryBannerHeader.vue";
import SummaryBannerValue from "../components/summary-banner/SummaryBannerValue.vue";
import SummaryBannerContainer from "../components/summary-banner/SummaryBannerContainer.vue";
import { Namespaces } from "../store/namespaces";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";
import Skeleton from "@/components/Skeleton.vue";
import { mapGetters } from "vuex";
import { GET_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT } from "../store/getters";
import { LOAD_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT } from "../store/action-types"
import { AccountCreationInProgressPageModel } from "../typings/account-creation-in-progress-page";

@Component({
    components: {
        BRow,
        BCol,
        FluidContainer,
        Skeleton,
        SummaryBanner,
        SummaryBannerHeader,
        SummaryBannerValue,
        SummaryBannerContainer,
        ContentHeader
    },
    computed: mapGetters({
        accountCreationInProgressPageModel: `${Namespaces.AccountCreationInProgress}/${GET_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT}`
    })
})
export default class AccountCreationInProgressPage extends Mixins(ErrorHandlerMixin) {
    public accountCreationInProgressPageModel!: AccountCreationInProgressPageModel;

    public get header(): string {
        return this.accountCreationInProgressPageModel.header;
    }

    public get body(): string {
        return this.accountCreationInProgressPageModel.body;
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.AccountCreationInProgress}/${LOAD_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT}`)
            .catch(error => this.handleError(error.response?.status));
    }
}
