






import moment from "moment";
import { Component, Mixins } from "vue-property-decorator";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";
import { GET_COMPANY_INFORMATION } from "../store/getters";
import { Namespaces } from "../store/namespaces";
import { CompanyInformation } from "../typings/company-information";

@Component({
    components: {},
})
export default class Copyright extends Mixins(ErrorHandlerMixin) {
    public get companyCopyright(): string {
        const copyrightSymbol = "&#169;";
        const currentYear = moment().format("YYYY");
        return `${copyrightSymbol} ${currentYear} ${this.companyInformation.companyName}`;
    }

    private get companyInformation(): CompanyInformation {
        return this.$store.getters[`${Namespaces.CompanyInformation}/${GET_COMPANY_INFORMATION}`];
    }
}
