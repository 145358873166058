export const ErrorPageMessages = {
    headings: {
        defaultServerErrorHeading: "Server Error",
        defaultForbiddenHeading: "Error 403 - Forbidden",
        defaultPageNotFoundHeading: "Error 404 - Page Not Found",
    },

    body: {
        defaultServerErrorBody: "There was a server error which has prevented this page from displaying. We apologise for the inconvenience. If the problem persists please contact us at clientservices@novia-financial.co.uk.",
        defaultForbiddenBody: "You do not have access to view this page. If you believe this is an error please contact us at clientservices@novia-financial.co.uk.  We apologise for the inconvenience.",
        defaultPageNotFoundBody:
            "The page has not been found.  Please check the address and try again. If the problem persists please contact us at clientservices@novia-financial.co.uk.  We apologise for the inconvenience.",
    }
}