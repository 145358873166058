import { ContentfulTransactionHistoryPage } from "@/typings/contentful";
import { TransactionHistoryPageContent } from "@/typings/transaction-history-page";
import { Entry } from "contentful";
import { ContentfulKeys } from "./contentful-keys";
import ContentfulService from "./ContentfulService";

export default class TransactionHistoryContentfulService extends ContentfulService {
    public getTransactionHistoryContent(): Promise<ContentfulTransactionHistoryPage> {
        return this.getContentfulEntry<ContentfulTransactionHistoryPage, TransactionHistoryPageContent>(
            ContentfulKeys.TransactionHistory,
            (result: Entry<ContentfulTransactionHistoryPage>) => {
                return {
                    transactionHistoryTitle: result.fields.transactionHistoryTitle,
                    modelPortfolioLabel: result.fields.modelPortfolioLabel,
                    totalValueLabel: result.fields.totalValueLabel,
                    wrapperTransactionsTitle: result.fields.wrapperTransactionsTitle,
                    searchPlaceholder: result.fields.searchPlaceholder,
                    dearCeoLetterLabel: result.fields.dearCeoLetterLabel,
                    dateTableHeading: result.fields.dateTableHeading,
                    wrapperIdTableHeading: result.fields.wrapperIdTableHeading,
                    transactionTableHeading: result.fields.transactionTableHeading,
                    investmentNameTableHeading: result.fields.investmentNameTableHeading,
                    unitChangeTableHeading: result.fields.unitChangeTableHeading,
                    transactionValueTableHeading: result.fields.transactionValueTableHeading,
                    downloadTransactionHistoryButton: result.fields.downloadTransactionHistoryButton,
                    transactionTableNoResultsMessage: result.fields.transactionTableNoResultsMessage
                };
            }
        );
    }
}
