import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import ContentfulVue from "contentful-vue";
import HighchartsVue from "highcharts-vue";
import { BootstrapVue, IconsPlugin, CollapsePlugin } from "bootstrap-vue";
import { DataDogServicePlugin } from "./services/DataDogServicePlugin";
import { PendoServicePlugin } from "./services/PendoServicePlugin";
import { ApiServicePlugin } from "./services/ApiServicePlugin";
import { AuthServicePlugin } from "./services/AuthServicePlugin";
import AxiosFactory from "./services/AxiosFactory";
import VueGtm from '@gtm-support/vue2-gtm';
import browserDetect from "vue-browser-detect-plugin";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./scss/global.scss";
import { environment } from './environment'

Vue.config.productionTip = false;

const axiosFactory = new AxiosFactory();
Vue.prototype.$http = axiosFactory.getAxiosClient(environment.noviaInvestorZoneApi);
Vue.prototype.$isWealthTime = environment.buildtype === "wealthtime";

Vue.use(ContentfulVue, {
    space: environment.contentfulSpace,
    accessToken: environment.contentfulAccessToken
});
Vue.use(DataDogServicePlugin);
Vue.use(PendoServicePlugin);
Vue.use(AuthServicePlugin);
Vue.use(ApiServicePlugin);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(HighchartsVue);
Vue.use(CollapsePlugin)
Vue.use(browserDetect);

Vue.use(VueGtm, {
    id: environment.googleTagManagerContainerId,    
    queryParams:{
        gtm_auth: environment.googleTagManagerEnvAuth,
        gtm_preview: environment.googleTagManagerEnvPreview,
        gtm_cookies_win: environment.googleTagManagerEnvCookies
    },
});

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount("#app");
