






















import { Component, Mixins } from "vue-property-decorator";
import { BIcon, BRow, BCol } from "bootstrap-vue";
import Button from "../components/Button.vue";
import FluidContainer from "../components/FluidContainer.vue";
import AuthMixin from "../mixins/AuthMixin";

@Component({
    components: {
        BIcon,
        BRow,
        BCol,
        FluidContainer,
        Button
    }
})
export default class LoginPage extends Mixins(AuthMixin) {
    private failedToAccessAuthServer = false;

    public redirectToLogin() {
        this.$auth.login().catch(() => {
            this.failedToAccessAuthServer = true;
        });
    }

    public mounted() {
        this.getAuthenticatedUser()
            .then(() => this.$router.push("/"))
            .catch(() => this.redirectToLogin());
    }
}
