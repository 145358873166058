import Vue from "vue";
import { Wrapper } from "@/typings/wrapper";
import { Commit } from "vuex";
import { LOAD_WRAPPER, LOAD_WRAPPER_ASSET_ALLOCATIONS } from "../action-types";
import { GET_WRAPPER_BY_ID, GET_WRAPPER_ASSET_ALLOCATIONS_BY_ID } from "../getters";
import { SAVE_WRAPPER, SAVE_WRAPPER_ASSET_ALLOCATIONS } from "../mutation-types";
import { AssetAllocationsPayload, WrapperState } from "../typings/wrapper";
import { AssetAllocation } from "@/typings/asset-allocation";
import { AssetAllocationApiResult } from "@/typings/api-service";

const state = (): WrapperState => ({
    wrappers: new Map<number, Wrapper>(),
    assetAllocations: new Map<number, Array<AssetAllocation>>()
});

const getters = {
    [GET_WRAPPER_BY_ID]: (state: WrapperState) => (wrapperId: number): Wrapper | null => {
        if (state.wrappers.has(wrapperId)) {
            return state.wrappers.get(wrapperId) as Wrapper;
        }

        return null;
    },
    [GET_WRAPPER_ASSET_ALLOCATIONS_BY_ID]: (state: WrapperState) => (
        wrapperId: number
    ): Array<AssetAllocation> | null => {
        if (state.assetAllocations.has(wrapperId)) {
            return state.assetAllocations.get(wrapperId) as Array<AssetAllocation>;
        }

        return null;
    }
};

const mutations = {
    [SAVE_WRAPPER](state: WrapperState, wrapper: Wrapper) {
        if (!state.wrappers.has(wrapper.id)) {
            // Vue.js v2 does not support reactivity with Map, so a new one must be created to re-compute the property
            state.wrappers = new Map<number, Wrapper>(state.wrappers.set(wrapper.id, wrapper));
        }
    },
    [SAVE_WRAPPER_ASSET_ALLOCATIONS](state: WrapperState, model: AssetAllocationsPayload) {
        state.assetAllocations = new Map<number, Array<AssetAllocation>>(
            state.assetAllocations.set(model.wrapperId, model.assetAllocations)
        );
    }
};

const actions = {
    [LOAD_WRAPPER]({ commit, state }: { commit: Commit; state: WrapperState }, { wrapperId }: { wrapperId: number }) {
        if (!state.wrappers.has(wrapperId)) {
            Vue.prototype.$api.getWrapper(wrapperId).then((result: Wrapper) => {
                commit(SAVE_WRAPPER, result);
            });
        }
    },
    [LOAD_WRAPPER_ASSET_ALLOCATIONS](
        { commit, state }: { commit: Commit; state: WrapperState },
        { wrapperId }: { wrapperId: number }
    ) {
        if (!state.assetAllocations.has(wrapperId)) {
            Vue.prototype.$api.getWrapperAssetAllocations(wrapperId).then((result: AssetAllocationApiResult) => {
                commit(SAVE_WRAPPER_ASSET_ALLOCATIONS, {
                    wrapperId: wrapperId,
                    assetAllocations: result.assetAllocation
                } as AssetAllocationsPayload);
            });
        }
    }
};

export const WrapperModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
