


























import { Component } from "vue-property-decorator";
import ChartTable from "./ChartTable.vue";

@Component({
    components: {},
})
export default class PortfolioChartTable extends ChartTable {}
