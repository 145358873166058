import WrapperValuationContentfulService from "@/services/contentful/WrapperValuationContentfulService";
import {
    WrapperValuationPageButtons,
    WrapperValuationPageChartContent,
    WrapperValuationPageContent,
    WrapperValuationPageHeadings,
    WrapperValuationPageTableHeadings
} from "@/typings/wrapper-valuation";
import { Commit } from "vuex";
import { LOAD_WRAPPER_VALUATION_CONTENT } from "../action-types";
import {
    GET_WRAPPER_VALUATION_BUTTONS,
    GET_WRAPPER_VALUATION_CHART_CONTENT,
    GET_WRAPPER_VALUATION_PAGE_HEADINGS,
    GET_WRAPPER_VALUATION_TABLE_CONTENT
} from "../getters";
import { SAVE_WRAPPER_VALUATION_PAGE_CONTENT, SET_WRAPPER_VALUATION_PAGE_CONTENT_LOADED } from "../mutation-types";
import { WrapperValuationState } from "../typings/wrapper-valuation";

const contentful = new WrapperValuationContentfulService();

const state = (): WrapperValuationState => ({
    pageHeadings: {
        modelPortfolioLabel: "",
        totalValueLabel: "",
        wrapperValuation: ""
    },
    buttons: {
        downloadValuationButton: ""
    },
    chart: {
        chartTitle: "",
        chartColour: "",
        chartInformation: ""
    },
    valuationTable: {
        sectorTableHeading: "",
        investmentNameTableHeading: "",
        priceTableHeading: "",
        priceDateTableHeading: "",
        defaultInvestmentStrategyTableHeading: "",
        valueTableHeading: "",
        unitsTableHeading: "",
        weightingTableHeading: ""
    },
    isLoaded: false
});

const getters = {
    [GET_WRAPPER_VALUATION_PAGE_HEADINGS]: (state: WrapperValuationState): WrapperValuationPageHeadings => {
        return state.pageHeadings;
    },
    [GET_WRAPPER_VALUATION_CHART_CONTENT]: (state: WrapperValuationState): WrapperValuationPageChartContent => {
        return state.chart;
    },
    [GET_WRAPPER_VALUATION_TABLE_CONTENT]: (state: WrapperValuationState): WrapperValuationPageTableHeadings => {
        return state.valuationTable;
    },
    [GET_WRAPPER_VALUATION_BUTTONS]: (state: WrapperValuationState): WrapperValuationPageButtons => {
        return state.buttons;
    }
};

const mutations = {
    [SAVE_WRAPPER_VALUATION_PAGE_CONTENT](state: WrapperValuationState, model: WrapperValuationPageContent) {
        state.pageHeadings = {
            modelPortfolioLabel: model.modelPortfolioLabel,
            totalValueLabel: model.totalValueLabel,
            wrapperValuation: model.wrapperValuation
        };
        state.buttons = {
            downloadValuationButton: model.downloadValuationButton
        };
        state.chart = {
            chartTitle: model.chartTitle,
            chartColour: model.chartColour,
            chartInformation: model.chartInformation
        };
        state.valuationTable = {
            sectorTableHeading: model.sectorTableHeading,
            investmentNameTableHeading: model.investmentNameTableHeading,
            priceTableHeading: model.priceTableHeading,
            priceDateTableHeading: model.priceDateTableHeading,
            defaultInvestmentStrategyTableHeading: model.defaultInvestmentStrategyTableHeading,
            valueTableHeading: model.valueTableHeading,
            unitsTableHeading: model.unitsTableHeading,
            weightingTableHeading: model.weightingTableHeading
        };
    },
    [SET_WRAPPER_VALUATION_PAGE_CONTENT_LOADED](state: WrapperValuationState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    [LOAD_WRAPPER_VALUATION_CONTENT]({ commit, state }: { commit: Commit; state: WrapperValuationState }) {
        if (!state.isLoaded) {
            contentful.getWrapperValuationPageContent().then((result: WrapperValuationPageContent) => {
                commit(SAVE_WRAPPER_VALUATION_PAGE_CONTENT, result);
                commit(SET_WRAPPER_VALUATION_PAGE_CONTENT_LOADED, true);
            });
        }
    }
};

export const WrapperValuationModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
