import { SessionExpiryContentState } from "../typings/session-expiry";
import { SessionExpiryContent } from "@/typings/session-expiry";
import { SAVE_SESSION_EXPIRY_CONTENT, SET_SESSION_EXPIRY_LOADED } from "../mutation-types";
import { GET_SESSION_EXPIRY_CONTENT } from "../getters";
import { LOAD_SESSION_EXPIRY_CONTENT } from "../action-types";
import { Commit } from "vuex";
import SessionExpiryContentfulService from "@/services/contentful/SessionExpiryContentfulService";

const contentful = new SessionExpiryContentfulService();

const state = (): SessionExpiryContentState => ({
    sessionExpiryContent: {
        expiryTitle: "",
        warningMessage: "",
        cancelButtonText: "",
        logoutButtonText: "",
        stopImpersonatingButtonText: ""
    },
    isLoaded: false
});

const getters = {
    [GET_SESSION_EXPIRY_CONTENT]: (state: SessionExpiryContentState): SessionExpiryContent => {
        return {
            expiryTitle: state.sessionExpiryContent.expiryTitle,
            warningMessage: state.sessionExpiryContent.warningMessage,
            cancelButtonText: state.sessionExpiryContent.cancelButtonText,
            logoutButtonText: state.sessionExpiryContent.logoutButtonText,
            stopImpersonatingButtonText: state.sessionExpiryContent.stopImpersonatingButtonText
        };
    }
};

const mutations = {
    [SAVE_SESSION_EXPIRY_CONTENT](state: SessionExpiryContentState, model: SessionExpiryContent) {
        state.sessionExpiryContent = model;
    },
    [SET_SESSION_EXPIRY_LOADED](state: SessionExpiryContentState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    async [LOAD_SESSION_EXPIRY_CONTENT]({ commit, state }: { commit: Commit; state: SessionExpiryContentState }) {
        if (!state.isLoaded) {
            contentful.getSessionExpiryContent().then((result: SessionExpiryContent) => {
                commit(SAVE_SESSION_EXPIRY_CONTENT, result);
                commit(SET_SESSION_EXPIRY_LOADED, true);
            });
        }
    }
};

export const SessionExpiryModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
