















import { Component, Mixins } from "vue-property-decorator";
import { BRow, BCol } from "bootstrap-vue";
import ContentHeader from "@/components/ContentHeader.vue";
import FluidContainer from "../components/FluidContainer.vue";
import SummaryBanner from "../components/summary-banner/SummaryBanner.vue";
import SummaryBannerHeader from "../components/summary-banner/SummaryBannerHeader.vue";
import SummaryBannerValue from "../components/summary-banner/SummaryBannerValue.vue";
import SummaryBannerContainer from "../components/summary-banner/SummaryBannerContainer.vue";
import { Namespaces } from "../store/namespaces";
import { LOAD_INFORMATION_PAGE_CONTENT } from "../store/action-types";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { InformationPage } from "../typings/information-page";
import { GET_INFORMATION_PAGE_CONTENT } from "../store/getters";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";
import Skeleton from "@/components/Skeleton.vue";
import ReactivePageTitle from "@/components/ReactivePageTitle.vue";
import { INLINES } from '@contentful/rich-text-types';


@Component({
    components: {
        BRow,
        BCol,
        FluidContainer,
        Skeleton,
        SummaryBanner,
        SummaryBannerHeader,
        SummaryBannerValue,
        SummaryBannerContainer,
        ContentHeader,
        ReactivePageTitle
    }
})
export default class InfoPage extends Mixins(ErrorHandlerMixin) {
    public get contentHeaderTitle(): string {
        return this.informationPage.title;
    }
    public get contentBody(): string {
        const options = {
            renderNode: {
            [INLINES.HYPERLINK] : (node: any, next: any) => {
                return `<a href="${node.data.uri}" target="_blank">${next(node.content)}</a>`;
                }
            }
        };

        return documentToHtmlString(this.informationPage.body, options);
    }

    private get informationPage(): InformationPage {
        return this.$store.getters[`${Namespaces.InformationPage}/${GET_INFORMATION_PAGE_CONTENT}`];
    }

    public get showSkeleton(): boolean {
        return !this.$store.state.informationPage.isLoaded;
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.InformationPage}/${LOAD_INFORMATION_PAGE_CONTENT}`)
            .catch(error => this.handleError(error.response?.status));
    }
}
