export const Routes = {
    Home: {
        name: "Home",
        path: "/"
    },
    Login: {
        path: "/login",
        name: "Login"
    },
    AccountCreationInProgress: {
        path: "/accountcreationinprogress",
        name: "Account Creation In Progress",
    },
    Information: {
        path: "/information",
        name: "Information",
    },
    Wrapper: {
        path: "/wrapper/:id",
        name: "Wrapper",
    },
    Error : {
        path: "/error/:statusCode?",
        name: "Error",
    },
    InternetExplorer: {
        path: "/internet-explorer",
        name: "InternetExplorer",
    },
    OffPlatformAssets: {
        path: "/offplatformassets/",
        name: "OffPlatformAssets",
    },
    WrapperStatement: {
        path: "/wrapperstatement/:id",
        name: "WrapperStatement",
    },
    WrapperValuation: {
        path: "/wrappervaluation/:id",
        name: "WrapperValuation",
    },
    WrapperTransaction: {
        path: "/wrappertransaction/:id",
        name: "WrapperTransaction",
    },
    MoneyInOut: {
        path: "/moneyinout",
        name: "Money In/Out",
    },
    PortfolioValuation: {
        path: "/portfoliovaluation",
        name: "Wrap Account Valuation",
    },
    Documents: {
        path: "/documents/",
        name: "Documents",        
    },
    NoBalance: {
        path: "/nobalance",
        name: "No Balance",
    },
    Logout: {
        path: "/logout",
        name: "Logout"
    },
    StopImpersonating: {
        path: "/stopimpersonating",
        name: "StopImpersonating",
    },
    NotImpersonating: {
        path: "/notimpersonating",
        name: "NotImpersonating",
    },
    Stocktrade: {
        path: "/stocktrade",
        name: "Stocktrade",
    }
}