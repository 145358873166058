






import { Component, Mixins, Prop } from "vue-property-decorator";
import { Namespaces } from "../store/namespaces";
import { GET_WHITELABELLING_CONTENT } from "../store/getters";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";
import { WhiteLabellingDetails } from "@/typings/whitelabelling-details";

const DEFAULT_LOGO_URL = "../img/novia-logo-default.png";
const DEFAULT_WEALTHTIME_LOGO_URL = "../img/wealthtime_neg.svg";

@Component({
    components: {}
})
export default class Logo extends Mixins(ErrorHandlerMixin) {
    @Prop()
    public width!: number | string;
    @Prop()
    public height!: number;

    public get stateHeight(): number {
        if (
            !this.$isWealthTime ||
            ((this.whiteLabellingDetails?.branchId ?? 0 > 0) && this.whiteLabellingDetails?.enabled)
        ) {
            return 75;
        } else {
            return 40;
        }
    }

    public get stateWidth(): number | string {
        return this.width ? this.width : this.$isWealthTime ? "auto" : 150;
    }

    public get logoUrl(): string {
        const defaultLogo = this.$isWealthTime ? DEFAULT_WEALTHTIME_LOGO_URL : DEFAULT_LOGO_URL;

        if ((this.whiteLabellingDetails?.branchId ?? 0 > 0) && this.whiteLabellingDetails?.enabled) {
            return this.whiteLabellingDetails.logoUrl;
        }
        return defaultLogo;
    }

    private get whiteLabellingDetails(): WhiteLabellingDetails | null {
        return this.$store.getters[`${Namespaces.CompanyInformation}/${GET_WHITELABELLING_CONTENT}`];
    }
}
