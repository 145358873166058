






























import { Component, Mixins, Model, Prop } from "vue-property-decorator";

import { BFormGroup, BFormRadio } from "bootstrap-vue";
import { Namespaces } from "../../store/namespaces";
import { LOAD_VALUATION_CONTENT } from "../../store/action-types";
import ErrorHandlerMixin from "../../mixins/ErrorHandlerMixin";
import { mapGetters } from "vuex";
import { GET_REPORTING_PERIOD_RADIO_CONTENT } from "../../store/getters";
import StatementsMixin from "../../mixins/StatementsMixin";
import { ReportingPeriodContent } from "../../typings/reporting-period";

@Component({
    components: {
        BFormGroup,
        BFormRadio
    },
    computed: mapGetters({
        getReportingPeriodContent: `${Namespaces.Valuation}/${GET_REPORTING_PERIOD_RADIO_CONTENT}`
    })
})
export default class ReportingPeriod extends Mixins(ErrorHandlerMixin, StatementsMixin) {
    @Model("change", { type: Number, default: 6 })
    public selectedInterval!: number;

    @Prop({ default: true })
    public displayPeriodDescription!: boolean;

    private getReportingPeriodContent!: ReportingPeriodContent;

    public get statementPeriodWithDates(): string {
        return this.getStatementPeriodWithDates(this.selectedInterval, this.getReportingPeriodContent.valuationPeriod);
    }

    public selectedIntervalChange(value: number): void {
        this.$emit("change", value);
    }

    public created(): void {
        this.$store.dispatch(`${Namespaces.Valuation}/${LOAD_VALUATION_CONTENT}`).catch(error => this.handleError(error.response?.status));
    }
}
