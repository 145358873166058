






import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {}
})
export default class Button extends Vue {
    @Prop({ default: false })
    simple!: boolean;

    @Prop({ default: "#" })
    to!: string | Record<string, any>;
}
