
















import { Component, Mixins } from "vue-property-decorator";
import { BIcon, BRow, BCol } from "bootstrap-vue";
import Button from "../components/Button.vue";
import FluidContainer from "../components/FluidContainer.vue";
import AuthMixin from "../mixins/AuthMixin";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";

@Component({
    components: {
        ErrorHandlerMixin,
        BIcon,
        BRow,
        BCol,
        FluidContainer,
        Button
    }
})
export default class StopImpersonatingPage extends Mixins(AuthMixin, ErrorHandlerMixin) {
    public async mounted() {
        // Remove the user
        await this.removeUser();

        // Then remove the investor context (this actually signs them in again but adds acr values for the IDS)
        await this.removeInvestorContext();

        this.$router.push("NotImpersonating");
    }

    public sleep(ms: number) {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    }
}
