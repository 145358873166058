import Vue from "vue";
import Vuex from "vuex";
import {
    CompanyInformationModule,
    GlobalContentModule,
    NavigationPagesModule,
    PortfolioModule,
    InformationPageModule,
    OffPlatformAssetsModule,
    WrapperDetailsModule,
    WrapperStatementModule,
    WrapperModule,
    MoneyInOutPageModule,
    PortfolioValuationModule,
    ValuationModule,
    WrapperValuationModule,
    DocumuntsModule,
    WrapperTransactionsModule,
    NoBalancePageModule,
    NotImpersonatingModule,
    InvestorDetailsModule,
    ErrorPageModule,
    SessionExpiryModule,
    AccountCreationInProgressPageModule,
    BrowserNotSupportedPageModule,
    WrapperPortfolioSummaryBannerModule,
    PortfolioSummaryBannerModule
} from "./modules";
import { WelcomeMessageModule } from "./modules/welcome-message";
import { Namespaces } from "./namespaces";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        [Namespaces.CompanyInformation]: CompanyInformationModule,
        [Namespaces.GlobalContent]: GlobalContentModule,
        [Namespaces.NavigationPages]: NavigationPagesModule,
        [Namespaces.Portfolio]: PortfolioModule,
        [Namespaces.InformationPage]: InformationPageModule,
        [Namespaces.WrapperDetails]: WrapperDetailsModule,
        [Namespaces.OffPlatformAssets]: OffPlatformAssetsModule,
        [Namespaces.WrapperStatement]: WrapperStatementModule,
        [Namespaces.Wrapper]: WrapperModule,
        [Namespaces.MoneyInOut]: MoneyInOutPageModule,
        [Namespaces.PortfolioValuation]: PortfolioValuationModule,
        [Namespaces.Valuation]: ValuationModule,
        [Namespaces.WrapperValuation]: WrapperValuationModule,
        [Namespaces.Documents]: DocumuntsModule,
        [Namespaces.WrapperTransactions]: WrapperTransactionsModule,
        [Namespaces.NoBalancePage]: NoBalancePageModule,
        [Namespaces.NotImpersonating]: NotImpersonatingModule,
        [Namespaces.InvestorDetails]: InvestorDetailsModule,
        [Namespaces.ErrorPage]: ErrorPageModule,
        [Namespaces.WelcomeMessage]: WelcomeMessageModule,
        [Namespaces.SessionExpiry]: SessionExpiryModule,
        [Namespaces.AccountCreationInProgress]: AccountCreationInProgressPageModule,
        [Namespaces.BrowserNotSupported]: BrowserNotSupportedPageModule,
        [Namespaces.WrapperPortfolioSummaryBanner]: WrapperPortfolioSummaryBannerModule,
        [Namespaces.PortfolioSummaryBanner]: PortfolioSummaryBannerModule
    }
});
