import { Component, Mixins } from "vue-property-decorator";
import _ from "lodash";
import NumberMixin from "./NumberMixin";

@Component
export default class SummaryBannerMixin extends Mixins(NumberMixin) {
    public getTotalValueContent(totalValueLabel: string, value?: number): string {
        if (_.isEmpty(totalValueLabel) || value === null || value === undefined) {
            return "";
        }

        return totalValueLabel.replace(
            "#VALUE#",
            `<span class='font-weight-bold'>£${this.numberToCommaSeparatedString(value.toFixed(2))}</span>`
        );
    }
}
