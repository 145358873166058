import { Commit } from "vuex";

export interface Action<T> {
    commit: Commit;
    state: T;
}

export const LOAD_COMPANY_INFORMATION = "LOAD_COMPANY_INFORMATION";
export const LOAD_GLOBAL_CONTENT = "LOAD_GLOBAL_CONTENT";
export const LOAD_NAVIGATION_PAGES = "LOAD_NAVIGATION_PAGES";

export const LOAD_PORTFOLIO_HEADINGS = "LOAD_PORTFOLIO_HEADINGS";
export const LOAD_PORTFOLIO_DETAILS = "LOAD_PORTFOLIO_DETAILS";
export const LOAD_PORTFOLIO_CHART = "LOAD_PORTFOLIO_CHART";

export const LOAD_WRAPPER_DETAILS_CONTENT = "LOAD_WRAPPER_DETAILS_CONTENT";

export const LOAD_INFORMATION_PAGE_CONTENT = "LOAD_INFORMATION_PAGE_CONTENT";

export const LOAD_ERROR_PAGE_CONTENT = "LOAD_ERROR_PAGE_CONTENT";

export const LOAD_OFF_PLATFORM_ASSETS_CONTENT = "LOAD_OFF_PLATFORM_ASSETS_CONTENT";

export const LOAD_WRAPPER_STATEMENT_CONTENT = "LOAD_WRAPPER_STATEMENT_CONTENT";
export const LOAD_WRAPPER = "LOAD_WRAPPER";
export const LOAD_WRAPPER_ASSET_ALLOCATIONS = "LOAD_WRAPPER_ASSET_ALLOCATIONS";

export const LOAD_MONEY_IN_OUT_CONTENT = "LOAD_MONEY_IN_OUT_CONTENT";

export const LOAD_PORTFOLIO_VALUATION_HEADINGS = "LOAD_PORTFOLIO_VALUATION_HEADINGS";

export const LOAD_VALUATION_CONTENT = "LOAD_VALUATION_CONTENT";

export const LOAD_WRAPPER_VALUATION_CONTENT = "LOAD_WRAPPER_VALUATION_CONTENT";
export const LOAD_DOCUMENTS_CONTENT = "LOAD_DOCUMENTS_CONTENT";

export const LOAD_WRAPPER_TRANSACTIONS_CONTENT = "LOAD_WRAPPER_TRANSACTIONS_CONTENT";

export const LOAD_NO_BALANCE_PAGE_CONTENT = "LOAD_NO_BALANCE_PAGE_CONTENT";

export const LOAD_NOT_IMPERSONATING_CONTENT = "LOAD_NOT_IMPERSONATING_CONTENT";

export const LOAD_INVESTOR_DETAILS = "LOAD_INVESTOR_DETAILS";

export const LOAD_WELCOME_MESSAGE_CONTENT = "LOAD_WELCOME_MESSAGE_CONTENT";

export const LOAD_SESSION_EXPIRY_CONTENT = "LOAD_SESSION_EXPIRY_CONTENT";

export const LOAD_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT = "LOAD_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT";

export const LOAD_BROWSER_NOT_SUPPORTED_PAGE_CONTENT = "LOAD_BROWSER_NOT_SUPPORTED_PAGE_CONTENT";

export const LOAD_PORTFOLIO_SUMMARY_BANNER = "LOAD_PORTFOLIO_SUMMARY_BANNER";

export const Actions = {
    LOAD_CONTENT: "LOAD_CONTENT"
}