import {
    ContentfulWrapperPortfolioSummaryBannerLabelingContent,
    WrapperPortfolioSummaryBannerLabeling
} from "@/typings/wrapper-portfolio-summary-banner-module";
import { Entry } from "contentful";
import { ContentfulKeys } from "./contentful-keys";
import ContentfulService from "./ContentfulService";

export default class WrapperPortfolioSummaryBannerContentfulService extends ContentfulService {
    public getWrapperPortfolioSummaryBannerContent(): Promise<ContentfulWrapperPortfolioSummaryBannerLabelingContent> {
        return this.getContentfulEntry<ContentfulWrapperPortfolioSummaryBannerLabelingContent, WrapperPortfolioSummaryBannerLabeling>(
            ContentfulKeys.WrapperPortfolioSummaryBannerLabeling,
            (result: Entry<WrapperPortfolioSummaryBannerLabeling>) => {
                return {
                    wrapperSummaryModelPortfolioLabel: result.fields.wrapperSummaryModelPortfolioLabel,
                    wrapperSummaryTotalValueLabel: result.fields.wrapperSummaryTotalValueLabel
                };
            }
        );
    }
}
