import { ContentfulAssetAllocationChart } from "@/typings/contentful";

export default class ChartColourService {
    private contentfulIdToNoviaAssetsMap = new Map<string, string>();

    constructor() {
        this.setMapData("juniorIsaChartColour", "JuniorISA");
        this.setMapData("absoluteReturnChartColour", "Absolute Return");
        this.setMapData("intEquityeuropeExUkChartColour", "Int. Equity:Europe Ex UK");
        this.setMapData("intEquityglobalEmergingMarketsChartColour", "Int. Equity:Global Emerging Markets");
        this.setMapData("intEquitynorthAmericaChartColour", "Int. Equity:North America");
        this.setMapData("intEquitypacificExJapanChartColour", "Int. Equity:Pacific Ex Japan");
        this.setMapData("multiAssetClassChartColour", "Multi Asset Class");
        this.setMapData("otherChartColour", "Other");
        this.setMapData("stocktradeCashequitiesChartColour", "Stocktrade Cash/Equities");
        this.setMapData("ukEquitiesChartColour", "UK Equities");
        this.setMapData("ukFixedIntChartColour", "UK Fixed Int");
        this.setMapData("noviaCashFacilityChartColour", "Novia Cash Facility");
        this.setMapData("intEquityjapanChartColour", "Int. Equity:Japan");
        this.setMapData("propertyChartColour", "Property");
        this.setMapData("specialistChartColour", "Specialist");
        this.setMapData("cashChartColour", "Cash");
        this.setMapData("intFixedInterestChartColour", "Int. Fixed Interest");
        this.setMapData("pendingTradesChartColour", "Pending Trades");
        this.setMapData("cashInFlightChartColour", "Cash and in-flights");
    }

    public getColoursMapFromContentfulKeys(contentfulChartData: ContentfulAssetAllocationChart): Map<string, string> {
        const map = new Map<string, string>();
        for (const [key, value] of Object.entries(contentfulChartData)) {
            const assetName = this.contentfulIdToNoviaAssetsMap.get(key);
            if (assetName && !map.has(assetName)) {
                map.set(assetName, value);
            }
        }

        return map;
    }

    private setMapData(key: string, value: string): void {
        if (!this.contentfulIdToNoviaAssetsMap.has(key)) {
            this.contentfulIdToNoviaAssetsMap.set(key, value);
        }
    }
}
