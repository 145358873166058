import PortfolioContentfulService from "@/services/contentful/PortfolioContentfulService";
import {
    MoneyInOutPageContentHeadings,
    MoneyInOutPageHeadings,
    MoneyInOutTableHeadings
} from "@/typings/moneyinout-page";
import { Commit } from "vuex";
import { LOAD_MONEY_IN_OUT_CONTENT } from "../action-types";
import { GET_MONEY_IN_OUT_CONTENT, GET_MONEY_IN_OUT_TABLE_HEADINGS } from "../getters";
import { SAVE_MONEY_IN_OUT_CONTENT, SET_MONEY_IN_OUT_LOADED } from "../mutation-types";
import { MoneyInOutState } from "../typings/money-in-out";

const contentful = new PortfolioContentfulService();

const state = (): MoneyInOutState => ({
    moneyInOutPageHeadings: {
        pageHeading: "",
        totalValueTitle: "",
        wrapperValue: "",
        transactionNameTableHeading: "",
        dateTableHeading: "",
        valueTableHeading: "",
        asAtDate: ""
    },
    isLoaded: false
});

const getters = {
    [GET_MONEY_IN_OUT_CONTENT]: (state: MoneyInOutState): MoneyInOutPageContentHeadings => {
        return {
            pageHeading: state.moneyInOutPageHeadings.pageHeading,
            totalValueTitle: state.moneyInOutPageHeadings.totalValueTitle,
            wrapperValue: state.moneyInOutPageHeadings.wrapperValue,
            asAtDate: state.moneyInOutPageHeadings.asAtDate
        };
    },
    [GET_MONEY_IN_OUT_TABLE_HEADINGS]: (state: MoneyInOutState): MoneyInOutTableHeadings => {
        return {
            transactionNameTableHeading: state.moneyInOutPageHeadings.transactionNameTableHeading,
            dateTableHeading: state.moneyInOutPageHeadings.dateTableHeading,
            valueTableHeading: state.moneyInOutPageHeadings.valueTableHeading
        };
    }
};

const mutations = {
    [SAVE_MONEY_IN_OUT_CONTENT](state: MoneyInOutState, moneyInOutPage: MoneyInOutPageHeadings) {
        state.moneyInOutPageHeadings = moneyInOutPage;
    },
    [SET_MONEY_IN_OUT_LOADED](state: MoneyInOutState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    async [LOAD_MONEY_IN_OUT_CONTENT]({ commit, state }: { commit: Commit; state: MoneyInOutState }) {
        if (!state.isLoaded) {
            contentful.getPortfolioStatementContent().then((result: MoneyInOutPageHeadings) => {
                commit(SAVE_MONEY_IN_OUT_CONTENT, result);
                commit(SET_MONEY_IN_OUT_LOADED, true);
            });
        }
    }
};

export const MoneyInOutPageModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
