














































import { Component, Mixins, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import _ from "lodash";

import { BRow, BCol, BFormGroup, BFormRadio, BTable } from "bootstrap-vue";
import SummaryBanner from "../components/summary-banner/SummaryBanner.vue";
import SummaryBannerContainer from "../components/summary-banner/SummaryBannerContainer.vue";
import SummaryBannerHeader from "../components/summary-banner/SummaryBannerHeader.vue";
import SummaryBannerValue from "../components/summary-banner/SummaryBannerValue.vue";
import Skeleton from "../components/Skeleton.vue";
import FluidContainer from "../components/FluidContainer.vue";
import ValuationsPanel from "../components/chart/ValuationsPanel.vue";
import ReportingPeriod from "../components/valuation/ReportingPeriod.vue";
import ValuationTable from "../components/valuation/ValuationTable.vue";

import AuthMixin from "../mixins/AuthMixin";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";
import { Namespaces } from "../store/namespaces";
import {
    LOAD_INVESTOR_DETAILS,
    LOAD_PORTFOLIO_DETAILS,
    LOAD_PORTFOLIO_HEADINGS,
    LOAD_PORTFOLIO_VALUATION_HEADINGS,
} from "../store/action-types";
import {
    GET_INVESTOR_DETAILS,
    GET_PORTFOLIO_DETAILS,
    GET_PORTFOLIO_PAGE_HEADINGS,
    GET_PORTFOLIO_VALUATION_CHART_CONTENT,
    GET_PORTFOLIO_VALUATION_PAGE_HEADINGS,
    GET_PORTFOLIO_VALUATION_RADIO_HEADINGS,
} from "../store/getters";
import { PortfolioHeadings } from "../typings/portfolio-page";
import { Portfolio } from "../typings/portfolio";
import NumberMixin from "../mixins/NumberMixin";
import { Valuation, ValuationDetails } from "../typings/valuation-details";
import StatementsMixin from "../mixins/StatementsMixin";
import { PortfolioValuationChartContent, PortfolioValuationPageContentHeadings } from "../typings/portfolio-valuations";
import SummaryBannerMixin from "../mixins/SummaryBannerMixin";
import { InvestorDetails } from "@/typings/investor-details";
import PortfolioSummaryBanner from "../components/PortfolioSummaryBanner.vue";
import ReactivePageTitle from "@/components/ReactivePageTitle.vue";

@Component({
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormRadio,
        BTable,
        SummaryBanner,
        SummaryBannerContainer,
        SummaryBannerHeader,
        SummaryBannerValue,
        Skeleton,
        FluidContainer,
        ValuationsPanel,
        ReportingPeriod,
        ValuationTable,
        PortfolioSummaryBanner,
        ReactivePageTitle
    },
    computed: mapGetters({
        getPortfolioHeadings: `${Namespaces.Portfolio}/${GET_PORTFOLIO_PAGE_HEADINGS}`,
        getPortfolioDetails: `${Namespaces.Portfolio}/${GET_PORTFOLIO_DETAILS}`,
        getPortfolioValuationPageHeadings: `${Namespaces.PortfolioValuation}/${GET_PORTFOLIO_VALUATION_PAGE_HEADINGS}`,
        getPortfolioValuationRadioHeadings: `${Namespaces.PortfolioValuation}/${GET_PORTFOLIO_VALUATION_RADIO_HEADINGS}`,
        getPortfolioValuationChartContent: `${Namespaces.PortfolioValuation}/${GET_PORTFOLIO_VALUATION_CHART_CONTENT}`,
        getInvestorDetails: `${Namespaces.InvestorDetails}/${GET_INVESTOR_DETAILS}`,
    }),
})
export default class PortfolioValuationPage extends Mixins(
    AuthMixin,
    ErrorHandlerMixin,
    NumberMixin,
    StatementsMixin,
    SummaryBannerMixin
) {
    public getPortfolioHeadings!: PortfolioHeadings;
    public getPortfolioDetails!: Portfolio;
    public getPortfolioValuationPageHeadings!: PortfolioValuationPageContentHeadings;
    public getPortfolioValuationChartContent!: PortfolioValuationChartContent;
    public getInvestorDetails!: InvestorDetails;

    public currentUser?: string = "";
    public selectedInterval = 6;
    public valuationDetails: ValuationDetails = {} as ValuationDetails;
    public isDataLoading = false;

    @Watch("selectedInterval")
    private selectedIntervalChanged(): void {
        this.getValuationsDataFromApi();
    }

    public get currentUserName(): string {
        return this.getInvestorDetails.investorName;
    }

    public get showSkeleton(): boolean {
        return _.isEmpty(this.valuationDetails);
    }

    public get portfolioValue(): string {
        return this.getTotalValueContent(
            this.getPortfolioHeadings.portfolioValueTitle,
            this.getPortfolioDetails.totalValue
        );
    }

    public get dailyValuations(): Array<Valuation> {
        return this.valuationDetails.dailyValuations ?? [];
    }

    public get statementPeriodWithDates(): string {
        return this.getStatementPeriodWithDates(
            this.selectedInterval,
            this.getPortfolioValuationPageHeadings.valuationPeriod
        );
    }

    public mounted(): void {
        this.getAuthenticatedUser().then(() => {
            this.$store
                .dispatch(`${Namespaces.Portfolio}/${LOAD_PORTFOLIO_DETAILS}`)
                .catch((error) => this.handleError(error.response?.status));
            this.getValuationsDataFromApi();
        });
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.Portfolio}/${LOAD_PORTFOLIO_HEADINGS}`)
            .catch((error) => this.handleError(error.response?.status));
        this.$store
            .dispatch(`${Namespaces.PortfolioValuation}/${LOAD_PORTFOLIO_VALUATION_HEADINGS}`)
            .catch((error) => this.handleError(error.response?.status));
        this.$store
            .dispatch(`${Namespaces.InvestorDetails}/${LOAD_INVESTOR_DETAILS}`)
            .catch((error) => this.handleError(error.response?.status));
    }

    private getValuationsDataFromApi(): void {
        this.isDataLoading = true;
        this.$api
            .getPortfolioValuation(this.selectedInterval)
            .then((result: ValuationDetails) => {
                this.valuationDetails = result;
                this.isDataLoading = false;
            })
            .catch((error) => this.handleError(error.response?.status));
    }
}
