import { environment } from "@/environment";
import _ from "lodash";
import { User } from "oidc-client";

export default class PendoService {
    // Initialize Pendo
    constructor() {
        if (typeof window.pendo !== 'undefined') {
            window.pendo.initialize({
                apiKey: environment.pendoApiKey,
            });
        }
    }

    public registerPendoUserContext(user: User): void {
        if (user) {
            const investorContext = user.profile.investor_context;
            const impersonationContext = user.profile.impersonation_context;
            const impersonating = !_.isEmpty(investorContext);
            const isInvestor = _.isEmpty(impersonationContext) && _.isEmpty(investorContext);

            // Identify users
            if (!_.isEmpty(investorContext) && _.isEmpty(impersonationContext))
            {
                window.pendo.identify({
                    visitor: {
                        id: user.profile.id,
                        usertype: user.profile.user_type,
                        is_investor: isInvestor,
                        is_impersonating: impersonating,
                        is_impersonating_from_advz: true,
                        impersonating_investor: investorContext,
                        impersonating_user_type: "Investor",
                    }
                });
            }
            else if (!_.isEmpty(investorContext) && !_.isEmpty(impersonationContext))
            {
                window.pendo.identify({
                    visitor: {
                        id: user.profile.id,
                        usertype: user.profile.user_type,
                        is_investor: isInvestor,
                        is_impersonating: impersonating,
                        impersonating_investor: investorContext,
                        impersonating_user_type: "Investor",
                    }
                });
            }
            else
            {
                window.pendo.identify({
                    visitor: {
                        id: user.profile.id,
                        usertype: user.profile.user_type,
                        userId: user.profile.entity_id,
                        is_investor: isInvestor,
                        is_impersonating: impersonating,
                    }
                });
            }
        }
    }
}