




















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { BContainer, BRow, BCol } from "bootstrap-vue";
import { PointOptionsObject } from "highcharts";
import { Chart } from "highcharts-vue";

import ChartBuilderService from "../../services/ChartBuilderService";

import { AssetAllocationDonutData } from "@/typings/asset-allocation-donut-data";

@Component({
    components: {
        BContainer,
        BRow,
        BCol,
        Chart,
    },
})
export default class AssetAllocationPanel extends Vue {
    @Prop()
    public title?: string;

    @Prop({ default: () => [] })
    public assetAllocation!: Array<AssetAllocationDonutData>;

    @Prop({ default: "" })
    public assetAllocationHeader!: string;

    @Prop({ default: true })
    public verticalOrientation!: boolean;

    @Watch("assetAllocation")
    public assetAllocationChanged() {
        this.setChartOptions();
    }

    private chartOptions: Highcharts.Options = {};

    public get chartColumnAttributes(): Record<string, string> {
        if (this.verticalOrientation) {
            return {
                sm: "12",
                lg: "10",
                offsetLg: "1",
            };
        }

        return {
            sm: "12",
            lg: "5",
        };
    }

    public get tableColumnAttributes(): Record<string, string> {
        if (this.verticalOrientation) {
            return {
                sm: "12",
                lg: "12",
            };
        }

        return {
            sm: "12",
            lg: "7",
        };
    }

    public get donutChartOptions(): Highcharts.Options {
        return this.chartOptions;
    }

    public async mounted() {
        await this.setChartOptions();
    }

    private async setChartOptions(): Promise<void> {
        const chartBuilder = new ChartBuilderService();
        const options = await chartBuilder.getDonutChartOptions(
            this.assetAllocation.map((value: AssetAllocationDonutData) => {
                return {
                    name: `${value.sector} (${value.weighting.toFixed(2)}%)`,
                    key: `${value.sector}`,
                    y: value.weighting,
                    value: value.value,
                } as PointOptionsObject & {key: string};
            })
        );

        this.chartOptions = options;
    }
}
