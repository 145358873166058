
























import { Component, Prop, Vue } from "vue-property-decorator";
import { BContainer, BRow, BCol, BNavbar } from "bootstrap-vue";
import NavBarCollapse from "./NavBarCollapse.vue";
import { IS_WHITELABELED } from "@/store/getters";
import { Namespaces } from "@/store/namespaces";
import store from "@/store"

@Component({
    components: {
        BContainer,
        BRow,
        BCol,
        BNavbar,
        NavBarCollapse
    }
})
export default class NavBar extends Vue {
    @Prop()
    collapseId?: string;

    @Prop({ default: true })
    displayNavigationItems!: boolean;

    public closeNavbarClicked() {
        this.$emit("closeNavbarClicked");
    }

    public get isWhitelabeled(): number | boolean {
        return store.getters[`${Namespaces.CompanyInformation}/${IS_WHITELABELED}`];
    }
}
