import { Entry } from "contentful";
import { ContentfulKeys } from "./contentful-keys";
import ContentfulService from "./ContentfulService";

import { ContentfulDocumentsPage } from "@/typings/contentful";
import { DocumentsPageHeadings } from "@/typings/documents-page";

export default class DocumentsContentfulService extends ContentfulService {
    public getDocumentsPageContent(): Promise<DocumentsPageHeadings> {
        return this.getContentfulEntry<ContentfulDocumentsPage, DocumentsPageHeadings>(
            ContentfulKeys.Documents,
            (result: Entry<ContentfulDocumentsPage>) => {
                return {
                    dateTableHeading: result.fields.documentDateTableHeading,
                    documentsHeading: result.fields.documentsHeading,
                    documentNameTableHeading: result.fields.documentNameTableHeading,
                    documentTypeTableHeading: result.fields.documentTypeTableHeading,
                    documentCategoryTableHeading: result.fields.documentCategoryTableHeading,
                    downloadButtonText: result.fields.downloadDocumentButtonText,
                    downloadTableHeading: result.fields.downloadDocumentTableHeading,
                    pageHeading: result.fields.documentsTitle,
                    documentDownloadErrorMessage: result.fields.documentDownloadErrorMessage,
                    documentsRelatedInvestorHeading: result.fields.documentsRelatedInvestorHeading,
                    noDocumentsFoundTableMessage: result.fields.noDocumentsFoundTableMessage
                };
            }
        );
    }
}
