import { Commit } from "vuex";
import WrapperContentfulService from "@/services/contentful/WrapperContentfulService";

import {
    WrapperStatementHeaders,
    WrapperStatementPageContentHeadings,
    WrapperStatementRadioHeaders,
    WrapperStatementTableHeaders,
} from "@/typings/wrapper-statement";
import { WrapperStatementState } from "../typings/wrapper-statement";
import {
    GET_WRAPPER_STATEMENT_CONTENT,
    GET_WRAPPER_STATEMENT_RADIO_HEADERS,
    GET_WRAPPER_STATEMENT_TABLE_HEADERS,
} from "../getters";
import {
    SAVE_WRAPPER_STATEMENT_CONTENT,
    SAVE_WRAPPER_STATEMENT_RADIO_HEADINGS,
    SAVE_WRAPPER_STATEMENT_TABLE_HEADINGS,
    SET_WRAPPER_STATEMENT_LOADED
} from "../mutation-types";
import { LOAD_WRAPPER_STATEMENT_CONTENT } from "../action-types";

const contentful = new WrapperContentfulService();

const state = (): WrapperStatementState => ({
    wrapperStatementHeadings: {
        wrapperStatementHeader: "",
        wrapperValueTitle: "",
        modelPortfolioTitle: "",
        noTransactionsAvailableMessage: "",
        dearCeoLetterLabel: "",
    },
    radioHeadings: {
        lastSixMonthsLabel: "",
        lastTwelveMonthsLabel: "",
        statementPeriod: ""
    },
    tableHeadings: {
        transactionNameTableHeader: "",
        dateTableHeader: "",
        valueTableHeader: ""
    },
    isLoaded: false
});

const getters = {
    [GET_WRAPPER_STATEMENT_CONTENT]: (state: WrapperStatementState): WrapperStatementHeaders => {
        return {
            wrapperStatementHeader: state.wrapperStatementHeadings.wrapperStatementHeader,
            wrapperValueTitle: state.wrapperStatementHeadings.wrapperValueTitle,
            modelPortfolioTitle: state.wrapperStatementHeadings.modelPortfolioTitle,
            noTransactionsAvailableMessage: state.wrapperStatementHeadings.noTransactionsAvailableMessage,
            dearCeoLetterLabel: state.wrapperStatementHeadings.dearCeoLetterLabel
        };
    },
    [GET_WRAPPER_STATEMENT_RADIO_HEADERS]: (state: WrapperStatementState): WrapperStatementRadioHeaders => {
        return {
            lastSixMonthsLabel: state.radioHeadings.lastSixMonthsLabel,
            lastTwelveMonthsLabel: state.radioHeadings.lastTwelveMonthsLabel,
            statementPeriod: state.radioHeadings.statementPeriod
        };
    },
    [GET_WRAPPER_STATEMENT_TABLE_HEADERS]: (state: WrapperStatementState): WrapperStatementTableHeaders => {
        return {
            transactionNameTableHeader: state.tableHeadings.transactionNameTableHeader,
            dateTableHeader: state.tableHeadings.dateTableHeader,
            valueTableHeader: state.tableHeadings.valueTableHeader
        };
    },
};

const mutations = {
    [SAVE_WRAPPER_STATEMENT_CONTENT](state: WrapperStatementState, model: WrapperStatementPageContentHeadings) {
        state.wrapperStatementHeadings = model;
    },
    [SAVE_WRAPPER_STATEMENT_RADIO_HEADINGS](state: WrapperStatementState, model: WrapperStatementRadioHeaders) {
        state.radioHeadings = model;
    },
    [SAVE_WRAPPER_STATEMENT_TABLE_HEADINGS](state: WrapperStatementState, model: WrapperStatementTableHeaders) {
        state.tableHeadings = model;
    },
    [SET_WRAPPER_STATEMENT_LOADED](state: WrapperStatementState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    [LOAD_WRAPPER_STATEMENT_CONTENT]({ commit, state }: { commit: Commit; state: WrapperStatementState }) {
        if (!state.isLoaded) {
            return contentful.getwrapperStatementContent().then((result: WrapperStatementPageContentHeadings) => {
                commit(SAVE_WRAPPER_STATEMENT_CONTENT, {
                    wrapperStatementHeader: result.wrapperStatementHeader,
                    wrapperValueTitle: result.wrapperValueTitle,
                    modelPortfolioTitle: result.modelPortfolioTitle,
                    noTransactionsAvailableMessage: result.noTransactionsAvailableMessage,
                    dearCeoLetterLabel: result.dearCeoLetterLabel
                });
                commit(SAVE_WRAPPER_STATEMENT_RADIO_HEADINGS, {
                    lastSixMonthsLabel: result.lastSixMonthsLabel,
                    lastTwelveMonthsLabel: result.lastTwelveMonthsLabel,
                    statementPeriod: result.statementPeriod
                });
                commit(SAVE_WRAPPER_STATEMENT_TABLE_HEADINGS, {
                    transactionNameTableHeader: result.transactionNameTableHeader,
                    dateTableHeader: result.dateTableHeader,
                    valueTableHeader: result.valueTableHeader
                });
                commit(SET_WRAPPER_STATEMENT_LOADED, true);
            });
        }
    }
};

export const WrapperStatementModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
