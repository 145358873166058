import { render, staticRenderFns } from "./PortfolioBreakdown.vue?vue&type=template&id=3a2042df&"
import script from "./PortfolioBreakdown.vue?vue&type=script&lang=ts&"
export * from "./PortfolioBreakdown.vue?vue&type=script&lang=ts&"
import style0 from "./PortfolioBreakdown.vue?vue&type=style&index=0&id=3a2042df&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports