import { ContentfulWelcomeMessage } from "@/typings/contentful";
import { WelcomeMessageContent } from "@/typings/welcome-message";
import { Entry } from "contentful";
import { ContentfulKeys } from "./contentful-keys";
import ContentfulService from "./ContentfulService";

export default class WelcomeMessageContentfulService extends ContentfulService {
    public getWelcomeMessageContent(): Promise<WelcomeMessageContent> {
        return this.getContentfulEntry<ContentfulWelcomeMessage, WelcomeMessageContent>(
            ContentfulKeys.WelcomeMessage,
            (result: Entry<ContentfulWelcomeMessage>) => {
                return {
                    welcomeMessage: result.fields.welcomeMessage,
                    impersonationMessage: result.fields.impersonationMessage,
                    stopImpersonationMessage: result.fields.stopImpersonationMessage
                };
            }
        );
    }
}