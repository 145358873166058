import { Component, Vue } from "vue-property-decorator";

@Component
export default class ErrorHandlerMixin extends Vue {
    public handleError(statusCode?: string): void {
        if (statusCode !== undefined) {
            this.$router.push({ name: "Error", params: { statusCode } });
        } else {
            this.$router.push({ name: "Error" });
        }
    }
}
