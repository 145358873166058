import { Entry } from "contentful";
import { ContentfulKeys } from "./contentful-keys";
import ContentfulService from "./ContentfulService";

import { ContentfulWrapperValuationPage } from "@/typings/contentful";
import { WrapperValuationPageContent } from "@/typings/wrapper-valuation";

export default class WrapperValuationContentfulService extends ContentfulService {
    public getWrapperValuationPageContent(): Promise<WrapperValuationPageContent> {
        return this.getContentfulEntry<ContentfulWrapperValuationPage, WrapperValuationPageContent>(
            ContentfulKeys.WrapperValuation,
            (result: Entry<ContentfulWrapperValuationPage>) => {
                return {
                    modelPortfolioLabel: result.fields.modelPortfolioLabel,
                    totalValueLabel: result.fields.totalValueLabel,
                    wrapperValuation: result.fields.wrapperValuationTitle,
                    chartTitle: result.fields.chartTitle,
                    chartColour: result.fields.chartColour,
                    sectorTableHeading: result.fields.sectorTableHeading,
                    investmentNameTableHeading: result.fields.investmentNameTableHeading,
                    unitsTableHeading: result.fields.unitsTableHeading,
                    priceTableHeading: result.fields.priceTableHeading,
                    priceDateTableHeading: result.fields.priceDateTableHeading,
                    defaultInvestmentStrategyTableHeading: result.fields.defaultInvestmentStrategyTableHeading,
                    weightingTableHeading: result.fields.weightingTableHeading,
                    valueTableHeading: result.fields.valueTableHeading,
                    downloadValuationButton: result.fields.downloadValuationButton,
                    chartInformation: result.fields.chartInformation
                };
            }
        );
    }
}
