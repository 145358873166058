






















import { Component, Mixins } from "vue-property-decorator";
import { BRow, BCol } from "bootstrap-vue";
import SummaryBanner from "../components/summary-banner/SummaryBanner.vue";
import SummaryBannerContainer from "../components/summary-banner/SummaryBannerContainer.vue";
import SummaryBannerHeader from "../components/summary-banner/SummaryBannerHeader.vue";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";
import FluidContainer from "../components/FluidContainer.vue";

import { mapGetters } from "vuex";
import { Namespaces } from "../store/namespaces";
import { GET_BROWSER_NOT_SUPPORTED_PAGE_CONTENT } from "../store/getters";
import { LOAD_BROWSER_NOT_SUPPORTED_PAGE_CONTENT } from "../store/action-types"

import { UnsupportedBrowserPageModel } from "../typings/unsupported-browser-page-model";

@Component({
    components: {
        BRow,
        BCol,
        SummaryBanner,
        SummaryBannerContainer,
        SummaryBannerHeader,
        FluidContainer
    },
    computed: mapGetters({
        pageModel: `${Namespaces.BrowserNotSupported}/${GET_BROWSER_NOT_SUPPORTED_PAGE_CONTENT}`
    })
})
export default class InternetExplorerPage extends Mixins(ErrorHandlerMixin) {
    public pageModel!: UnsupportedBrowserPageModel;

    public get header(): string {
        return this.pageModel.header;
    }

    public get body(): string {
        return this.pageModel.body;
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.BrowserNotSupported}/${LOAD_BROWSER_NOT_SUPPORTED_PAGE_CONTENT}`)
            .catch(error => this.handleError(error.response?.status));
    }
}
