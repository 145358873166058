import DocumentsContentfulService from "@/services/contentful/DocumentsContentfulService";
import { DocumentsPageContentHeadings, DocumentsPageHeadings, DocumentsTableHeadings } from "@/typings/documents-page";
import { Commit } from "vuex";
import { LOAD_DOCUMENTS_CONTENT } from "../action-types";
import { GET_DOCUMENTS_CONTENT, GET_DOCUMENTS_TABLE_HEADINGS } from "../getters";
import { SAVE_DOCUMENTS_CONTENT, SET_DOCUMENTS_LOADED } from "../mutation-types";
import { DocumentsPageState } from "../typings/documents-page";

const contentful = new DocumentsContentfulService();

const state = (): DocumentsPageState => ({
    documentsPageHeadings: {
        pageHeading: "",
        documentsHeading: "",
        dateTableHeading: "",
        downloadTableHeading: "",
        documentNameTableHeading: "",
        documentTypeTableHeading: "",
        documentCategoryTableHeading: "",
        downloadButtonText: "",
        documentDownloadErrorMessage: "",
        documentsRelatedInvestorHeading: "",
        noDocumentsFoundTableMessage: ""
    },
    isLoaded: false
});

const getters = {
    [GET_DOCUMENTS_CONTENT]: (state: DocumentsPageState): DocumentsPageContentHeadings => {
        return {
            pageHeading: state.documentsPageHeadings.pageHeading,
            documentsHeading: state.documentsPageHeadings.documentsHeading,
            downloadButtonText: state.documentsPageHeadings.downloadButtonText,
            documentDownloadErrorMessage: state.documentsPageHeadings.documentDownloadErrorMessage,
            documentsRelatedInvestorHeading: state.documentsPageHeadings.documentsRelatedInvestorHeading
        };
    },
    [GET_DOCUMENTS_TABLE_HEADINGS]: (state: DocumentsPageState): DocumentsTableHeadings => {
        return {
            dateTableHeading: state.documentsPageHeadings.dateTableHeading,
            downloadTableHeading: state.documentsPageHeadings.downloadTableHeading,
            documentNameTableHeading: state.documentsPageHeadings.documentNameTableHeading,
            documentTypeTableHeading: state.documentsPageHeadings.documentTypeTableHeading,
            documentCategoryTableHeading: state.documentsPageHeadings.documentCategoryTableHeading,
            noDocumentsFoundTableMessage: state.documentsPageHeadings.noDocumentsFoundTableMessage

        };
    }
};

const mutations = {
    [SAVE_DOCUMENTS_CONTENT](state: DocumentsPageState, model: DocumentsPageHeadings) {
        state.documentsPageHeadings = model;
    },
    [SET_DOCUMENTS_LOADED](state: DocumentsPageState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    [LOAD_DOCUMENTS_CONTENT]({ commit, state }: { commit: Commit; state: DocumentsPageState }) {
        if (!state.isLoaded) {
            contentful.getDocumentsPageContent().then((result: DocumentsPageHeadings) => {
                commit(SAVE_DOCUMENTS_CONTENT, result);
                commit(SET_DOCUMENTS_LOADED, true);
            });
        }
    }
};

export const DocumuntsModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
