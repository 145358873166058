export const GET_COMPANY_INFORMATION = "GET_COMPANY_INFORMATION";
export const GET_WHITELABELLING_CONTENT = "GET_WHITELABELLING_CONTENT";
export const IS_WHITELABELED = "IS_WHITELABELED";
export const GET_GLOBAL_CONTENT = "GET_GLOBAL_CONTENT";
export const GET_INFORMATION_PAGE_CONTENT = "GET_INFORMATION_PAGE_CONTENT";
export const GET_NAVIGATION_PAGES = "GET_NAVIGATION_PAGES";

export const GET_PORTFOLIO_PAGE_HEADINGS = "GET_PORTFOLIO_PAGE_HEADINGS";
export const GET_PORTFOLIO_DETAILS = "GET_PORTFOLIO_DETAILS";

export const GET_CHART_COLOURS = "GET_CHART_COLOURS";
export const GET_CHART_TABLE_HEADINGS = "GET_CHART_TABLE_HEADINGS";

export const GET_WRAPPER_DETAILS_CONTENT = "GET_WRAPPER_DETAILS_CONTENT";
export const GET_WRAPPER_TABLE_HEADINGS = "GET_WRAPPER_TABLE_HEADINGS";

export const GET_OFF_PLATFORM_ASSETS_CONTENT = "GET_OFF_PLATFORM_ASSETS_CONTENT";
export const GET_OFF_PLATFORM_ASSETS_TABLE_HEADINGS = "GET_OFF_PLATFORM_ASSETS_TABLE_HEADINGS";

export const GET_WRAPPER_STATEMENT_CONTENT = "GET_WRAPPER_STATEMENT_CONTENT";
export const GET_WRAPPER_STATEMENT_HELPER_TEXT = "GET_WRAPPER_STATEMENT_HELPER_TEXT";
export const GET_WRAPPER_STATEMENT_RADIO_HEADERS = "GET_WRAPPER_STATEMENT_RADIO_HEADERS";
export const GET_WRAPPER_STATEMENT_TABLE_HEADERS = "GET_WRAPPER_STATEMENT_TABLE_HEADERS";
export const GET_WRAPPER_ASSET_ALLOCATIONS_BY_ID = "GET_WRAPPER_ASSET_ALLOCATIONS_BY_ID";

export const GET_MONEY_IN_OUT_CONTENT = "GET_MONEY_IN_OUT_CONTENT";
export const GET_MONEY_IN_OUT_TABLE_HEADINGS = "GET_MONEY_IN_OUT_TABLE_HEADINGS";

export const GET_PORTFOLIO_VALUATION_PAGE_HEADINGS = "GET_PORTFOLIO_VALUATION_PAGE_HEADINGS";
export const GET_PORTFOLIO_VALUATION_RADIO_HEADINGS = "GET_PORTFOLIO_VALUATION_RADIO_HEADINGS";
export const GET_PORTFOLIO_VALUATION_CHART_CONTENT = "GET_PORTFOLIO_VALUATION_CHART_CONTENT";

export const GET_REPORTING_PERIOD_RADIO_CONTENT = "GET_REPORTING_PERIOD_RADIO_CONTENT";
export const GET_VALUATION_TABLE_CONTENT = "GET_VALUATION_TABLE_CONTENT";

export const GET_WRAPPER_VALUATION_PAGE_HEADINGS = "GET_WRAPPER_VALUATION_PAGE_HEADINGS";
export const GET_WRAPPER_VALUATION_CHART_CONTENT = "GET_WRAPPER_VALUATION_CHART_CONTENT";
export const GET_WRAPPER_VALUATION_TABLE_CONTENT = "GET_WRAPPER_VALUATION_TABLE_CONTENT";
export const GET_WRAPPER_VALUATION_BUTTONS = "GET_WRAPPER_VALUATION_BUTTONS";

export const GET_DOCUMENTS_CONTENT = "GET_DOCUMENTS_CONTENT";
export const GET_DOCUMENTS_TABLE_HEADINGS = "GET_DOCUMENTS_TABLE_HEADINGS";

export const GET_NO_BALANCE_PAGE_CONTENT = "GET_NO_BALANCE_PAGE_CONTENT";

export const GET_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT = "GET_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT";

export const GET_NOT_IMPERSONATING_CONTENT = "GET_NOT_IMPERSONATING_CONTENT";

export const GET_INVESTOR_DETAILS = "GET_INVESTOR_DETAILS";

export const GET_ERROR_PAGE_CONTENT = "GET_ERROR_PAGE_CONTENT";

export const GET_WELCOME_MESSAGE_CONTENT = "GET_WELCOME_MESSAGE_CONTENT";

export const GET_SESSION_EXPIRY_CONTENT = "GET_SESSION_EXPIRY_CONTENT";

export const GET_BROWSER_NOT_SUPPORTED_PAGE_CONTENT = 'GET_BROWSER_NOT_SUPPORTED_PAGE_CONTENT';

export const GET_WRAPPER_BY_ID = "GET_WRAPPER_BY_ID";

export const GET_WRAPPER_PORTFOLIO_BANNER_LABELS = "GET_WRAPPER_PORTFOLIO_BANNER_LABELS";
export const GET_PORTFOLIO_SUMMARY_BANNER_LABELS = "GET_PORTFOLIO_SUMMARY_BANNER_LABELS";

export const GET_RELATED_INVESTOR_BY_WRAPPER_ID = "GET_RELATED_INVESTOR_BY_WRAPPER_ID";
export const GET_WRAPPER_TRANSACTIONS_PAGE_HEADINGS = "GET_WRAPPER_TRANSACTIONS_PAGE_HEADINGS";
export const GET_WRAPPER_TRANSACTIONS_TABLE_CONTENT = "GET_WRAPPER_TRANSACTIONS_TABLE_CONTENT";
