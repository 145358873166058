import Vue from "vue";
import { ContentfulClientApi, Entry } from "contentful";

type MapFunction<TContentful, TResult> = (result: Entry<TContentful>) => TResult;

export default abstract class ContentfulService {
    protected get Contentful(): ContentfulClientApi {
        return Vue.prototype.$contentful;
    }

    protected getContentfulEntry<TContentful, TResult>(
        contentfulKey: string,
        map: MapFunction<TContentful, TResult>
    ): Promise<TResult> {
        return new Promise((resolve, reject) => {
            this.Contentful.getEntry<TContentful>(contentfulKey)
                .then((result: Entry<TContentful>) => {
                    resolve(map(result));
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }
}
