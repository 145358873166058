





























import { Component, Prop, Vue } from "vue-property-decorator";
import { Wrapper } from "../../typings/wrapper";

@Component({
    components: {},
})
export default class WrapperSummaryGroup extends Vue {
    @Prop()
    groupHeading!: string;

    @Prop()
    consolidatedValue!: string;

    @Prop()
    wrapper!: Wrapper;

    @Prop()
    numberOfWrappers!: number;

    @Prop()
    offPlatformAssetsExcludedTotalMessage!: string;

    public get isOffPlatformAssets(): boolean {
        return this.wrapper.name.toLowerCase() == "off platform assets";
    }
}
