




import { Component, Mixins } from "vue-property-decorator";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";
import { GET_COMPANY_INFORMATION } from "../store/getters";
import { Namespaces } from "../store/namespaces";
import { CompanyInformation } from "../typings/company-information";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { setHrefTargetBlank } from "../helpers/url-helper";

@Component({
    components: {}
})
export default class CompanyRegistration extends Mixins(ErrorHandlerMixin) {
    public get companyRegistration(): string {
        const htmlString = documentToHtmlString(this.companyInformation.companyRegistration);
        return setHrefTargetBlank(htmlString);
    }

    private get companyInformation(): CompanyInformation {
        return this.$store.getters[`${Namespaces.CompanyInformation}/${GET_COMPANY_INFORMATION}`];
    }
}
