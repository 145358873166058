import { WhiteLabellingDetails } from "@/typings/whitelabelling-details";
import { ContentfulWhiteLabellingDetails } from "@/typings/whitelabelling";
import * as Contentful from "contentful";
import { Asset, ContentfulClientApi, EntryCollection } from "contentful";
import { environment } from '@/environment'

export default class WhiteLabellingContentfulService {
    private contentFulClient: ContentfulClientApi;

    constructor() {
        this.contentFulClient = Contentful.createClient({
            space: environment.whitelabellingContentfulSpace,
            accessToken: environment.whitelabellingContentfulAccessToken
        });
    }

    public getBranchWhiteLabelledInformation(branchId: number): Promise<WhiteLabellingDetails> {
        return new Promise((resolve, reject) => {
            this.contentFulClient
                .getEntries<ContentfulWhiteLabellingDetails>({
                    content_type: "branchWhiteLabel",
                    "fields.branchId": branchId
                })
                .then((result: EntryCollection<ContentfulWhiteLabellingDetails>) => {
                    if (result.items.length != 1) {
                        resolve({
                            branchId: branchId,
                            enabled: false,
                            logoUrl: ""
                        });
                    }
                    // The URL of the image must be retrieved via a separate Contentful call
                    this.contentFulClient
                        .getAsset(result.items[0].fields.logo.sys.id)
                        .then((asset: Asset) => {
                            const whiteLabellingDetails: WhiteLabellingDetails = {
                                branchId: result.items[0].fields.branchId,
                                enabled: result.items[0].fields.enabled,
                                logoUrl: asset.fields.file.url
                            };
                            resolve(whiteLabellingDetails);
                        })
                        .catch((error: Error) => {
                            reject(error);
                        });
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }
}
