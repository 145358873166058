import { Entry } from "contentful";
import { ContentfulKeys } from "./contentful-keys";
import ContentfulService from "./ContentfulService";

import { ContentfulSessionExpiryContent } from "@/typings/contentful";
import { SessionExpiryContent } from "@/typings/session-expiry";

export default class SessionExpiryContentfulService extends ContentfulService {
    public getSessionExpiryContent(): Promise<SessionExpiryContent> {
        return this.getContentfulEntry<ContentfulSessionExpiryContent, SessionExpiryContent>(
            ContentfulKeys.SessionExpiry,
            (result: Entry<ContentfulSessionExpiryContent>) => {
                return {
                    expiryTitle: result.fields.expiryTitle,
                    warningMessage: result.fields.warningMessage,
                    cancelButtonText: result.fields.cancelButtonText,
                    logoutButtonText: result.fields.logoutButtonText,
                    stopImpersonatingButtonText: result.fields.stopImpersonatingButtonText
                };
            }
        );
    }
}
