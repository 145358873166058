



























import router from "@/router";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import moment from "moment";
import * as _ from "lodash";
import { Namespaces } from "@/store/namespaces";
import { LOAD_SESSION_EXPIRY_CONTENT } from "@/store/action-types";
import AuthMixin from "@/mixins/AuthMixin";
import ErrorHandlerMixin from "@/mixins/ErrorHandlerMixin";
import { GET_SESSION_EXPIRY_CONTENT } from "@/store/getters";
import { SessionExpiryContent } from "@/typings/session-expiry";
import { User } from "oidc-client";
import { BModal } from "bootstrap-vue";

@Component({
    components: { BModal },
    computed: mapGetters({
        getSessionExpiryContent: `${Namespaces.SessionExpiry}/${GET_SESSION_EXPIRY_CONTENT}`
    })
})
export default class SessionExpiry extends Mixins(AuthMixin, ErrorHandlerMixin) {
    private getSessionExpiryContent!: SessionExpiryContent;
    private isImpersonating = false;
    private timerId = 0;
    private previousPageTitleStack: string[] = [];
    private isExpired = false;
    private isWithinWarningThreshold = false;
    private sessionTimeRemainingInSeconds = 0;
    private eventsDelayingSessionExpiration = ["click", "keydown", "keyup", "scroll"];

    @Prop({ default: 1800 })
    sessionTimeoutLengthInSeconds!: number;

    @Prop({ default: 60 })
    sessionTimeoutWarningInSeconds!: number;

    get warningMessage(): string {
        return this.getSessionExpiryContent.warningMessage.replace(
            "{seconds}",
            this.sessionTimeRemainingInSeconds.toString()
        );
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.SessionExpiry}/${LOAD_SESSION_EXPIRY_CONTENT}`)
            .catch(error => this.handleError(error.response?.status));
        this.resetTimer();
    }

    public mounted(): void {
        this.getAuthenticatedUser().then((user: User) => {
            if (user) {
                if (user.profile.investor_context) {
                    this.isImpersonating = true;
                }

                this.timerId = setInterval(this.expirationMonitor, 1000);
                this.eventsDelayingSessionExpiration.forEach(e => this.addTimerBinding(e));
            }
        });
    }

    addTimerBinding(event: string) {
        window.addEventListener(event, _.throttle(this.resetTimer, 15000, { leading: true, trailing: false }));
    }

    resetTimer() {
        if (this.isExpired) {
            return;
        }

        localStorage.sessionExpiryStart = moment()
            .unix()
            .toString();
        this.restorePreviousPageTitle();
    }

    expirationMonitor() {
        this.updateRemainingSessionTime();
        if (this.sessionTimeRemainingInSeconds == 0) {
            this.expireSession();
        } else if (this.isRemainingTimeWithinWarningThreshold()) {
            this.setLogoutWarningPrompt(true);
        } else {
            this.setLogoutWarningPrompt(false);
        }
    }

    updateRemainingSessionTime() {
        const sessionStart = moment.unix(+localStorage.sessionExpiryStart);
        const remainder = this.sessionTimeoutLengthInSeconds - moment().diff(sessionStart, "seconds");
        this.sessionTimeRemainingInSeconds = remainder > 0 ? remainder : 0;
    }

    isRemainingTimeWithinWarningThreshold(): boolean {
        return this.sessionTimeRemainingInSeconds < this.sessionTimeoutWarningInSeconds;
    }

    setLogoutWarningPrompt(promptUser: boolean) {
        if (promptUser) {
            this.preservePreviousPageTitle();
            this.setWarningThresholdFlag(true);
            document.title = `(${this.sessionTimeRemainingInSeconds}) ${this.getSessionExpiryContent.expiryTitle}`;
        } else {
            this.setWarningThresholdFlag(false);
        }
    }

    preservePreviousPageTitle() {
        if (this.previousPageTitleStack.length == 0) {
            this.previousPageTitleStack.push(document.title);
        }
    }

    restorePreviousPageTitle() {
        if (this.previousPageTitleStack.length > 0) {
            document.title = this.previousPageTitleStack.pop() ?? "";
        }
    }

    setWarningThresholdFlag(setting: boolean) {
        if (this.isWithinWarningThreshold != setting) {
            this.isWithinWarningThreshold = setting;
        }
    }

    expireSession() {
        clearInterval(this.timerId);
        this.isExpired = true;
        this.setWarningThresholdFlag(false);
        this.restorePreviousPageTitle();

        if (this.isImpersonating) {
            router.push({ name: "StopImpersonating" });
        } else {
            router.push({ name: "Logout" });
        }
    }
}
