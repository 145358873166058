


























import { Component, Mixins } from "vue-property-decorator";
import { BRow, BCol } from "bootstrap-vue";
import _ from "lodash";
import { mapGetters } from "vuex";

import FluidContainer from "../components/FluidContainer.vue";
import WrapperPortfolioSummaryBanner from "../components/WrapperPortfolioSummaryBanner.vue";
import AssetAllocationPanel from "../components/chart/AssetAllocationPanel.vue";
import WrapperChartTable from "../components/chart/WrapperChartTable.vue";
import Skeleton from "../components/Skeleton.vue";
import { Namespaces } from "../store/namespaces";
import { LOAD_WRAPPER, LOAD_WRAPPER_DETAILS_CONTENT } from "../store/action-types";
import { Wrapper } from "../typings/wrapper";
import { WrapperPageContentHeadings, WrapperTableHeadings } from "../typings/wrapper-page";
import { AssetAllocation } from "../typings/asset-allocation";
import { ChartTableDataPoint } from "../typings/chart-table";
import { GET_WRAPPER_BY_ID, GET_WRAPPER_DETAILS_CONTENT, GET_WRAPPER_TABLE_HEADINGS } from "../store/getters";
import { AssetAllocationApiResult } from "../typings/api-service";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";
import AuthMixin from "../mixins/AuthMixin";
import NumberMixin from "../mixins/NumberMixin";
import RouteMixin from "../mixins/RouteMixin";
import ReactivePageTitle from "@/components/ReactivePageTitle.vue";
import { AssetAllocationDonutData } from "@/typings/asset-allocation-donut-data";
import { convertToCashInFlightDonut } from "@/helpers/donut-chart-data-helper";

@Component({
    components: {
        FluidContainer,
        BRow,
        BCol,
        Skeleton,
        WrapperPortfolioSummaryBanner,
        AssetAllocationPanel,
        WrapperChartTable,
        ReactivePageTitle,
    },
    computed: mapGetters({
        getWrapperById: `${Namespaces.Wrapper}/${GET_WRAPPER_BY_ID}`,
        getTableHeaders: `${Namespaces.WrapperDetails}/${GET_WRAPPER_TABLE_HEADINGS}`,
        getWrapperDetailsContent: `${Namespaces.WrapperDetails}/${GET_WRAPPER_DETAILS_CONTENT}`,
    }),
})
export default class WrapperPage extends Mixins(ErrorHandlerMixin, AuthMixin, NumberMixin, RouteMixin) {
    private getWrapperById!: Function;
    private getTableHeaders!: WrapperTableHeadings;
    private getWrapperDetailsContent!: WrapperPageContentHeadings;
    private assetAllocations: Array<AssetAllocation> = [];

    public get wrapper(): Wrapper | null {
        return this.getWrapperById(this.idFromRoute);
    }

    public get showSkeleton(): boolean {
        return _.isEmpty(this.wrapper);
    }

    public get wrapperStatementButton(): string {
        return this.getWrapperDetailsContent.wrapperStatementButton;
    }

    public get chartData(): Array<AssetAllocationDonutData> {
        const chartDataArray = convertToCashInFlightDonut(this.assetAllocations);
        return chartDataArray;
    }

    public get tableData(): Array<ChartTableDataPoint> {
        const chartTableData: Array<ChartTableDataPoint> = [];

        this.assetAllocations.forEach((assetAllocation: AssetAllocation) => {
            chartTableData.push({
                sector: assetAllocation.sector,
                assetName: assetAllocation.name,
                value: this.numberToCommaSeparatedString(assetAllocation.value.toFixed(2)),
                percentage: assetAllocation.weighting,
            });
        });

        return chartTableData;
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.WrapperDetails}/${LOAD_WRAPPER_DETAILS_CONTENT}`)
            .catch((error) => this.handleError(error.response?.status));
        this.$store
            .dispatch(`${Namespaces.Wrapper}/${LOAD_WRAPPER}`, { wrapperId: this.idFromRoute })
            .catch((error) => this.handleError(error.response?.status));
    }

    public mounted(): void {
        this.getAuthenticatedUser().then(() => {
            this.$api
                .getWrapperAssetAllocations(this.idFromRoute as number)
                .then((result: AssetAllocationApiResult) => {
                    this.assetAllocations = result.assetAllocation;
                })
                .catch((error) => this.handleError(error.response?.status));
        });
    }
}
