import { Commit } from "vuex";
import PortfolioSummaryBannerContentfulService from "@/services/contentful/PortfolioSummaryBannerContentfulService";

import { LOAD_PORTFOLIO_SUMMARY_BANNER } from "../action-types";
import { SAVE_PORTFOLIO_SUMMARY_BANNER_CONTENT, SET_PORTFOLIO_SUMMARY_BANNER_CONTENT_LOADED } from "../mutation-types";

import { GET_PORTFOLIO_SUMMARY_BANNER_LABELS } from "../getters";
import { PortfolioSummaryBannerModuleState } from "../typings/portfolio-summary-banner-module-state";
import { PortfolioSummaryBannerLabelling } from "@/typings/portfolio-summary-banner-module";

const contentful = new PortfolioSummaryBannerContentfulService();

const moduleState = (): PortfolioSummaryBannerModuleState => ({
    labelling: {
        portfolioSummaryAsAtDateLabel: "",
        portfolioSummaryValueLabel: ""
    },
    isLoaded: false
});
const getters = {
    [GET_PORTFOLIO_SUMMARY_BANNER_LABELS]: (state: PortfolioSummaryBannerModuleState) => {
        return state.labelling;
    }
};

const mutations = {
    [SAVE_PORTFOLIO_SUMMARY_BANNER_CONTENT](state: PortfolioSummaryBannerModuleState, labelling: PortfolioSummaryBannerLabelling) {
        state.labelling = labelling;
    },
    [SET_PORTFOLIO_SUMMARY_BANNER_CONTENT_LOADED](state: PortfolioSummaryBannerModuleState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    async [LOAD_PORTFOLIO_SUMMARY_BANNER]({ commit, state }: { commit: Commit; state: PortfolioSummaryBannerModuleState }) {
        if (!state.isLoaded) {
            contentful.getPortfolioSummaryBannerContent()
                .then((result: PortfolioSummaryBannerLabelling) => {
                    commit(SAVE_PORTFOLIO_SUMMARY_BANNER_CONTENT, result);
                    commit(SET_PORTFOLIO_SUMMARY_BANNER_CONTENT_LOADED, true);
                });
        }
    }
};

export const PortfolioSummaryBannerModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state: moduleState
};
