





























































import { Component, Mixins, Watch } from "vue-property-decorator";
import AuthMixin from "../mixins/AuthMixin";

import FluidContainer from "../components/FluidContainer.vue";
import WrapperPortfolioSummaryBanner from "../components/WrapperPortfolioSummaryBanner.vue";
import Button from "../components/Button.vue";
import { BRow, BCol, BTable } from "bootstrap-vue";
import ReportingPeriod from "../components/valuation/ReportingPeriod.vue";
import Skeleton from "../components/Skeleton.vue";
import ValuationTable from "../components/valuation/ValuationTable.vue";
import ValuationsPanel from "../components/chart/ValuationsPanel.vue";

import { Namespaces } from "../store/namespaces";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";
import RouteMixin from "../mixins/RouteMixin";
import { LOAD_WRAPPER, LOAD_WRAPPER_ASSET_ALLOCATIONS, LOAD_WRAPPER_VALUATION_CONTENT } from "../store/action-types";
import { mapGetters } from "vuex";
import {
    GET_WRAPPER_BY_ID,
    GET_WRAPPER_ASSET_ALLOCATIONS_BY_ID,
    GET_WRAPPER_VALUATION_CHART_CONTENT,
    GET_WRAPPER_VALUATION_PAGE_HEADINGS,
    GET_WRAPPER_VALUATION_TABLE_CONTENT,
    GET_WRAPPER_VALUATION_BUTTONS,
} from "../store/getters";
import { Wrapper } from "../typings/wrapper";
import { ValuationDetails } from "../typings/valuation-details";
import { WrapperValuationPageButtons, WrapperValuationPageTableHeadings } from "../typings/wrapper-valuation";
import NumberMixin from "../mixins/NumberMixin";
import _ from "lodash";
import { Interval } from "../typings/api-service";
import { AssetAllocation } from "../typings/asset-allocation";
import moment from "moment";
import { TableField } from "../typings/table";
import ReactivePageTitle from "@/components/ReactivePageTitle.vue";

@Component({
    components: {
        FluidContainer,
        Button,
        BRow,
        BCol,
        BTable,
        WrapperPortfolioSummaryBanner,
        ReportingPeriod,
        Skeleton,
        ValuationTable,
        ValuationsPanel,
        ReactivePageTitle,
    },
    computed: mapGetters({
        getWrapperById: `${Namespaces.Wrapper}/${GET_WRAPPER_BY_ID}`,
        getWrapperAssetAllocationsById: `${Namespaces.Wrapper}/${GET_WRAPPER_ASSET_ALLOCATIONS_BY_ID}`,
        getWrapperValuationPageContent: `${Namespaces.WrapperValuation}/${GET_WRAPPER_VALUATION_PAGE_HEADINGS}`,
        getWrapperValuationChartContent: `${Namespaces.WrapperValuation}/${GET_WRAPPER_VALUATION_CHART_CONTENT}`,
        getWrapperValuationTableContent: `${Namespaces.WrapperValuation}/${GET_WRAPPER_VALUATION_TABLE_CONTENT}`,
        getWrapperValuationButtons: `${Namespaces.WrapperValuation}/${GET_WRAPPER_VALUATION_BUTTONS}`,
    }),
})
export default class WrapperValuationPage extends Mixins(AuthMixin, ErrorHandlerMixin, RouteMixin, NumberMixin) {
    private getWrapperById!: Function;
    private getWrapperAssetAllocationsById!: Function;
    private getWrapperValuationTableContent!: WrapperValuationPageTableHeadings;
    private getWrapperValuationButtons!: WrapperValuationPageButtons;

    public selectedInterval = Interval.SixMonths;
    private valuationDetails: ValuationDetails = {} as ValuationDetails;
    private apiDataLoading = false;

    @Watch("selectedInterval")
    private selectedIntervalChanged(): void {
        this.getValuationsDataFromApi();
    }

    public get wrapper(): Wrapper | null {
        return this.getWrapperById(this.idFromRoute);
    }

    public get showSkeleton(): boolean {
        return _.isEmpty(this.valuationDetails);
    }

    public get isDataLoading(): boolean {
        return this.apiDataLoading;
    }

    public get tableItems(): Array<AssetAllocation> {
        if (this.getWrapperAssetAllocationsById(this.wrapper?.id)) {
            return this.getWrapperAssetAllocationsById(this.wrapper?.id).map((value: AssetAllocation) => ({
                sector: value.sector,
                name: value.name,
                units: this.numberToCommaSeparatedString(value.units?.toFixed(6) ?? 0),
                price: this.numberToCommaSeparatedString(value.price.toFixed(6)),
                priceDate: moment(value.priceDate).format("DD/MM/YYYY"),
                defaultInvestmentStrategy: value.defaultInvestmentStrategy?.toFixed(2),
                weighting: value.weighting.toFixed(2),
                value: this.numberToCommaSeparatedString(value.value.toFixed(2)),
            }));
        }

        return [];
    }

    public get tableFields(): Array<TableField> {
        return [
            {
                key: "sector",
                label: this.getWrapperValuationTableContent.sectorTableHeading,
            },
            {
                key: "name",
                label: this.getWrapperValuationTableContent.investmentNameTableHeading,
            },
            {
                key: "units",
                label: this.getWrapperValuationTableContent.unitsTableHeading,
                tdClass: "text-right",
                thClass: "text-right",
            },
            {
                key: "price",
                label: this.getWrapperValuationTableContent.priceTableHeading,
                tdClass: "text-right",
                thClass: "text-right",
            },
            {
                key: "priceDate",
                label: this.getWrapperValuationTableContent.priceDateTableHeading,
            },
            {
                key: "defaultInvestmentStrategy",
                label: this.getWrapperValuationTableContent.defaultInvestmentStrategyTableHeading,
                tdClass: "text-right",
                thClass: "text-right",
            },
            {
                key: "weighting",
                label: this.getWrapperValuationTableContent.weightingTableHeading,
                tdClass: "text-right",
                thClass: "text-right",
            },
            {
                key: "value",
                label: this.getWrapperValuationTableContent.valueTableHeading,
                tdClass: "text-right",
                thClass: "text-right",
            },
        ];
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.Wrapper}/${LOAD_WRAPPER}`, { wrapperId: this.idFromRoute })
            .catch((error) => this.handleError(error.response?.status));
        this.$store
            .dispatch(`${Namespaces.Wrapper}/${LOAD_WRAPPER_ASSET_ALLOCATIONS}`, { wrapperId: this.idFromRoute })
            .catch((error) => this.handleError(error.response?.status));
        this.$store
            .dispatch(`${Namespaces.WrapperValuation}/${LOAD_WRAPPER_VALUATION_CONTENT}`)
            .catch((error) => this.handleError(error.response?.status));
    }

    public mounted(): void {
        this.getAuthenticatedUser().then(() => {
            this.getValuationsDataFromApi();
        });
    }

    public downloadValuationCsv(wrapperId: number) {
        this.$api.getWrapperAssetAllocationAsCsv(wrapperId).catch((error) => this.handleError(error.response?.status));
    }

    private getValuationsDataFromApi(): void {
        this.apiDataLoading = true;
        this.$api
            .getWrapperValuation(this.idFromRoute as number, this.selectedInterval)
            .then((result: ValuationDetails) => {
                this.valuationDetails = result;
                this.apiDataLoading = false;
            })
            .catch((error) => this.handleError(error.response?.status));
    }
}
