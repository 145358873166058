




import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {}
})
export default class Overlay extends Vue {
    @Prop({ default: false })
    displayOverlay!: boolean;
}
