import OffPlatformAssetsContentfulService from "@/services/contentful/OffPlatformAssetsContentfulService";
import {
    OffPlatformAssetsContentHeadings,
    OffPlatformAssetsPageHeadings,
    OffPlatformAssetsTableHeadings
} from "@/typings/off-platform-assets-page";
import { Commit } from "vuex";
import { LOAD_OFF_PLATFORM_ASSETS_CONTENT } from "../action-types";
import { GET_OFF_PLATFORM_ASSETS_CONTENT, GET_OFF_PLATFORM_ASSETS_TABLE_HEADINGS } from "../getters";
import { SAVE_OFF_PLATFORM_ASSETS_CONTENT, SET_OFF_PLATFORM_ASSETS_LOADED } from "../mutation-types";
import { OffPlatformAssetsState } from "../typings/off-platform-assets";

const contentful = new OffPlatformAssetsContentfulService();

const state = (): OffPlatformAssetsState => ({
    offPlatformAssets: {
        offPlatformAssetsTitle: "",
        totalValueHeading: "",
        assetDetailsHeading: "",
        assetNameTableHeading: "",
        valueTableHeading: ""
    },
    isLoaded: false
});

const getters = {
    [GET_OFF_PLATFORM_ASSETS_CONTENT]: (state: OffPlatformAssetsState): OffPlatformAssetsContentHeadings => {
        return {
            offPlatformAssetsTitle: state.offPlatformAssets.offPlatformAssetsTitle,
            totalValueHeading: state.offPlatformAssets.totalValueHeading,
            assetDetailsHeading: state.offPlatformAssets.assetDetailsHeading
        };
    },
    [GET_OFF_PLATFORM_ASSETS_TABLE_HEADINGS]: (state: OffPlatformAssetsState): OffPlatformAssetsTableHeadings => {
        return {
            assetNameTableHeading: state.offPlatformAssets.assetNameTableHeading,
            valueTableHeading: state.offPlatformAssets.valueTableHeading
        };
    }
};

const mutations = {
    [SAVE_OFF_PLATFORM_ASSETS_CONTENT](state: OffPlatformAssetsState, model: OffPlatformAssetsPageHeadings) {
        state.offPlatformAssets = model;
    },
    [SET_OFF_PLATFORM_ASSETS_LOADED](state: OffPlatformAssetsState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    [LOAD_OFF_PLATFORM_ASSETS_CONTENT]({ commit, state }: { commit: Commit; state: OffPlatformAssetsState }) {
        if (!state.isLoaded) {
            contentful.getOffPlatformAssetsPageContent().then((result: OffPlatformAssetsPageHeadings) => {
                commit(SAVE_OFF_PLATFORM_ASSETS_CONTENT, result);
                commit(SET_OFF_PLATFORM_ASSETS_LOADED, true);
            });
        }
    }
};

export const OffPlatformAssetsModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
