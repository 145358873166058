export const SAVE_COMPANY_INFORMATION = "SAVE_COMPANY_INFORMATION";
export const SET_COMPANY_INFORMATION_LOADED = "SET_COMPANY_INFORMATION_LOADED";

export const SAVE_COMPANY_WHITELABELLING = "SAVE_COMPANY_WHITELABELLING";
export const SET_COMPANY_WHITELABELLING_BRANCHID = "SET_COMPANY_WHITELABELLING_BRANCHID";
export const SET_COMPANY_WHITELABELLING_DEFAULT = "SET_COMPANY_WHITELABELLING_DEFAULT";
export const SET_COMPANY_WHITELABELLING_LOADED = "SET_COMPANY_WHITELABELLING_LOADED";

export const SAVE_GLOBAL_CONTENT = "SAVE_GLOBAL_CONTENT";
export const SET_GLOBAL_CONTENT_LOADED = "SET_GLOBAL_CONTENT_LOADED";

export const SAVE_NAVIGATION_PAGES = "SAVE_NAVIGATION_PAGES";
export const SET_NAVIGATION_PAGES_LOADED = "SET_NAVIGATION_PAGES_LOADED";

export const SAVE_PORTFOLIO_HEADINGS = "SAVE_PORTFOLIO_HEADINGS";
export const SAVE_PORTFOLIO_VALUE = "SAVE_PORTFOLIO_VALUE";
export const SAVE_WRAPPERS = "SAVE_WRAPPERS";
export const SAVE_RELATED_INVESTORS = "SAVE_RELATED_INVESTORS";
export const SAVE_ASSET_ALLOCATION = "SAVE_ASSET_ALLOCATION";
export const SAVE_PORTFOLIO_CHART_COLOURS = "SET_CHART_COLOURS";
export const SET_PORTFOLIO_LOADED_FLAG = "SET_PORTFOLIO_LOADED_FLAG";
export const SET_PORTFOLIO_HEADINGS_LOADED_FLAG = "SET_PORTFOLIO_HEADINGS_LOADED_FLAG";
export const SET_PORTFOLIO_CHART_LOADED_FLAG = "SET_PORTFOLIO_CHART_LOADED_FLAG";

export const SAVE_WRAPPER_DETAILS_CONTENT = "SAVE_WRAPPER_DETAILS_CONTENT";
export const SET_WRAPPER_DETAILS_LOADED = "SET_WRAPPER_DETAILS_LOADED";

export const SAVE_INFORMATION_PAGE_CONTENT = "SAVE_INFORMATION_PAGE_CONTENT";
export const SET_INFORMATION_PAGE_LOADED = "SET_INFORMATION_PAGE_LOADED";

export const SAVE_OFF_PLATFORM_ASSETS_CONTENT = "SAVE_OFF_PLATFORM_ASSETS_CONTENT";
export const SET_OFF_PLATFORM_ASSETS_LOADED = "SET_OFF_PLATFORM_ASSETS_LOADED";

export const SAVE_WRAPPER_STATEMENT_CONTENT = "SAVE_WRAPPER_STATEMENT_CONTENT";
export const SAVE_WRAPPER_STATEMENT_RADIO_HEADINGS = "SAVE_WRAPPER_STATEMENT_RADIO_HEADINGS";
export const SAVE_WRAPPER_STATEMENT_TABLE_HEADINGS = "SAVE_WRAPPER_STATEMENT_TABLE_HEADINGS";
export const SET_WRAPPER_STATEMENT_LOADED = "SET_WRAPPER_STATEMENT_LOADED";

export const SAVE_WRAPPER = "SAVE_WRAPPER";
export const SAVE_WRAPPER_ASSET_ALLOCATIONS = "SAVE_WRAPPER_ASSET_ALLOCATIONS";

export const SAVE_MONEY_IN_OUT_CONTENT = "SAVE_MONEY_IN_OUT_CONTENT";
export const SET_MONEY_IN_OUT_LOADED = "SET_MONEY_IN_OUT_LOADED";

export const SAVE_PORTFOLIO_VALUATION_PAGE_HEADINGS = "SAVE_PORTFOLIO_VALUATION_PAGE_HEADINGS";
export const SET_PORTFOLIO_VALUATION_HEADINGS_LOADED_FLAG = "SET_PORTFOLIO_VALUATION_HEADINGS_LOADED_FLAG";

export const SAVE_REPORTING_PERIOD_RADIO_CONTENT = "SAVE_REPORTING_PERIOD_RADIO_CONTENT";
export const SAVE_REPORTING_PERIOD_TABLE_CONTENT = "SAVE_REPORTING_PERIOD_TABLE_CONTENT";
export const SET_REPORTING_PERIOD_LOADED_FLAG = "SET_REPORTING_PERIOD_LOADED_FLAG";

export const SAVE_WRAPPER_VALUATION_PAGE_CONTENT = "SAVE_WRAPPER_VALUATION_PAGE_CONTENT";
export const SET_WRAPPER_VALUATION_PAGE_CONTENT_LOADED = "SET_WRAPPER_VALUATION_PAGE_CONTENT_LOADED";

export const SAVE_DOCUMENTS_CONTENT = "SAVE_DOCUMENTS_CONTENT";
export const SET_DOCUMENTS_LOADED = "SET_DOCUMENTS_LOADED";

export const SAVE_WRAPPER_TRANSACTIONS_PAGE_HEADINGS_CONTENT = "SAVE_WRAPPER_TRANSACTIONS_CONTENT";
export const SAVE_WRAPPER_TRANSACTIONS_TABLE_HEADINGS = "SAVE_WRAPPER_TRANSACTIONS_TABLE_HEADINGS";
export const SET_WRAPPER_TRANSACTIONS_LOADED = "SET_WRAPPER_TRANSACTIONS_LOADED";

export const SAVE_NO_BALANCE_PAGE_CONTENT = "SAVE_NO_BALANCE_PAGE_CONTENT";
export const SET_NO_BALANCE_PAGE_LOADED_FLAG = "SET_NO_BALANCE_PAGE_LOADED_FLAG";

export const SAVE_NOT_IMPERSONATING_CONTENT = "SAVE_NOT_IMPERSONATING_CONTENT";
export const SET_NOT_IMPERSONATING_LOADED = "SET_NOT_IMPERSONATING_LOADED";

export const SAVE_INVESTOR_DETAILS = "SAVE_INVESTOR_DETAILS";
export const SET_INVESTOR_DETAILS_LOADED = "SET_INVESTOR_DETAILS_LOADED";

export const SAVE_ERROR_PAGE_CONTENT = "SAVE_ERROR_PAGE_CONTENT";
export const SET_ERROR_PAGE_LOADED = "SET_ERROR_PAGE_LOADED";

export const SAVE_WELCOME_MESSAGE_CONTENT = "SAVE_WELCOME_MESSAGE_CONTENT";
export const SET_WELCOME_MESSAGE_LOADED = "SET_WELCOME_MESSAGE_LOADED";

export const SAVE_SESSION_EXPIRY_CONTENT = "SAVE_SESSION_EXPIRY_CONTENT";
export const SET_SESSION_EXPIRY_LOADED = "SET_SESSION_EXPIRY_LOADED";

export const SAVE_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT = "SAVE_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT";
export const SET_ACCOUNT_CREATION_IN_PROGRESS_PAGE_LOADED_FLAG = "SET_ACCOUNT_CREATION_IN_PROGRESS_PAGE_LOADED_FLAG";

export const SAVE_BROWSER_NOT_SUPPORTED_PAGE_CONTENT = "SAVE_BROWSER_NOT_SUPPORTED_PAGE_CONTENT";
export const SET_BROWSER_NOT_SUPPORTED_PAGE_CONTENT_LOADED_FLAG = "SET_BROWSER_NOT_SUPPORTED_PAGE_CONTENT_LOADED_FLAG";

export const SAVE_PORTFOLIO_SUMMARY_BANNER_CONTENT = "SAVE_PORTFOLIO_SUMMARY_BANNER_CONTENT"
export const SET_PORTFOLIO_SUMMARY_BANNER_CONTENT_LOADED = "SET_PORTFOLIO_SUMMARY_BANNER_CONTENT_LOADED"

export const SET_LABELLING = "SET_LABELLING";
export const SET_STATE_LOADED = "SET_STATE_LOADED";