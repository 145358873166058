import { Entry } from "contentful";
import { ContentfulKeys } from "./contentful-keys";
import ContentfulService from "./ContentfulService";

import { ContentfulNotImpersonatingPage } from "@/typings/contentful";
import { NotImpersonatingContent } from "@/typings/not-impersonating-page";

export default class NotImpersonatingContentfulService extends ContentfulService {
    public getNotImpersonatingPageContent(): Promise<NotImpersonatingContent> {
        return this.getContentfulEntry<ContentfulNotImpersonatingPage, NotImpersonatingContent>(
            ContentfulKeys.NotImpersonating,
            (result: Entry<ContentfulNotImpersonatingPage>) => {
                return {
                    pageHeading: result.fields.notImpersonatingTitle,
                    noInvestorContextMessage: result.fields.noInvestorContextMessage,
                    notLoggedInMessage: result.fields.notLoggedInMessage
                };
            }
        );
    }
}
