









import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {}
})
export default class Skeleton extends Vue {
    @Prop({ default: 50 })
    height!: number;

    @Prop({ default: true })
    show!: boolean;

    @Prop({ default: 1 })
    rows!: number;

    get cssVariables() {
        return {
            "--skeleton-height": `${this.height}px`
        };
    }
}
