















import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "../Button.vue";
import PanelDetails from "../panel/PanelDetails.vue";
import PanelActions from "../panel/PanelActions.vue";
import PanelIcon from "../panel/PanelIcon.vue";
import { numberToCommaSeparatedString } from "../../helpers/number-helper";
import { Wrapper } from "../../typings/wrapper";

@Component({
    components: {
        Button,
        PanelDetails,
        PanelIcon,
        PanelActions
    }
})
export default class WrapperSummary extends Vue {
    @Prop()
    public type?: string;

    @Prop()
    public wrapper!: Wrapper;

    @Prop({ default: true })
    public displayCurrency!: boolean;

    public get wrapperValue(): string {
            return numberToCommaSeparatedString(this.wrapper.value.toFixed(2));
    }

    public get wrapperHref(): string | Record<string, any> {
        if (this.isWrapperOffPlatformAssets) {
            return "/offplatformassets";
        }

        return {
            name: "Wrapper",
            params: {
                id: this.wrapper.id
            }
        };
    }

    private get isWrapperOffPlatformAssets(): boolean {
        return this.wrapper.shortCode === "NOVOPA";
    }
}
