import Vue from "vue";
import { numberToCommaSeparatedString } from "@/helpers/number-helper";
import { LOAD_PORTFOLIO_CHART } from "@/store/action-types";
import { GET_CHART_COLOURS } from "@/store/getters";
import { Namespaces } from "@/store/namespaces";
import { ChartBuilderOptions } from "@/typings/chart-builder-service";
import { PointOptionsObject } from "highcharts";
import store from "../store/";
import hexRgb from "hex-rgb";
import Color from "color";

interface PieDataPoint {
    value: number;
    name: string;
}

export default class ChartBuilderService {
    public async getDonutChartOptions(data: Array<PointOptionsObject>): Promise<Highcharts.Options> {
        await store.dispatch(`${Namespaces.Portfolio}/${LOAD_PORTFOLIO_CHART}`);

        return new Promise(resolve => {
            let chartOptionsData = data;

            if (this.storeContainsContentfulColourMap()) {
                chartOptionsData = this.getDataPointsWithChartColours(data as Array<PointOptionsObject & {key: string}>);
            }

            const chartOptions: Highcharts.Options = {
                title: {
                    text: ""
                },
                credits: {
                    enabled: false
                },
                chart: {
                    plotBorderWidth: 0,
                    plotShadow: false
                },
                tooltip: {
                    formatter: function () {
                        const pointValue = ((this.point as unknown) as PieDataPoint).value;
                        const nameValue = ((this.point as unknown) as PieDataPoint).name;
                        const fixedValue = Number(Math.round(parseFloat(pointValue + "e2")) + "e-2").toFixed(2);

                        return `<b> ${nameValue} </b><br><b>£${numberToCommaSeparatedString(fixedValue)} </b>`;
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: false
                        },
                        center: ["50%", "50%"],
                        size: "100%"
                    }
                },
                series: [
                    {
                        type: "pie",
                        name: "Asset Allocation",
                        innerSize: "40%",
                        data: chartOptionsData
                    }
                ]
            };

            resolve(chartOptions);
        });
    }

    public getAreaChartOptions(
        data: [string | number, number | null][],
        options?: ChartBuilderOptions
    ): Highcharts.Options {
        let fillColor

        if (Vue.prototype.$isWealthTime) {
            fillColor = Color(this.getChartColour(options)).lighten(0.3).rgb().string()
        } else {
            fillColor = Color(this.getChartColour(options)).lighten(0.5).rgb().string()
        }
        
        const chartOptions: Highcharts.Options = {
            chart: {
                zoomType: "x",
                resetZoomButton: {
                    position: {
                        x: -0,
                        y: -55
                    }
                },
                style: {
                    ...Vue.prototype.$isWealthTime && 
                    {fontFamily: "Alliance, Arial, sans-serif"}    
                }        
            },
            credits: {
                enabled: false
            },
            title: {
                text: options ? options.title : "Value"
            },
            subtitle: {
                text:
                    document.ontouchstart === undefined
                        ? "Click and drag in the plot area to zoom in"
                        : "Pinch the chart to zoom in"
            },
            xAxis: {
                type: "datetime"
            },
            yAxis: {
                title: {
                    text: ""
                },
                labels: {
                    formatter: function () {
                        return `£${numberToCommaSeparatedString(this.value.toFixed(2))}`;
                    }
                }
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                area: {
                    fillColor: this.getRgbaFromHex(this.getChartColour(options)),
                    fillOpacity: 0,
                    marker: {
                        radius: 2,
                        lineColor: "#000",
                        fillColor: fillColor
                    },
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    threshold: null
                }
            },
            series: [
                {
                    type: "area",
                    name: options ? options.title : "Value",
                    data: data,
                    tooltip: {
                        pointFormatter: function () {
                            return `£${numberToCommaSeparatedString(this.y!.toFixed(2))}`;
                        }
                    }
                }
            ]
        };

        return chartOptions;
    }

    private storeContainsContentfulColourMap(): boolean {
        const contentfulColoursMap: Map<string, string> = store.getters[`${Namespaces.Portfolio}/${GET_CHART_COLOURS}`];

        return contentfulColoursMap && contentfulColoursMap.size > 0;
    }

    private getDataPointsWithChartColours(data: Array<PointOptionsObject & {key: string}>): Array<PointOptionsObject> {
        const dataWithColours = [...data];
        const contentfulColoursMap = store.getters[`${Namespaces.Portfolio}/${GET_CHART_COLOURS}`];

        for (let i = 0; i < data.length; i++) {
            dataWithColours[i].color = contentfulColoursMap.get(data[i]?.key as string);
        }

        return dataWithColours;
    }

    private getChartColour(options?: ChartBuilderOptions) {
        return options?.chartColour ? options.chartColour : "#BFD9DA";
    }

    private getRgbaFromHex(hexCode: string) {
        const rgbaConversion = hexRgb(hexCode);
        const brightness = Vue.prototype.$isWealthTime ? '0.7' : '0.5'
        return `rgba(${rgbaConversion.red}, ${rgbaConversion.green}, ${rgbaConversion.blue}, ${brightness})`;
    }
}
