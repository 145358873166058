
















import { Component, Mixins } from "vue-property-decorator";
import { LOAD_GLOBAL_CONTENT } from "../store/action-types";
import { GlobalContent } from "../typings/global-content";
import { GET_GLOBAL_CONTENT, GET_WHITELABELLING_CONTENT } from "../store/getters";
import { Namespaces } from "@/store/namespaces";
import ErrorHandlerMixin from "@/mixins/ErrorHandlerMixin";
import { WhiteLabellingDetails } from "@/typings/whitelabelling-details";
import { mapGetters } from "vuex";
import Skeleton from "../components/Skeleton.vue";
import _ from "lodash";

@Component({
    components: { Skeleton },
    computed: mapGetters({
        getGlobalContent: `${Namespaces.GlobalContent}/${GET_GLOBAL_CONTENT}`,
        getWhiteLabellingDetails: `${Namespaces.CompanyInformation}/${GET_WHITELABELLING_CONTENT}`
    })
})
export default class Strapline extends Mixins(ErrorHandlerMixin) {
    private getGlobalContent!: GlobalContent;
    private getWhiteLabellingDetails!: WhiteLabellingDetails;

    public get showSkeleton(): boolean {
        return (
            (_.isEmpty(this.getWhiteLabellingDetails) || (this.getWhiteLabellingDetails?.branchId ?? 0) == 0) &&
            _.isEmpty(this.getGlobalContent.strapline)
        );
    }

    public get investorZoneHeadingClass(): string {
        if (this.$isWealthTime)
            return this.getWhiteLabellingDetails.enabled ? "mb-0" : "m-0";
        else
            return this.getWhiteLabellingDetails.enabled ? "mb-0" : "mt-0";
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.GlobalContent}/${LOAD_GLOBAL_CONTENT}`)
            .catch(error => this.handleError(error.response?.status));
    }
}
