import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { User } from "oidc-client";

import HomePage from "../views/HomePage.vue";
import LoginPage from "../views/LoginPage.vue";
import InfoPage from "../views/InfoPage.vue";
import WrapperPage from "../views/WrapperPage.vue";
import ErrorPage from "../views/ErrorPage.vue";
import InternetExplorerPage from "../views/InternetExplorerPage.vue";
import OffPlatformAssetsPage from "../views/OffPlatformAssetsPage.vue";
import WrapperStatementPage from "../views/WrapperStatementPage.vue";
import MoneyInOutPage from "../views/MoneyInOutPage.vue";
import PortfolioValuationPage from "../views/PortfolioValuationPage.vue";
import WrapperValuationPage from "../views/WrapperValuationPage.vue";
import DocumentsPage from "../views/DocumentsPage.vue";
import WrapperTransactionPage from "../views/WrapperTransactionPage.vue";
import NoBalancePage from "../views/NoBalancePage.vue";
import { AssetAllocationApiResult, WrappersApiResult, InvestorAccountStatusResult } from "@/typings/api-service";
import _ from "lodash";
import { AxiosError } from "axios";
import NotImpersonatingPage from "../views/NotImpersonatingPage.vue";
import StopImpersonatingPage from "../views/StopImpersonatingPage.vue";
import AccountCreationInProgressPage from "../views/AccountCreationInProgressPage.vue";

import { Routes } from "../constants/routes";
import { InvestorAccountStatus } from "../constants/investor-account-status";
import { environment } from '../environment'

Vue.use(VueRouter);

// Caching variables to support the no balance page guard - ensure we don't hit the API in every route change
let totalValue: number;
let wrappersLength: number;
let relatedWrappersLength = 0;
let isZeroBalanceCalculated = false;

let investorAccountStatus: InvestorAccountStatusResult;

function doesAuthenticatedUserHaveZeroBalance(): Promise<boolean> {
    return new Promise((resolve, reject) => {
        if (!isZeroBalanceCalculated) {
            // The API is used here rather than the store due to the fact we cannot guarantee the API will be ready before the store
            // in the context of the router
            Promise.all([Vue.prototype.$api.getPortfolioAssetAllocations(), Vue.prototype.$api.getAllWrappers()])
                .then((result: [AssetAllocationApiResult, WrappersApiResult]) => {
                    totalValue = _.sum(result[0].assetAllocation.map(asset => asset.value));
                    wrappersLength = result[1].investorWrappersModel.wrapperGroups.length;

                    const relatedInvestorsWrappersModels = result[1]?.relatedInvestorsWrappersModels;
                    if (relatedInvestorsWrappersModels){
                        for (const relatedGroup of result[1].relatedInvestorsWrappersModels?.[0].wrapperGroups) {
                            if (relatedGroup.wrappers.length > 0) {
                                relatedWrappersLength = 1;
                                break; // Break out of the loop when a non-empty wrappers array is found
                            }
                        }
                    }

                    isZeroBalanceCalculated = true;

                    resolve(
                        (totalValue === 0 || wrappersLength === 0) &&
                            (relatedWrappersLength === 0 || relatedWrappersLength === undefined)
                    );
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        } else {
            resolve(
                (totalValue === 0 || wrappersLength === 0) &&
                    (relatedWrappersLength === 0 || relatedWrappersLength === undefined)
            );
        }
    });
}

function accountCreationInProgress(): Promise<boolean> {
    return new Promise((resolve, reject) => {
        if (!investorAccountStatus) {        
            Vue.prototype.$api
                .getInvestorAccountStatus()
                .then(async (response: InvestorAccountStatusResult) => { 
                    investorAccountStatus = response;
                    resolve(investorAccountStatus.accountStatus == InvestorAccountStatus.pending);
                })
                .catch((error: any) => reject(error));
        }
        else {
            resolve(investorAccountStatus.accountStatus == InvestorAccountStatus.pending);
        }
    });
}

async function ensureAccountIsActive(next: NavigationGuardNext<Vue>) {
    if (await accountCreationInProgress()) {
        next({ name: Routes.AccountCreationInProgress.name });
    }
    else {
        next();
    }
}

const routes: Array<RouteConfig> = [
    {
        name: Routes.Home.name,
        path: Routes.Home.path,
        component: HomePage,
        beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext<Vue>) => {
            if (await accountCreationInProgress()) {
                next({ name: Routes.AccountCreationInProgress.name });
            }
            else {
                // Check if the authenticated user is an investor with a zero balance on their account. If so, they should be redirected
                const result = await doesAuthenticatedUserHaveZeroBalance().catch(error => next({ name: Routes.Error.name, params: {"statusCode": error.response?.status }}));
                if (result && to.name !== Routes.NoBalance.name) {
                    next({ name: Routes.NoBalance.name });
                } else {
                    next();
                }
            }
        }
    },
    {
        name: Routes.Login.name,
        path: Routes.Login.path,
        component: LoginPage
    },
    {
        name: Routes.AccountCreationInProgress.name,
        path: Routes.AccountCreationInProgress.path,
        component: AccountCreationInProgressPage
    },
    {
        name: Routes.Information.name,
        path: Routes.Information.path,
        component: InfoPage,
        beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext<Vue>) => ensureAccountIsActive(next)
    },
    {
        name: Routes.Wrapper.name,
        path: Routes.Wrapper.path,
        component: WrapperPage,
        beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext<Vue>) => ensureAccountIsActive(next)
    },
    {
        name: Routes.Error.name,
        path: Routes.Error.path,
        component: ErrorPage,
        props: (route) => ({ statusCode: Number(route.params.statusCode) }),
    },    
    {
        name: Routes.InternetExplorer.name,
        path: Routes.InternetExplorer.path,
        component: InternetExplorerPage
    },
    {
        name: Routes.OffPlatformAssets.name,
        path: Routes.OffPlatformAssets.path,
        component: OffPlatformAssetsPage,
        beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext<Vue>) => ensureAccountIsActive(next)
    },
    {
        name: Routes.WrapperStatement.name,
        path: Routes.WrapperStatement.path,
        component: WrapperStatementPage,
        beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext<Vue>) => ensureAccountIsActive(next)
    },
    {
        name: Routes.WrapperValuation.name,
        path: Routes.WrapperValuation.path,
        component: WrapperValuationPage,
        beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext<Vue>) => ensureAccountIsActive(next)
    },
    {
        name: Routes.WrapperTransaction.name,
        path: Routes.WrapperTransaction.path,
        component: WrapperTransactionPage,
        beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext<Vue>) => ensureAccountIsActive(next)
    },
    {
        name: Routes.MoneyInOut.name,
        path: Routes.MoneyInOut.path,
        component: MoneyInOutPage,
        beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext<Vue>) => ensureAccountIsActive(next)
    },
    {
        name: Routes.PortfolioValuation.name,
        path: Routes.PortfolioValuation.path,
        component: PortfolioValuationPage,
        beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext<Vue>) => ensureAccountIsActive(next)
    },
    {
        name: Routes.Documents.name,
        path: Routes.Documents.path,
        component: DocumentsPage,
        beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext<Vue>) => ensureAccountIsActive(next)
    },
    {
        name: Routes.NoBalance.name,
        path: Routes.NoBalance.path,
        component: NoBalancePage,
        beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext<Vue>) => ensureAccountIsActive(next)
    },
    {
        name: Routes.Logout.name,
        path: Routes.Logout.path,
    },
    {
        name: Routes.StopImpersonating.name,
        path: Routes.StopImpersonating.path,
        component: StopImpersonatingPage
    },
    {
        name: Routes.NotImpersonating.name,
        path: Routes.NotImpersonating.path,
        component: NotImpersonatingPage
    },
    {
        name: Routes.Stocktrade.name,
        path: Routes.Stocktrade.path,
        beforeEnter() {
            window.open(environment.stocktradeUrl, '_blank');
          }
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to: Route, _from: Route, next: NavigationGuardNext<Vue>) => {
    const endpointsThatAllowAnonymousAccess = [Routes.Login.name, Routes.NotImpersonating.name, Routes.StopImpersonating.name];
    
    if (to.name === Routes.Logout.name) {
        Vue.prototype.$auth.logout();
        next();
    }
    // We need to check if the route we are trying to go to is Login and allow execution if it is - if the auth has expired and we don't do this check the guard
    // will fall into an endless loop
    else if (!endpointsThatAllowAnonymousAccess.includes(to.name ?? '')) {
        Vue.prototype.$auth
            .getUser()
            .then((user: User | null) => {
                // User session expired
                if (!user || user.expired) {                        
                    next({ name: Routes.Login.name });
                } 
                // IE error page
                else if (to.name !== Routes.InternetExplorer.name && Vue.prototype.$browserDetect.isIE) {     
                    next({ name: Routes.InternetExplorer.name });
                }
                // Non investor?
                else if (to.name !== Routes.InternetExplorer.name && user.profile.user_type != "Investor" && !user.profile.investor_context) {
                    next({ name: Routes.NotImpersonating.name });
                } 
                // Continue
                else {
                    next();
                }
            })
            .catch(() => {
                // Something has gone wrong when trying to retrieve the user from the auth service, so we do not know whether they are authenticated
                next({ name: Routes.Login.name });
            });
    } else {
        next();
    }
});

export default router;
