import { Commit } from "vuex";
import PortfolioContentfulService from "@/services/contentful/PortfolioContentfulService";

import { LOAD_PORTFOLIO_VALUATION_HEADINGS } from "../action-types";
import {
    GET_PORTFOLIO_VALUATION_CHART_CONTENT,
    GET_PORTFOLIO_VALUATION_PAGE_HEADINGS,
    GET_PORTFOLIO_VALUATION_RADIO_HEADINGS
} from "../getters";
import {
    SAVE_PORTFOLIO_VALUATION_PAGE_HEADINGS,
    SET_PORTFOLIO_VALUATION_HEADINGS_LOADED_FLAG
} from "../mutation-types";

import {
    PortfolioValuationChartContent,
    PortfolioValuationPageContent,
    PortfolioValuationPageContentHeadings,
    PortfolioValuationRadioHeadings
} from "@/typings/portfolio-valuations";
import { ContentfulPortfolioValuationsPage } from "@/typings/contentful";
import { PortfolioValuationState } from "../typings/portfolio-valuation";

const contentful = new PortfolioContentfulService();

const state = (): PortfolioValuationState => ({
    headings: {
        portfolioValuationTitle: "",
        valuationPeriod: ""
    },
    radio: {
        lastSixMonthsRadioHeading: "",
        lastTwelveMonthsRadioHeading: ""
    },
    chart: {
        valuationChartTitle: "",
        valuationChartColour: "",
        valuationChartInformation: ""
    },
    isDataLoaded: false
});

const getters = {
    [GET_PORTFOLIO_VALUATION_PAGE_HEADINGS]: (
        state: PortfolioValuationState
    ): PortfolioValuationPageContentHeadings => {
        return state.headings;
    },
    [GET_PORTFOLIO_VALUATION_RADIO_HEADINGS]: (state: PortfolioValuationState): PortfolioValuationRadioHeadings => {
        return state.radio;
    },
    [GET_PORTFOLIO_VALUATION_CHART_CONTENT]: (state: PortfolioValuationState): PortfolioValuationChartContent => {
        return state.chart;
    }
};

const mutations = {
    [SAVE_PORTFOLIO_VALUATION_PAGE_HEADINGS](state: PortfolioValuationState, model: PortfolioValuationPageContent) {
        state.headings = {
            portfolioValuationTitle: model.portfolioValuationTitle,
            valuationPeriod: model.valuationPeriod
        };
        state.radio = {
            lastSixMonthsRadioHeading: model.lastSixMonthsRadioHeading,
            lastTwelveMonthsRadioHeading: model.lastTwelveMonthsRadioHeading
        };
        state.chart = {
            valuationChartTitle: model.valuationChartTitle,
            valuationChartColour: model.valuationChartColour,
            valuationChartInformation: model.valuationChartInformation
        };
    },
    [SET_PORTFOLIO_VALUATION_HEADINGS_LOADED_FLAG](state: PortfolioValuationState, isLoaded: boolean) {
        state.isDataLoaded = isLoaded;
    }
};

const actions = {
    async [LOAD_PORTFOLIO_VALUATION_HEADINGS]({ commit, state }: { commit: Commit; state: PortfolioValuationState }) {
        if (!state.isDataLoaded) {
            contentful.getPortfolioValuationsContent().then((result: ContentfulPortfolioValuationsPage) => {
                commit(SAVE_PORTFOLIO_VALUATION_PAGE_HEADINGS, result);
                commit(SET_PORTFOLIO_VALUATION_HEADINGS_LOADED_FLAG, true);
            });
        }
    }
};

export const PortfolioValuationModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
