













import { Prop, Mixins, Component } from "vue-property-decorator";

@Component({
    components: {},
})
export default class ReactivePageTitle extends Mixins() {
    @Prop()
    public pageTitle!: string;

    private get mobileClass(): string{
        if(this.$slots.default){
            return "mobile-header-with-content float-left text-left"
        }
        return "mobile-header float-left text-left"
    }
}
