import Vue from "vue";
import { InvestorDetailsResult } from "@/typings/api-service";
import { InvestorDetails } from "@/typings/investor-details";
import { AxiosError } from "axios";
import { Commit, Dispatch } from "vuex";
import { LOAD_COMPANY_INFORMATION, LOAD_INVESTOR_DETAILS } from "../action-types";
import { GET_INVESTOR_DETAILS } from "../getters";
import {
    SAVE_INVESTOR_DETAILS,
    SET_INVESTOR_DETAILS_LOADED
} from "../mutation-types";
import { InvestorDetailsState } from "../typings/investor-details";
import { Namespaces } from "../namespaces";

const state = (): InvestorDetailsState => ({
    details: {
        branchName: "",
        investorName: "",
        id: 0,
        hasStockTradeAccount: false
    },
    isDataLoaded: false
});

const getters = {
    [GET_INVESTOR_DETAILS]: (state: InvestorDetailsState): InvestorDetails => {
        return state.details;
    }
};

const mutations = {
    [SAVE_INVESTOR_DETAILS](state: InvestorDetailsState, model: InvestorDetailsResult) {
        state.details.branchName = model.branchName;
        state.details.id = model.branchId;
        state.details.investorName = model.investorName;
        state.details.hasStockTradeAccount = model.hasStocktradeAccount;
    },
    [SET_INVESTOR_DETAILS_LOADED](state: InvestorDetailsState, isLoaded: boolean) {
        state.isDataLoaded = isLoaded;
    }
};

const actions = {
    async [LOAD_INVESTOR_DETAILS]({ dispatch, commit, state }: { dispatch: Dispatch; commit: Commit; state: InvestorDetailsState }): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!state.isDataLoaded) {
                Vue.prototype.$api.getInvestorDetails().then((result: InvestorDetailsResult) => {
                    commit(SAVE_INVESTOR_DETAILS, result);
                    commit(SET_INVESTOR_DETAILS_LOADED, true);
                    dispatch(`${Namespaces.CompanyInformation}/${LOAD_COMPANY_INFORMATION}`, result.branchId, { root: true })
                    resolve()
                })
                    .catch((error: Error | AxiosError) => {
                        reject(error);
                    });
            }
        });
    }
};

export const InvestorDetailsModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
