import { AssetAllocation } from "@/typings/asset-allocation";
import { AssetAllocationDonutData } from "@/typings/asset-allocation-donut-data";
import { ChartTableDataPoint } from "@/typings/chart-table";

const cashFacilitySectorName = `Cash Facility`;
const pendingTradesSectorName = "Pending Trades";
const settlementFundingAssetName = "Settlement Funding";
const cashInFlightSectorName = "Cash and in-flights";
const cashSectorName = "Novia Cash Facility";

export function convertToCashInFlightDonut(assetAllocationArray: Array<AssetAllocation>): Array<AssetAllocationDonutData> {
    const chartDataArray = new Array<AssetAllocationDonutData>();
    const cashObject = {
        sector: "",
        value: 0,
        weighting: 0,
        inflights: false,
        pushObject: false
    }

    assetAllocationArray.forEach((assetAllocationItem) => {
        if (assetAllocationItem.sector == pendingTradesSectorName || assetAllocationItem.sector.includes(cashFacilitySectorName)) {
            if (assetAllocationItem.name == settlementFundingAssetName || assetAllocationItem.sector == pendingTradesSectorName) {
                cashObject.inflights = true;
            }

            cashObject.value += assetAllocationItem.value;
            cashObject.weighting += assetAllocationItem.weighting;
            cashObject.pushObject = true;
        }
        else {
            chartDataArray.push({
                sector: assetAllocationItem.sector,
                value: assetAllocationItem.value,
                weighting: assetAllocationItem.weighting
            });
        }
    });

    if (cashObject.pushObject) {
        chartDataArray.push({
            sector: cashObject.inflights ? cashInFlightSectorName : cashSectorName,
            value: cashObject.value,
            weighting: cashObject.weighting
        });
    }

    return chartDataArray;
}

export function convertToCashInFlightSectors(assetAllocationArray: Array<AssetAllocation>): Array<ChartTableDataPoint> {
    const chartDataArray = new Array<ChartTableDataPoint>();
    const cashObject = {
        sector: "",
        percentage: 0,
        inflights: false,
        pushObject: false
    }

    assetAllocationArray.forEach((assetAllocationItem) => {
        if (assetAllocationItem.sector == pendingTradesSectorName || assetAllocationItem.sector.includes(cashFacilitySectorName)) {
            if (assetAllocationItem.name == settlementFundingAssetName || assetAllocationItem.sector == pendingTradesSectorName) {
                cashObject.inflights = true;
            }

            cashObject.percentage += assetAllocationItem.weighting;
            cashObject.pushObject = true;
        }
        else {
            chartDataArray.push({
                sector: assetAllocationItem.sector,
                percentage: assetAllocationItem.weighting
            });
        }
    });

    if (cashObject.pushObject) {
        chartDataArray.push({
            sector: cashObject.inflights ? cashInFlightSectorName : cashSectorName,
            percentage: cashObject.percentage
        });
    }

    return chartDataArray;
}
