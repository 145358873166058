


















import SummaryBanner from "../components/summary-banner/SummaryBanner.vue";
import SummaryBannerContainer from "../components/summary-banner/SummaryBannerContainer.vue";
import Skeleton from "../components/Skeleton.vue";
import SummaryBannerHeader from "../components/summary-banner/SummaryBannerHeader.vue";
import SummaryBannerValue from "../components/summary-banner/SummaryBannerValue.vue";
import WrapperDetailsBannerActionBar from "../components/wrapper-details/WrapperDetailsBannerActionBar.vue";
import { Component, Mixins, Prop } from "vue-property-decorator";

import { Portfolio } from "../typings/portfolio";

import SummaryBannerMixin from "../mixins/SummaryBannerMixin";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";
import { PortfolioSummaryBannerLabelling } from "@/typings/portfolio-summary-banner-module";

import { mapGetters } from "vuex";
import { LOAD_PORTFOLIO_SUMMARY_BANNER } from "@/store/action-types";
import { Namespaces } from "../store/namespaces";
import { EtlInformation } from "../typings/api-service";
import { GET_PORTFOLIO_SUMMARY_BANNER_LABELS } from "@/store/getters";

@Component({
    components: {
        Skeleton,
        SummaryBanner,
        SummaryBannerHeader,
        SummaryBannerValue,
        SummaryBannerContainer,
        WrapperDetailsBannerActionBar,
    },
    computed: mapGetters({
        portfolioSummaryBannerLabels: `${Namespaces.PortfolioSummaryBanner}/${GET_PORTFOLIO_SUMMARY_BANNER_LABELS}`,
    }),
})
export default class PortfolioSummaryBanner extends Mixins(ErrorHandlerMixin, SummaryBannerMixin) {

    @Prop()
    public portfolio!: Portfolio;

    @Prop()
    public showSkeleton!: boolean;

    private etlInformation: EtlInformation = {} as EtlInformation;
    public getPortfolioDetails!: Portfolio;
    public portfolioSummaryBannerLabels!: PortfolioSummaryBannerLabelling;

    public get asAtDateContent(): any {
        return {
            label: this.portfolioSummaryBannerLabels.portfolioSummaryAsAtDateLabel,
            value: this.etlInformation.etlDateMessage,
        };
    }

    public get portfolioValue(): any {
        return {
            label: this.portfolioSummaryBannerLabels.portfolioSummaryValueLabel,
            value: `${this.numberToCommaSeparatedString((this.portfolio?.totalValue ?? 0).toFixed(2))}`,
        };
    }

    public async mounted(): Promise<void> {
        this.$api.getEtlDate().then((result) => {
            this.etlInformation = result;
        });
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.PortfolioSummaryBanner}/${LOAD_PORTFOLIO_SUMMARY_BANNER}`)
            .catch((error) => this.handleError(error.response?.status));
    }
}
