import { Entry } from "contentful";
import { ContentfulKeys } from "./contentful-keys";
import ContentfulService from "./ContentfulService";
import { ErrorPageMessages } from "../../constants/error-page-messages";
import { ContentfulErrorPage } from "@/typings/contentful";
import { ErrorPageContent } from "@/typings/error-page";

export default class ErrorPageContentfulService extends ContentfulService {
    public getErrorPageContent(): Promise<ErrorPageContent> {
        return this.getContentfulEntry<ContentfulErrorPage, ErrorPageContent>(
            ContentfulKeys.ErrorPage,
            (result: Entry<ContentfulErrorPage>) => {
                return {
                    serverErrorHeading: result.fields.serverErrorHeading,
                    serverErrorBody: result.fields.serverErrorBody,
                    pageNotFoundHeading: result.fields.pageNotFoundHeading,
                    pageNotFoundBody: result.fields.pageNotFoundBody,
                    forbiddenHeading: result.fields.forbiddenHeading,
                    forbiddenBody: result.fields.forbiddenBody
                };
            }
        ).catch(() => {
            // In case connection to contentful can't be made
            return new Promise<ErrorPageContent>(resolve => {
                resolve({
                    serverErrorHeading: ErrorPageMessages.headings.defaultServerErrorHeading,
                    pageNotFoundHeading: ErrorPageMessages.headings.defaultPageNotFoundHeading,
                    forbiddenHeading: ErrorPageMessages.headings.defaultForbiddenHeading,
                    serverErrorBody: ErrorPageMessages.body.defaultServerErrorBody,
                    pageNotFoundBody: ErrorPageMessages.body.defaultPageNotFoundBody,
                    forbiddenBody: ErrorPageMessages.body.defaultForbiddenBody
                });
            });
        });
    }
}
