export enum ContentfulKeys {
    CompanyInformation = "HMrCkrqtX2p8JtKC30w0X",
    Global = "2NQ6ClKrUipPPVBI0OIlk0",
    Portfolio = "2LBU4a7ipWoeCuo9z6STmR",
    InformationPage = "24UGaVNbCw8rJWMHQo8C1A",
    PortfolioAssetAllocationChart = "78MWWXzWUnL62cKSJpTK7D",
    WrapperDetails = "3Ptkn654Mn7UzBQ091sESE",
    ErrorPage = "4vjWic7MZ620T0HteWN7zE",
    OffPlatformAssets = "qj1bEDBf2hT94vkdMkKMZ",
    WrapperStatement = "31iPrrWp5gX6zMjZRaJb3m",
    MoneyInOut = "1LpEMf3MGB2gGhgMRpy9vM",
    PortfolioValuation = "7AYllEcpfhyDGlgNN9Ubs4",
    ReportingPeriod = "2gwIT1at7KljdtjRgwRZRM",
    WrapperValuation = "3prAZ0Q8joAwzs5f3zNgAr",
    Documents = "73mKS9v76BLq0x7j71J33X",
    TransactionHistory = "2yBQ1k1qas9Lyod4nILKUU",
    NoBalancePage = "68SDY9DiSMdv3LSCvYuM33",
    NotImpersonating = "5kmbRW56b82eB4GOaOqsx0",
    WelcomeMessage = "1IrVGTbN0S67TxL8Pudijy",
    SessionExpiry = "1awj8J9Ruwx2IFKDKFmxUG",
    AccountCreationInProgressPage = "3pnfmIl6Xgopq8GQeglZxQ",
    BrowserNotSupportedPage = "2YzU2qhwOp7Ke9RH9cuNWF",
    WrapperPortfolioSummaryBannerLabeling = "7vJL8tCmvpwCbzobDweMOG",
    PortfolioSummaryBannerLabelling = "3rMyX0ZpF0IRg3c6h3r4fy",
}
