import { Entry } from "contentful";
import { ContentfulKeys } from "./contentful-keys";
import ContentfulService from "./ContentfulService";

import { ContentfulReportingPeriodContent } from "@/typings/contentful";
import { ReportingPeriodContent } from "@/typings/reporting-period";

export default class ReportingPeriodContentfulService extends ContentfulService {
    public getReportingPeriodContent(): Promise<ReportingPeriodContent> {
        return this.getContentfulEntry<ContentfulReportingPeriodContent, ReportingPeriodContent>(
            ContentfulKeys.ReportingPeriod,
            (result: Entry<ContentfulReportingPeriodContent>) => {
                return {
                    reportingPeriod: result.fields.reportingPeriod,
                    valuationPeriod: result.fields.valuationPeriod,
                    lastSixMonthsRadioLabel: result.fields.lastSixMonthsRadioLabel,
                    lastTwelveMonthsRadioLabel: result.fields.lastTwelveMonthsRadioLabel,
                    startValue: result.fields.startValue,
                    moneyIn: result.fields.moneyIn,
                    moneyOut: result.fields.moneyOut,
                    endValue: result.fields.endValue,
                    changePercentage: result.fields.changePercentage
                };
            }
        );
    }
}
