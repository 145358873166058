import { User } from "oidc-client";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AuthMixin extends Vue {
    public getAuthenticatedUser(): Promise<User> {
        return new Promise((resolve, reject) => {
            this.$auth.getUser().then((user: User | null) => {
                if (user && !user.expired) {
                    resolve(user);
                } else {
                    reject();
                }
            });
        });
    }

    public removeInvestorContext(): Promise<User> {
        return this.$auth.removeInvestorContext();
    }

    public removeUser() {
        this.$auth.removeUser();
    }
}
