

























































































import { Component, Mixins } from "vue-property-decorator";
import { mapGetters } from "vuex";

import { BRow, BCol, BTable, BPagination, BFormGroup, BFormInput, BAlert } from "bootstrap-vue";
import Button from "@/components/Button.vue";
import FluidContainer from "@/components/FluidContainer.vue";
import WrapperPortfolioSummaryBanner from "../components/WrapperPortfolioSummaryBanner.vue";
import Skeleton from "@/components/Skeleton.vue";
import ReportingPeriod from "@/components/valuation/ReportingPeriod.vue";

import { Namespaces } from "../store/namespaces";
import {
    GET_WRAPPER_BY_ID,
    GET_WRAPPER_TRANSACTIONS_PAGE_HEADINGS,
    GET_WRAPPER_TRANSACTIONS_TABLE_CONTENT
} from "../store/getters";
import { Wrapper } from "../typings/wrapper";
import RouteMixin from "../mixins/RouteMixin";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";
import { LOAD_WRAPPER, LOAD_WRAPPER_TRANSACTIONS_CONTENT } from "../store/action-types";
import AuthMixin from "../mixins/AuthMixin";
import { Interval, WrapperTransactionsApiResult } from "../typings/api-service";
import { WrapperTransaction, WrapperTransactionTableRow } from "../typings/wrapper-transaction";
import { TableField } from "../typings/table";
import _ from "lodash";
import moment from "moment";
import { TransactionHistoryPageHeadings, TransactionHistoryTableHeadings } from "../typings/transaction-history-page";
import NumberMixin from "@/mixins/NumberMixin";
import ReactivePageTitle from "@/components/ReactivePageTitle.vue";
import { getRichTextContentWithHyperlinks } from "@/helpers/contentful-helper";

@Component({
    components: {
        BRow,
        BCol,
        BTable,
        BPagination,
        BFormGroup,
        BFormInput,
        BAlert,
        Button,
        FluidContainer,
        Skeleton,
        ReportingPeriod,
        WrapperPortfolioSummaryBanner,
        ReactivePageTitle
    },
    computed: mapGetters({
        getWrapperById: `${Namespaces.Wrapper}/${GET_WRAPPER_BY_ID}`,
        getWrapperTransactionsPageHeadings: `${Namespaces.WrapperTransactions}/${GET_WRAPPER_TRANSACTIONS_PAGE_HEADINGS}`,
        getWrapperTransactionsTableContent: `${Namespaces.WrapperTransactions}/${GET_WRAPPER_TRANSACTIONS_TABLE_CONTENT}`
    })
})
export default class WrapperTransactionPage extends Mixins(RouteMixin, ErrorHandlerMixin, NumberMixin, AuthMixin) {
    private getWrapperById!: Function;
    private getWrapperTransactionsPageHeadings!: TransactionHistoryPageHeadings;
    private getWrapperTransactionsTableContent!: TransactionHistoryTableHeadings;

    public interval = Interval.SixMonths;
    public allTransactions: Array<WrapperTransaction> = [];
    public filteredTransactions: Array<WrapperTransaction> = [];
    public perPage = 10;
    public currentPage = 1;
    public isDataLoaded = false;
    public resultsFound = this.filteredTransactions.length > 0;

    public get showSkeleton(): boolean {
        return !this.isDataLoaded;
    }

    public get noResults(): boolean {
        return _.isEmpty(this.allTransactions);
    }

    private get wrapper(): Wrapper {
        return this.getWrapperById(this.idFromRoute);
    }

    public get tableData() {
        return this.filteredTransactions.map(
            (transaction: WrapperTransaction) =>
                ({
                    date: moment(transaction.date).format("DD/MM/YYYY"),
                    wrapperId: transaction.wrapperId,
                    transaction: transaction.transaction,
                    investmentName: transaction.investmentName,
                    unitChange: transaction.unitChange.toFixed(2),
                    transactionValue: this.numberToCommaSeparatedString(transaction.transactionValue.toFixed(2))
                } as WrapperTransactionTableRow)
        );
    }

    public get tableFields(): Array<TableField> {
        return [
            {
                key: "date",
                label: this.getWrapperTransactionsTableContent.dateTableHeading,
                sortable: true
            },
            {
                key: "wrapperId",
                label: this.getWrapperTransactionsTableContent.wrapperIdTableHeading
            },
            {
                key: "transaction",
                label: this.getWrapperTransactionsTableContent.transactionTableHeading,
                sortable: true
            },
            {
                key: "investmentName",
                label: this.getWrapperTransactionsTableContent.investmentNameTableHeading,
                sortable: true
            },
            {
                key: "unitChange",
                label: this.getWrapperTransactionsTableContent.unitChangeTableHeading,
                sortable: true,
                thClass: "text-right",
                tdClass: "text-right"
            },
            {
                key: "transactionValue",
                label: this.getWrapperTransactionsTableContent.transactionValueTableHeading,
                sortable: true,
                thClass: "text-right",
                tdClass: "text-right"
            }
        ];
    }

    public get richTextDearCeoLabel(): string{
        return getRichTextContentWithHyperlinks(this.getWrapperTransactionsPageHeadings.dearCeoLetterLabel);
    }

    public reportingPeriodChange(value: number): void {
        this.interval = value;
        this.getApiData();
    }

    public searchChange(searchValue: string): void {
        if (searchValue === "") {
            this.filteredTransactions = this.allTransactions;
            return;
        }

        this.filteredTransactions = this.allTransactions.filter((transaction: WrapperTransaction) => {
            return (
                transaction.transaction.toUpperCase().includes(searchValue.toUpperCase()) ||
                transaction.investmentName.toUpperCase().includes(searchValue.toUpperCase())
            );
        });
    }

    public sortCompare(a: WrapperTransactionTableRow, b: WrapperTransactionTableRow, key: string): number | boolean {
        if (key === "transactionValue") {
            return parseFloat(a.transactionValue) - parseFloat(b.transactionValue);
        }
        if (key === "date") {
            return moment(a.date, "DD/MM/YYYY") < moment(b.date, "DD/MM/YYYY");
        }

        // Let Bootstrap Vue sort using its default functionality
        return false;
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.Wrapper}/${LOAD_WRAPPER}`, { wrapperId: this.idFromRoute })
            .catch(error => this.handleError(error.response?.status));
        this.$store
            .dispatch(`${Namespaces.WrapperTransactions}/${LOAD_WRAPPER_TRANSACTIONS_CONTENT}`)
            .catch(error => this.handleError(error.response?.status));
    }

    public mounted(): void {
        this.getAuthenticatedUser().then(() => {
            this.getApiData();
        });
    }

    private getApiData(): void {
        this.$api
            .getWrapperTransactions(this.idFromRoute as number, this.interval)
            .then((result: WrapperTransactionsApiResult) => {
                this.allTransactions = result.transactions;
                this.filteredTransactions = result.transactions;
                this.isDataLoaded = true;
            })
            .catch(error => this.handleError(error.response?.status));
    }

    public async downloadTransactionHistoryCsv(wrapperId: number) {
        await this.$api
            .getWrapperTransactionsAsCsv(wrapperId, this.interval)
            .catch(error => this.handleError(error.response?.status));
    }
}
