import AccountCreationInProgressPageContentfulService from "@/services/contentful/AccountCreationInProgressPageContentfulService";

import { Commit } from "vuex";
import { LOAD_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT } from "../action-types";
import { GET_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT } from "../getters";
import { SAVE_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT, SET_ACCOUNT_CREATION_IN_PROGRESS_PAGE_LOADED_FLAG } from "../mutation-types";
import { AccountCreationInProgressPageState } from "../typings/account-creation-in-progress-page";
import { AccountCreationInProgressPageModel } from "@/typings/account-creation-in-progress-page";

const contentful = new AccountCreationInProgressPageContentfulService();

const pageState = (): AccountCreationInProgressPageState => ({
    accountCreationInProgressPage: {
        header: "",
        body: ""
    },
    isLoaded: false
});

const getters = {
    [GET_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT]: (state: AccountCreationInProgressPageState) => {
        return state.accountCreationInProgressPage;
    }
};

const mutations = {
    [SAVE_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT](state: AccountCreationInProgressPageState, accountCreationInProgressPageModel: AccountCreationInProgressPageModel) {
        state.accountCreationInProgressPage = accountCreationInProgressPageModel;
    },
    [SET_ACCOUNT_CREATION_IN_PROGRESS_PAGE_LOADED_FLAG](state: AccountCreationInProgressPageState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    async [LOAD_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT]({ commit, state }: { commit: Commit; state: AccountCreationInProgressPageState }) {
        if (!state.isLoaded) {
            contentful.getAccountCreationInProgressPageContent().then((result: AccountCreationInProgressPageModel) => {
                commit(SAVE_ACCOUNT_CREATION_IN_PROGRESS_PAGE_CONTENT, result);
                commit(SET_ACCOUNT_CREATION_IN_PROGRESS_PAGE_LOADED_FLAG, true);
            });
        }
    }
};

export const AccountCreationInProgressPageModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state: pageState
};
