export default class IconService {
    private iconTypeToCssClassMap = new Map();

    constructor() {
        this.iconTypeToCssClassMap.set("NOVSSISA", "isa-icon");
        this.iconTypeToCssClassMap.set("NOVJISA", "jisa-icon");
        this.iconTypeToCssClassMap.set("NOVSIPP", "sipp-icon");
        this.iconTypeToCssClassMap.set("NOVSIPPASP", "sipp-icon");
        this.iconTypeToCssClassMap.set("NOVSIPPDD", "sipp-icon");
        this.iconTypeToCssClassMap.set("NOVGIAG", "gia-icon");
        this.iconTypeToCssClassMap.set("NOVGIAN", "gia-icon");
        this.iconTypeToCssClassMap.set("NOVOFB", "offshore-bond-icon");
        this.iconTypeToCssClassMap.set("NOVOPA", "open-new-icon");
        // TODO: Missing icon for Off Platform Assets
    }

    public getIconClassFromIconType(wrapperType: string): string {
        if (this.iconTypeToCssClassMap.has(wrapperType)) {
            return this.iconTypeToCssClassMap.get(wrapperType);
        }

        return "";
    }
}
