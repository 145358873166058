












import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {}
})
export default class SummaryBannerValue extends Vue {
    @Prop({ default: false })
    small!: boolean;
}
