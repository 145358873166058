import { Entry } from "contentful";
import { ContentfulKeys } from "./contentful-keys";
import ContentfulService from "./ContentfulService";
import { ContentfulWrapperPage, ContentfulWrapperStatementPage } from "@/typings/contentful";
import { WrapperPageHeadings } from "@/typings/wrapper-page";
import { WrapperStatementPageContentHeadings } from "@/typings/wrapper-statement";

export default class WrapperContentfulService extends ContentfulService {
    public getwrapperDetailsContent(): Promise<WrapperPageHeadings> {
        return this.getContentfulEntry<ContentfulWrapperPage, WrapperPageHeadings>(
            ContentfulKeys.WrapperDetails,
            (result: Entry<ContentfulWrapperPage>) => {
                return {
                    wrapperDetailsHeader: result.fields.wrapperDetailsHeader,
                    wrapperValueTitle: result.fields.wrapperValueTitle,
                    modelPortfolioTitle: result.fields.modelPortfolioTitle,
                    sectorTableHeading: result.fields.sectorTableHeading,
                    assetNameTableHeading: result.fields.assetNameTableHeading,
                    assetValueTableHeading: result.fields.assetValueTableHeading,
                    percentageTableHeading: result.fields.percentageTableHeading,
                    wrapperStatementButton: result.fields.wrapperStatementButton
                };
            }
        );
    }

    public getwrapperStatementContent(): Promise<WrapperStatementPageContentHeadings> {
        return this.getContentfulEntry<ContentfulWrapperStatementPage, WrapperStatementPageContentHeadings>(
            ContentfulKeys.WrapperStatement,
            (result: Entry<ContentfulWrapperStatementPage>) => {
                return {
                    wrapperStatementHeader: result.fields.wrapperStatementHeader,
                    dearCeoLetterLabel: result.fields.dearCeoLetterLabel,
                    wrapperValueTitle: result.fields.wrapperValueTitle,
                    modelPortfolioTitle: result.fields.modelPortfolioTitle,
                    lastSixMonthsLabel: result.fields.lastSixMonthsLabel,
                    lastTwelveMonthsLabel: result.fields.lastTwelveMonthsLabel,
                    statementPeriod: result.fields.statementPeriod,
                    transactionNameTableHeader: result.fields.transactionNameTableHeader,
                    dateTableHeader: result.fields.dateTableHeader,
                    valueTableHeader: result.fields.valueTableHeader,
                    noTransactionsAvailableMessage: result.fields.noTransactionsAvailableMessage
                };
            }
        );
    }
}
