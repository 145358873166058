
import NumberMixin from "@/mixins/NumberMixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import ErrorHandlerMixin from "../../mixins/ErrorHandlerMixin";
import { LOAD_PORTFOLIO_CHART } from "../../store/action-types";
import { GET_CHART_COLOURS } from "../../store/getters";
import { Namespaces } from "../../store/namespaces";
import { ChartTableDataPoint } from "../../typings/chart-table";

const alignMapping = new Map([
    ["sectorTableHeading", false],
    ["assetNameTableHeading", false],
    ["assetValueTableHeading", true],
    ["percentageTableHeading", true],
]);

@Component({
    components: {},
})
export default class ChartTable extends Mixins(ErrorHandlerMixin, NumberMixin) {
    @Prop({ default: () => [] })
    public tableHeaders!: Array<string>;

    @Prop({ default: () => [] })
    public tableData!: Array<ChartTableDataPoint>;

    public getHeadingClass = (heading: string) =>
        alignMapping.get(heading) ? "border-top-0 text-right" : "border-top-0";

    public getBackgroundColour(dataPoint: ChartTableDataPoint): string {
        const chartColours: Map<string, string> = this.$store.getters[`${Namespaces.Portfolio}/${GET_CHART_COLOURS}`];
        if (chartColours) {
            return `background-color: ${chartColours.get(dataPoint.sector)}`;
        }

        return "";
    }

    public getSortedDataPointsByValue(dataPoints: Array<ChartTableDataPoint>): Array<ChartTableDataPoint> {
        const sortedDataPoints = [...dataPoints];
        sortedDataPoints.sort((a: ChartTableDataPoint, b: ChartTableDataPoint) => {
            return b.percentage - a.percentage;
        });

        return sortedDataPoints;
    }

    public getSortedDataPointsBySectorAndValue(dataPoints: Array<ChartTableDataPoint>): Array<ChartTableDataPoint> {
        const sortedDataPoints = [...dataPoints].filter(a => a.percentage > 0);

        sortedDataPoints.sort((a: ChartTableDataPoint, b: ChartTableDataPoint) => {
            return a.sector.localeCompare(b.sector) || b.percentage - a.percentage;
        });

        return sortedDataPoints;
    }

    public created() {
        this.$store
            .dispatch(`${Namespaces.Portfolio}/${LOAD_PORTFOLIO_CHART}`)
            .catch((error) => this.handleError(error.response?.status));
    }
}
