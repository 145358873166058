import { Commit } from "vuex";
import WelcomeMessageContentfulService from "@/services/contentful/WelcomeMessageContentfulService";
import { LOAD_WELCOME_MESSAGE_CONTENT } from "../action-types";
import { SAVE_WELCOME_MESSAGE_CONTENT, SET_WELCOME_MESSAGE_LOADED } from "../mutation-types";

import { WelcomeMessageContent } from "@/typings/welcome-message";
import { WelcomeMessageState } from "../typings/welcome-message";
import { GET_WELCOME_MESSAGE_CONTENT } from "../getters";

const contentful = new WelcomeMessageContentfulService

const state = (): WelcomeMessageState => ({
    welcomeMessageContent: {
        welcomeMessage: "",
        impersonationMessage: "",
        stopImpersonationMessage: ""
    }, 
    isLoaded: false
})

const getters = {
    [GET_WELCOME_MESSAGE_CONTENT]: (state: WelcomeMessageState): WelcomeMessageContent => {
        return {
            welcomeMessage: state.welcomeMessageContent.welcomeMessage,
            impersonationMessage: state.welcomeMessageContent.impersonationMessage,
            stopImpersonationMessage: state.welcomeMessageContent.stopImpersonationMessage
        };
    }
}

const mutations = {
    [SAVE_WELCOME_MESSAGE_CONTENT](state: WelcomeMessageState, model: WelcomeMessageContent) {
        state.welcomeMessageContent = model
    },
    [SET_WELCOME_MESSAGE_LOADED](state: WelcomeMessageState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    [LOAD_WELCOME_MESSAGE_CONTENT]({ commit, state }: { commit: Commit; state: WelcomeMessageState }) {
        if (!state.isLoaded) {
            return contentful.getWelcomeMessageContent().then((result: WelcomeMessageContent) => {
                commit(SAVE_WELCOME_MESSAGE_CONTENT, result);
                commit(SET_WELCOME_MESSAGE_LOADED, true);
            });
        }
    }
}

export const WelcomeMessageModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};