
































import { Component, Mixins } from "vue-property-decorator";
import { mapGetters } from "vuex";

import { BRow, BCol } from "bootstrap-vue";
import FluidContainer from "../components/FluidContainer.vue";
import SummaryBanner from "../components/summary-banner/SummaryBanner.vue";
import SummaryBannerHeader from "../components/summary-banner/SummaryBannerHeader.vue";
import SummaryBannerValue from "../components/summary-banner/SummaryBannerValue.vue";
import SummaryBannerContainer from "../components/summary-banner/SummaryBannerContainer.vue";
import DocumentsTable from "../components/documentsTable/DocumentsTable.vue";
import Skeleton from "../components/Skeleton.vue";
import AuthMixin from "../mixins/AuthMixin";
import NumberMixin from "../mixins/NumberMixin";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";

import { DocumentsApiResult, RelatedInvestorDocuments } from "../typings/api-service";
import { InvestorDocument } from "../typings/investor-document";
import { Namespaces } from "../store/namespaces";
import { LOAD_DOCUMENTS_CONTENT, LOAD_INVESTOR_DETAILS } from "../store/action-types";
import { DocumentsPageContentHeadings } from "../typings/documents-page";
import { GET_DOCUMENTS_CONTENT, GET_DOCUMENTS_TABLE_HEADINGS, GET_INVESTOR_DETAILS } from "../store/getters";
import { InvestorDetails } from "@/typings/investor-details";

@Component({
    components: {
        FluidContainer,
        BRow,
        BCol,
        SummaryBanner,
        SummaryBannerHeader,
        SummaryBannerValue,
        SummaryBannerContainer,
        DocumentsTable,
        Skeleton,
    },
    computed: mapGetters({
        getDocumentsPageHeadings: `${Namespaces.Documents}/${GET_DOCUMENTS_CONTENT}`,
        getDocumentsTableHeadings: `${Namespaces.Documents}/${GET_DOCUMENTS_TABLE_HEADINGS}`,
        getInvestorDetails: `${Namespaces.InvestorDetails}/${GET_INVESTOR_DETAILS}`,
    }),
})
export default class DocumentsPage extends Mixins(AuthMixin, ErrorHandlerMixin, NumberMixin) {
    private documentsResult: DocumentsApiResult | null = null;

    public getDocumentsPageHeadings!: DocumentsPageContentHeadings;
    public getInvestorDetails!: InvestorDetails;

    public get showSkeleton(): boolean {
        return this.documentsResult === null;
    }

    public getFormattedSummary(): string {
        return this.getDocumentsPageHeadings.documentsHeading.replace(
            "#INVESTORNAME#",
            this.getInvestorDetails.investorName
        );
    }

    public getFormattedRelatedInvestorSummary(firstName: string, lastName: string): string {
        return this.getDocumentsPageHeadings.documentsRelatedInvestorHeading.replace(
            "#INVESTORNAME#",
            `${firstName} ${lastName}` ?? ""
        );
    }

    public get investorDocuments(): Array<InvestorDocument> {
        return this.documentsResult ? this.documentsResult.documents : [];
    }

    public get relatedInvestors(): Array<RelatedInvestorDocuments> {
        return this.documentsResult ? this.documentsResult.relatedInvestorDocuments : [];
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.Documents}/${LOAD_DOCUMENTS_CONTENT}`)
            .catch((error) => this.handleError(error.response?.status));
        this.$store
            .dispatch(`${Namespaces.InvestorDetails}/${LOAD_INVESTOR_DETAILS}`)
            .catch((error) => this.handleError(error.response?.status));
    }

    public mounted(): void {
        this.getAuthenticatedUser().then(() => {
            this.$api
                .getAllDocuments()
                .then((response: DocumentsApiResult) => {
                    this.documentsResult = response;
                })
                .catch((error) => this.handleError(error.response?.status));
        });
    }
}
