import Vue from "vue";
import { Commit } from "vuex";
import {
    SAVE_ASSET_ALLOCATION,
    SAVE_PORTFOLIO_HEADINGS,
    SAVE_PORTFOLIO_VALUE,
    SAVE_WRAPPERS,
    SAVE_PORTFOLIO_CHART_COLOURS,
    SET_PORTFOLIO_LOADED_FLAG,
    SET_PORTFOLIO_HEADINGS_LOADED_FLAG,
    SET_PORTFOLIO_CHART_LOADED_FLAG,
    SAVE_RELATED_INVESTORS
} from "../mutation-types";
import { LOAD_PORTFOLIO_HEADINGS, LOAD_PORTFOLIO_DETAILS, LOAD_PORTFOLIO_CHART } from "../action-types";
import PortfolioContentfulService from "@/services/contentful/PortfolioContentfulService";
import _ from "lodash";

import { Portfolio } from "@/typings/portfolio";
import { AssetAllocation } from "@/typings/asset-allocation";
import { MappedPortfolioAssetAllocationChart } from "@/typings/chart-table";
import {
    PortfolioAssetAllocationTableHeadings,
    PortfolioHeadings,
    PortfolioPageHeadings
} from "@/typings/portfolio-page";
import {
    GET_CHART_COLOURS,
    GET_CHART_TABLE_HEADINGS,
    GET_PORTFOLIO_DETAILS,
    GET_PORTFOLIO_PAGE_HEADINGS,
    GET_RELATED_INVESTOR_BY_WRAPPER_ID,
} from "../getters";
import { PortfolioState } from "../typings/portfolio";
import { AssetAllocationApiResult, WrapperGroup, WrappersApiResult } from "@/typings/api-service";
import { AxiosError } from "axios";
import { RelatedInvestor } from "@/typings/related-investor";

const contentful = new PortfolioContentfulService();

const moduleState = (): PortfolioState => ({
    headings: {
        portfolioSummaryTitle: "",
        portfolioSummaryDateTitle: "",
        portfolioAssetAllocationHeader: "",
        portfolioBreakdownHeader: "",
        portfolioValueTitle: "",
        sectorTableHeading: "",
        percentageTableHeading: "",
        consolidatedValueHeading: ""
    },
    chart: {
        colours: new Map<string, string>()
    },
    details: {
        assetAllocation: [],
        totalValue: 0,
        wrapperGroups: [],
        relatedInvestors: []
    },
    isPortfolioDataLoaded: false,
    isHeadingsLoaded: false,
    isChartDataLoaded: false
});

const getters = {
    [GET_PORTFOLIO_PAGE_HEADINGS]: (state: PortfolioState): PortfolioPageHeadings => {
        return {
            portfolioSummaryTitle: state.headings.portfolioSummaryTitle,
            portfolioSummaryDateTitle: state.headings.portfolioSummaryDateTitle,
            portfolioAssetAllocationHeader: state.headings.portfolioAssetAllocationHeader,
            portfolioBreakdownHeader: state.headings.portfolioBreakdownHeader,
            portfolioValueTitle: state.headings.portfolioValueTitle,
            consolidatedValueWrapperHeading: state.headings.consolidatedValueWrapperHeading,
            relatedInvestorHeader: state.headings.relatedInvestorHeader,
            offPlatformAssetsExcludedTotalMessage: state.headings.offPlatformAssetsExcludedTotalMessage,
        };
    },
    [GET_CHART_COLOURS]: (state: PortfolioState): Map<string, string> => {
        return state.chart.colours;
    },
    [GET_CHART_TABLE_HEADINGS]: (state: PortfolioState): PortfolioAssetAllocationTableHeadings => {
        return {
            sectorTableHeading: state.headings.sectorTableHeading,
            percentageTableHeading: state.headings.percentageTableHeading
        };
    },
    [GET_PORTFOLIO_DETAILS]: (state: PortfolioState): Portfolio => {
        return state.details;
    },
    [GET_RELATED_INVESTOR_BY_WRAPPER_ID]: (state: PortfolioState) => (wrapperId: number): RelatedInvestor | null => {
        return (
            state.details.relatedInvestors.find(x =>
                x.wrapperGroups
                    .flatMap(group => group.wrappers)
                    .map(wrapper => wrapper.id)
                    .includes(wrapperId)
            ) || null
        );
    }
};

const mutations = {
    [SAVE_PORTFOLIO_HEADINGS](state: PortfolioState, model: PortfolioHeadings) {
        state.headings = {
            portfolioSummaryTitle: model.portfolioSummaryTitle,
            portfolioSummaryDateTitle: model.portfolioSummaryDateTitle,
            portfolioAssetAllocationHeader: model.portfolioAssetAllocationHeader,
            portfolioBreakdownHeader: model.portfolioBreakdownHeader,
            portfolioValueTitle: model.portfolioValueTitle,
            sectorTableHeading: model.sectorTableHeading,
            percentageTableHeading: model.percentageTableHeading,
            consolidatedValueWrapperHeading: model.consolidatedValueWrapperHeading,
            relatedInvestorHeader: model.relatedInvestorHeader,
            offPlatformAssetsExcludedTotalMessage: model.offPlatformAssetsExcludedTotalMessage
        };
    },
    [SAVE_PORTFOLIO_VALUE](state: PortfolioState, value: number) {
        state.details = {
            ...state.details,
            totalValue: value
        };
    },
    [SAVE_WRAPPERS](state: PortfolioState, model: Array<WrapperGroup>) {
        state.details = {
            ...state.details,
            wrapperGroups: model
        };
    },
    [SAVE_RELATED_INVESTORS](state: PortfolioState, model: Array<RelatedInvestor>) {
        state.details = {
            ...state.details,
            relatedInvestors: model
        };
    },
    [SAVE_ASSET_ALLOCATION](state: PortfolioState, model: Array<AssetAllocation>) {
        state.details = {
            ...state.details,
            assetAllocation: model
        };
    },
    [SAVE_PORTFOLIO_CHART_COLOURS](state: PortfolioState, colours: Map<string, string>) {
        state.chart = {
            ...state.chart,
            colours: colours
        };
    },
    [SET_PORTFOLIO_LOADED_FLAG](state: PortfolioState, value: boolean) {
        state.isPortfolioDataLoaded = value;
    },
    [SET_PORTFOLIO_HEADINGS_LOADED_FLAG](state: PortfolioState, value: boolean) {
        state.isHeadingsLoaded = value;
    },
    [SET_PORTFOLIO_CHART_LOADED_FLAG](state: PortfolioState, value: boolean) {
        state.isChartDataLoaded = value;
    }
};

const actions = {
    async [LOAD_PORTFOLIO_HEADINGS]({ commit, state }: { commit: Commit; state: PortfolioState }) {
        if (!state.isHeadingsLoaded) {
            return contentful.getPortfolioHeadings().then((portfolioHeaders: PortfolioHeadings) => {
                commit(SAVE_PORTFOLIO_HEADINGS, portfolioHeaders);
                commit(SET_PORTFOLIO_HEADINGS_LOADED_FLAG, true);
            });
        }
    },
    async [LOAD_PORTFOLIO_DETAILS]({ commit, state }: { commit: Commit; state: PortfolioState }) {
        return new Promise((resolve, reject) => {
            if (!state.isPortfolioDataLoaded) {
                Promise.all([Vue.prototype.$api.getPortfolioAssetAllocations(), Vue.prototype.$api.getAllWrappers()])
                    .then((result: [AssetAllocationApiResult, WrappersApiResult]) => {
                        const totalValue = _.sum(result[0].assetAllocation.map(asset => asset.value));

                        commit(SAVE_ASSET_ALLOCATION, result[0].assetAllocation);
                        commit(SAVE_PORTFOLIO_VALUE, totalValue);

                        result[1].investorWrappersModel.wrapperGroups
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .forEach(element => {
                                element.wrappers.sort((a, b) => a.id - b.id);
                            });

                        commit(SAVE_WRAPPERS, result[1].investorWrappersModel.wrapperGroups);

                        commit(SET_PORTFOLIO_LOADED_FLAG, true);

                        const { relatedInvestorsWrappersModels } = result[1];
                        if (relatedInvestorsWrappersModels) {
                            commit(SAVE_RELATED_INVESTORS, relatedInvestorsWrappersModels);
                        }

                        resolve(true);
                    })
                    .catch((error: Error | AxiosError) => {
                        reject(error);
                    });
            }
        });
    },
    async [LOAD_PORTFOLIO_CHART]({ commit, state }: { commit: Commit; state: PortfolioState }) {
        if (!state.isChartDataLoaded) {
            return contentful.getPortfolioAssetAllocationChart().then((result: MappedPortfolioAssetAllocationChart) => {
                commit(SAVE_PORTFOLIO_CHART_COLOURS, result.chartColours);
                commit(SET_PORTFOLIO_CHART_LOADED_FLAG, true);
            });
        }
    }
};

export const PortfolioModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state: moduleState
};
