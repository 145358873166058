























import { Component, Mixins } from "vue-property-decorator";

import { BRow, BCol, BTable } from "bootstrap-vue";
import FluidContainer from "../components/FluidContainer.vue";
import SummaryBanner from "../components/summary-banner/SummaryBanner.vue";
import SummaryBannerContainer from "../components/summary-banner/SummaryBannerContainer.vue";
import SummaryBannerHeader from "../components/summary-banner/SummaryBannerHeader.vue";
import SummaryBannerValue from "../components/summary-banner/SummaryBannerValue.vue";
import Skeleton from "../components/Skeleton.vue";
import AuthMixin from "../mixins/AuthMixin";
import NumberMixin from "../mixins/NumberMixin";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";

import { OffPlatformAssetsApiResult } from "../typings/api-service";
import { OffPlatformAssets } from "../typings/off-platform-assets";
import { OffPlatformAssetsSummary } from "../typings/off-platform-assets-summary";
import { Namespaces } from "../store/namespaces";
import { LOAD_OFF_PLATFORM_ASSETS_CONTENT } from "../store/action-types";
import { OffPlatformAssetsContentHeadings, OffPlatformAssetsTableHeadings } from "../typings/off-platform-assets-page";
import { GET_OFF_PLATFORM_ASSETS_CONTENT, GET_OFF_PLATFORM_ASSETS_TABLE_HEADINGS } from "../store/getters";
import SummaryBannerMixin from "../mixins/SummaryBannerMixin";
import OffPlatformAssetsSummaryBanner from "../components/OffPlatformAssetsSummaryBanner.vue";

@Component({
    components: {
        FluidContainer,
        BRow,
        BCol,
        BTable,
        SummaryBanner,
        SummaryBannerContainer,
        SummaryBannerHeader,
        SummaryBannerValue,
        Skeleton,
        OffPlatformAssetsSummaryBanner,
    },
})
export default class OffPlatformAssetsPage extends Mixins(
    AuthMixin,
    ErrorHandlerMixin,
    NumberMixin,
    SummaryBannerMixin
) {
    private offPlatformAssetsResult: OffPlatformAssetsApiResult | null = null;

    public get showSkeleton(): boolean {
        return this.offPlatformAssetsResult === null;
    }

    public get totalValue(): string {
        return this.getTotalValueContent(
            this.offPlatformAssetsPageHeadings.totalValueHeading,
            this.offPlatformAssetsResult?.value
        );
    }

    public get offPlatformAssetsSummary(): OffPlatformAssetsSummary {
        return {
            title: this.offPlatformAssetsPageHeadings.offPlatformAssetsTitle,
            totalValue: this.offPlatformAssetsResult?.value ?? 0,
        };
    }

    public get offPlatformAssets(): Array<OffPlatformAssets> {
        return this.offPlatformAssetsResult ? this.offPlatformAssetsResult.offPlatformAssets : [];
    }

    public get offPlatformAssetsPageHeadings(): OffPlatformAssetsContentHeadings {
        return this.$store.getters[`${Namespaces.OffPlatformAssets}/${GET_OFF_PLATFORM_ASSETS_CONTENT}`];
    }

    public get offPlatformAssetsTableHeadings(): OffPlatformAssetsTableHeadings {
        return this.$store.getters[`${Namespaces.OffPlatformAssets}/${GET_OFF_PLATFORM_ASSETS_TABLE_HEADINGS}`];
    }

    public get tableData() {
        return this.offPlatformAssets.map((asset: OffPlatformAssets) => {
            return {
                assetName: asset.assetName,
                value: this.numberToCommaSeparatedString(asset.value.toFixed(2)),
            };
        });
    }

    public get tableFields() {
        return [
            {
                key: "assetName",
                label: this.offPlatformAssetsTableHeadings.assetNameTableHeading,
            },
            {
                key: "value",
                label: this.offPlatformAssetsTableHeadings.valueTableHeading,
                thClass: "text-right",
                tdClass: "text-right",
            },
        ];
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.OffPlatformAssets}/${LOAD_OFF_PLATFORM_ASSETS_CONTENT}`)
            .catch((error) => this.handleError(error.response?.status));
    }

    public mounted(): void {
        this.getAuthenticatedUser().then(() => {
            this.$api
                .getOffPlatformAssets()
                .then((response: OffPlatformAssetsApiResult) => {
                    this.offPlatformAssetsResult = response;
                })
                .catch((error) => this.handleError(error.response?.status));
        });
    }
}
