
















import { Component, Mixins } from "vue-property-decorator";
import { BIcon, BRow, BCol } from "bootstrap-vue";
import Button from "../components/Button.vue";
import FluidContainer from "../components/FluidContainer.vue";
import AuthMixin from "../mixins/AuthMixin";
import { User } from "oidc-client";
import { GET_NOT_IMPERSONATING_CONTENT } from "@/store/getters";
import { Namespaces } from "@/store/namespaces";
import { mapGetters } from "vuex";
import { NotImpersonatingPageContent } from "@/typings/not-impersonating-page";
import { LOAD_NOT_IMPERSONATING_CONTENT } from "@/store/action-types";
import ErrorHandlerMixin from "@/mixins/ErrorHandlerMixin";

@Component({
    components: {
        BIcon,
        BRow,
        BCol,
        FluidContainer,
        Button
    },
    computed: mapGetters({
        getNotImpersonatingContent: `${Namespaces.NotImpersonating}/${GET_NOT_IMPERSONATING_CONTENT}`
    })
})
export default class NotImpersonatingPage extends Mixins(AuthMixin, ErrorHandlerMixin) {
    private userType = "";

    public getNotImpersonatingContent!: NotImpersonatingPageContent;
    public get NotImpersonatingMessage(): string {
        if (this.userType != "") {
            return this.getNotImpersonatingContent.noInvestorContextMessage.replace("#USERTYPE#", this.userType ?? "");
        } else {
            return this.getNotImpersonatingContent.notLoggedInMessage;
        }
    }

    public async created(): Promise<void> {
        await this.$store
            .dispatch(`${Namespaces.NotImpersonating}/${LOAD_NOT_IMPERSONATING_CONTENT}`)
            .catch(error => this.handleError(error.response?.status));
    }

    public async mounted() {
        await this.getAuthenticatedUser().then((user: User) => {
            if (user) {
                if (user.profile.user_type && !user.profile.investor_context) {
                    this.userType = user.profile.user_type;
                }
                this.removeUser();
            }
        });
    }
}
