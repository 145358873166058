import { Component, Vue } from "vue-property-decorator";

@Component
export default class NumberMixin extends Vue {
    public numberToCommaSeparatedString(value: number | string): string {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d)+\.)/g, ",");
    }

    public getMoneyValueString(value: number): string {
        return `£${this.numberToCommaSeparatedString(value.toFixed(2))}`;
    }
}
