











import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Chart } from "highcharts-vue";
import _ from "lodash";

import ChartBuilderService from "../../services/ChartBuilderService";
import ErrorHandlerMixin from "../../mixins/ErrorHandlerMixin";

import { Valuation } from "../../typings/valuation-details";
import { ChartBuilderOptions } from "../../typings/chart-builder-service";
import { PortfolioValuationChartContent } from "../../typings/portfolio-valuations";

@Component({
    components: {
        Chart
    }
})
export default class ValuationsPanel extends Mixins(ErrorHandlerMixin) {
    @Prop({ default: () => [] })
    public valuations!: Array<Valuation>;
    @Prop()
    public chartTitle?: string;
    @Prop()
    public chartColour?: string;
    @Prop()
    public chartInformation?: string;

    public chartOptions: Highcharts.Options = {};

    private getPortfolioValuationChartContent!: PortfolioValuationChartContent;

    @Watch("valuations")
    public valuationsChanged(): void {
        this.setChartOptions();
    }

    public get showChart(): boolean {
        return !_.isEmpty(this.chartOptions);
    }

    public mounted(): void {
        this.setChartOptions();
    }

    private setChartOptions(): void {
        const chartBuilder = new ChartBuilderService();

        const options = chartBuilder.getAreaChartOptions(
            this.valuations.map((value: Valuation) => {
                const valuationDate = new Date(value.date);
                return [
                    Date.UTC(valuationDate.getFullYear(), valuationDate.getMonth(), valuationDate.getDate()),
                    value.value
                ];
            }),
            {
                title: this.chartTitle,
                chartColour: this.chartColour
            } as ChartBuilderOptions
        );

        this.chartOptions = options;
    }
}
