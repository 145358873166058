import ContentfulService from "./ContentfulService";
import { ContentfulKeys } from "./contentful-keys";
import { CompanyInformation } from "@/typings/company-information";
import { ContentfulCompanyInformation, ContentfulGlobalContent, ContentfulPage } from "@/typings/contentful";
import { Asset, Entry, EntryCollection } from "contentful";
import { GlobalContent } from "@/typings/global-content";
import { Page } from "@/typings/page";

export default class GlobalContentfulService extends ContentfulService {
    public getCompanyInformation(): Promise<CompanyInformation> {
        return new Promise((resolve, reject) => {
            this.Contentful.getEntry<ContentfulCompanyInformation>(ContentfulKeys.CompanyInformation)
                .then((result: Entry<ContentfulCompanyInformation>) => {
                    // The URL of the image must be retrieved via a separate Contentful call
                    this.Contentful.getAsset(result.fields.companyLogo.sys.id)
                        .then((asset: Asset) => {
                            const companyInformation: CompanyInformation = {
                                companyName: result.fields.companyName,
                                companyRegistration: result.fields.companyRegistration,
                                logoUrl: asset.fields.file.url
                            };

                            resolve(companyInformation);
                        })
                        .catch((error: Error) => {
                            reject(error);
                        });
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    public getGlobalContent(): Promise<GlobalContent> {
        return new Promise((resolve, reject) => {
            this.Contentful.getEntry<ContentfulGlobalContent>(ContentfulKeys.Global)
                .then((result: Entry<ContentfulGlobalContent>) => {
                    this.Contentful.getAsset(result.fields.noviaBannerImage.sys.id)
                        .then((asset: Asset) => {
                            resolve({
                                strapline: result.fields.strapline,
                                noviaBannerUrl: asset.fields.file.url,
                                whitelabelledStrapline: result.fields.whitelabelledStrapline
                            });
                        })
                        .catch((error: Error) => {
                            reject(error);
                        });
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }

    public getAllNavigationPages(): Promise<Page[]> {
        return new Promise((resolve, reject) => {
            this.Contentful.getEntries<ContentfulPage>({
                content_type: "page",
                "fields.displayOnNavigation": true,
                order: "fields.navigationOrder"
            })
                .then((result: EntryCollection<ContentfulPage>) => {
                    resolve(
                        result.items.map((page: Entry<ContentfulPage>) => {
                            return {
                                title: page.fields.title,
                                key: page.fields.key
                            };
                        })
                    );
                })
                .catch((error: Error) => {
                    reject(error);
                });
        });
    }
}
