import { Commit } from "vuex";
import { ErrorPageContent } from "@/typings/error-page";
import { GET_ERROR_PAGE_CONTENT } from "../getters";
import { ErrorPageContentState } from "../typings/error-page"
import {
    SAVE_ERROR_PAGE_CONTENT,
    SET_ERROR_PAGE_LOADED
} from "../mutation-types";
import { LOAD_ERROR_PAGE_CONTENT } from "../action-types";
import ErrorPageContentfulService from "@/services/contentful/ErrorPageContentfulService";

const contentful = new ErrorPageContentfulService();

const state = (): ErrorPageContentState => ({
    content: {
        pageNotFoundHeading: "",
        pageNotFoundBody: "",
        forbiddenHeading: "",
        forbiddenBody: "",
        serverErrorHeading: "",
        serverErrorBody: ""
    },
    isDataLoaded: false
});

const getters = {
    
    [GET_ERROR_PAGE_CONTENT]: (state: ErrorPageContentState): ErrorPageContent => {
        return state.content;
    }
};

const mutations = {
    [SAVE_ERROR_PAGE_CONTENT](state: ErrorPageContentState, errorPageContent: ErrorPageContent) {
        state.content = errorPageContent;
    },
    [SET_ERROR_PAGE_LOADED](state: ErrorPageContentState, isLoaded: boolean) {
        state.isDataLoaded = isLoaded;
    }
};

const actions = {
    async [LOAD_ERROR_PAGE_CONTENT]({ commit, state }: { commit: Commit; state: ErrorPageContentState }) {
        if (!state.isDataLoaded) {
            contentful.getErrorPageContent().then((result: ErrorPageContent) => {
                commit(SAVE_ERROR_PAGE_CONTENT, result);
                commit(SET_ERROR_PAGE_LOADED, true);
            });
        }
    }
};

export const ErrorPageModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};

