
















import { Component, Mixins } from "vue-property-decorator";
import { BRow, BCol } from "bootstrap-vue";
import FluidContainer from "../FluidContainer.vue";
import { LOAD_GLOBAL_CONTENT } from "../../store/action-types";
import { Namespaces } from "../../store/namespaces";
import { GlobalContent } from "../../typings/global-content";
import { GET_GLOBAL_CONTENT } from "../../store/getters";
import ErrorHandlerMixin from "../../mixins/ErrorHandlerMixin";

@Component({
    components: {
        BRow,
        BCol,
        FluidContainer
    }
})
export default class SummaryBanner extends Mixins(ErrorHandlerMixin) {
    private get globalContent(): GlobalContent | null {
        return this.$store.getters[`${Namespaces.GlobalContent}/${GET_GLOBAL_CONTENT}`];
    }

    public get cssVariables(): Record<string, string> {
        if (this.globalContent) {
            return {
                "--banner-background-url": `url(${this.globalContent.noviaBannerUrl})`
            };
        }

        return {};
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.GlobalContent}/${LOAD_GLOBAL_CONTENT}`)
            .catch(error => this.handleError(error.response?.status));
    }
}
