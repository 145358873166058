import Vue from "vue";
import { AxiosError, AxiosResponse } from "axios";
import { StatusCodes } from "http-status-codes";

import { Wrapper } from "@/typings/wrapper";
import {
    AssetAllocationApiResult,
    AssetAllocationAsCsvApiResult,
    Interval,
    OffPlatformAssetsApiResult,
    StatementsApiResult,
    WrappersApiResult,
    DocumentsApiResult,
    DocumentDownloadResult,
    WrapperTransactionsApiResult,
    EtlInformation,
    WrapperTransactionsAsCsvApiResult,
    InvestorDetailsResult,
    WrapperStatementsApiResult,
    InvestorAccountStatusResult
} from "@/typings/api-service";
import { ValuationDetails } from "@/typings/valuation-details";

export default class ApiService {
    private assetAllocationUrl = "/AssetAllocation";
    private wrappersUrl = "/Wrappers";
    private offPlatformAssetsUrl = "/OffPlatformAssets";
    private statementsUrl = "/Statements";
    private valuationsUrl = "/Valuations";
    private documentsUrl = "/Documents";
    private transactionsUrl = "Transactions";
    private investorInformationUrl = "/InvestorInformation";
    private etlInformationUrl = "/EtlInformation";

    public getPortfolioAssetAllocations(): Promise<AssetAllocationApiResult> {
        return new Promise((resolve, reject) => {
            this.getAxiosResponse<AssetAllocationApiResult>(this.assetAllocationUrl)
                .then((response: AxiosResponse<AssetAllocationApiResult>) => {
                    if (response.status !== StatusCodes.OK) {
                        reject(this.getInvalidResponseCodeErrorMessage(response.status));
                    }

                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    public getWrapperAssetAllocations(wrapperId: number): Promise<AssetAllocationApiResult> {
        return new Promise((resolve, reject) => {
            this.getAxiosResponse<AssetAllocationApiResult>(this.assetAllocationUrl, wrapperId.toString())
                .then((response: AxiosResponse<AssetAllocationApiResult>) => {
                    if (response.status !== StatusCodes.OK) {
                        reject(this.getInvalidResponseCodeErrorMessage(response.status));
                    }

                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    public getWrapperAssetAllocationAsCsv(wrapperId: number): Promise<AssetAllocationAsCsvApiResult> {
        return new Promise((resolve, reject) => {
            this.getAxiosResponse<AssetAllocationAsCsvApiResult>(`${this.assetAllocationUrl}/${wrapperId}/download`)
                .then((response: AxiosResponse) => {
                    if (response.status !== StatusCodes.OK) {
                        reject(this.getInvalidResponseCodeErrorMessage(response.status));
                    }

                    // The Content-Disposition header contains the filename sent by the API
                    const headerval = response.request.getResponseHeader("Content-Disposition");

                    // But the Content-Disposition header contains other infomation, this is used to extract the filename
                    const filename = headerval
                        .split(";")[1]
                        .split("=")[1]
                        .replace('"', "")
                        .replace('"', "")
                        .concat(".csv");

                    const fileLink = window.document.createElement("a");
                    fileLink.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(response.data);
                    fileLink.setAttribute("download", filename);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                    fileLink.remove();
                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    public getAllWrappers(): Promise<WrappersApiResult> {
        return new Promise((resolve, reject) => {
            this.getAxiosResponse<WrappersApiResult>(this.wrappersUrl)
                .then((response: AxiosResponse<WrappersApiResult>) => {
                    if (response.status !== StatusCodes.OK) {
                        reject(this.getInvalidResponseCodeErrorMessage(response.status));
                    }

                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    public getWrapper(wrapperId: number): Promise<Wrapper> {
        return new Promise((resolve, reject) => {
            this.getAxiosResponse<Wrapper>(this.wrappersUrl, wrapperId?.toString())
                .then((response: AxiosResponse<Wrapper>) => {
                    if (response.status !== StatusCodes.OK) {
                        reject(this.getInvalidResponseCodeErrorMessage(response.status));
                    }

                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    public getOffPlatformAssets(): Promise<OffPlatformAssetsApiResult> {
        return new Promise((resolve, reject) => {
            this.getAxiosResponse<OffPlatformAssetsApiResult>(this.offPlatformAssetsUrl)
                .then((response: AxiosResponse<OffPlatformAssetsApiResult>) => {
                    if (response.status !== StatusCodes.OK) {
                        reject(this.getInvalidResponseCodeErrorMessage(response.status));
                    }

                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    public getPortfolioStatements(): Promise<Array<StatementsApiResult>> {
        return new Promise((resolve, reject) => {
            this.getAxiosResponse<Array<StatementsApiResult>>(this.statementsUrl)
                .then((response: AxiosResponse<Array<StatementsApiResult>>) => {
                    if (response.status !== StatusCodes.OK) {
                        reject(this.getInvalidResponseCodeErrorMessage(response.status));
                    }

                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    public getWrapperStatements(wrapperId: number, interval: Interval): Promise<WrapperStatementsApiResult> {
        return new Promise((resolve, reject) => {
            this.getAxiosResponse<WrapperStatementsApiResult>(`${this.statementsUrl}/${wrapperId}?interval=${interval}`)
                .then((response: AxiosResponse<WrapperStatementsApiResult>) => {
                    if (response.status !== StatusCodes.OK) {
                        reject(this.getInvalidResponseCodeErrorMessage(response.status));
                    }

                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    public getPortfolioValuation(interval: Interval): Promise<ValuationDetails> {
        return new Promise((resolve, reject) => {
            this.getAxiosResponse<ValuationDetails>(`${this.valuationsUrl}?interval=${interval}`)
                .then((response: AxiosResponse<ValuationDetails>) => {
                    if (response.status !== StatusCodes.OK) {
                        reject(this.getInvalidResponseCodeErrorMessage(response.status));
                    }

                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    public getWrapperValuation(wrapperId: number, interval: Interval): Promise<ValuationDetails> {
        return new Promise((resolve, reject) => {
            this.getAxiosResponse<ValuationDetails>(`${this.valuationsUrl}/${wrapperId}?interval=${interval}`)
                .then((response: AxiosResponse<ValuationDetails>) => {
                    if (response.status !== StatusCodes.OK) {
                        reject(this.getInvalidResponseCodeErrorMessage(response.status));
                    }

                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    public getAllDocuments(): Promise<DocumentsApiResult> {
        return new Promise((resolve, reject) => {
            this.getAxiosResponse<DocumentsApiResult>(this.documentsUrl)
                .then((response: AxiosResponse<DocumentsApiResult>) => {
                    if (response.status !== StatusCodes.OK) {
                        reject(this.getInvalidResponseCodeErrorMessage(response.status));
                    }

                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    public getWrapperTransactions(wrapperId: number, interval: Interval): Promise<WrapperTransactionsApiResult> {
        return new Promise((resolve, reject) => {
            this.getAxiosResponse<WrapperTransactionsApiResult>(
                `${this.wrappersUrl}/${wrapperId}/${this.transactionsUrl}?interval=${interval}`
            )
                .then((response: AxiosResponse<WrapperTransactionsApiResult>) => {
                    if (response.status !== StatusCodes.OK) {
                        reject(this.getInvalidResponseCodeErrorMessage(response.status));
                    }

                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    public getWrapperTransactionsAsCsv(
        wrapperId: number,
        interval: Interval
    ): Promise<WrapperTransactionsAsCsvApiResult> {
        return new Promise((resolve, reject) => {
            this.getAxiosResponse<WrapperTransactionsAsCsvApiResult>(
                `${this.wrappersUrl}/${wrapperId}/${this.transactionsUrl}/download?interval=${interval}`
            )
                .then((response: AxiosResponse) => {
                    if (response.status !== StatusCodes.OK) {
                        reject(this.getInvalidResponseCodeErrorMessage(response.status));
                    }

                    const filename = this.getFileNameFromResponse(response).concat(".csv");

                    this.doFileDownload("data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(response.data), filename);
                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    public downloadDocument(documentId: string): Promise<DocumentDownloadResult> {
        return new Promise((resolve, reject) => {
            this.getAxiosBlobResponse(this.documentsUrl, documentId)
                .then((response: AxiosResponse) => {
                    const filename = this.getFileNameFromResponse(response);

                    const fileURL = window.URL.createObjectURL(new Blob([response.data]));

                    this.doFileDownload(fileURL, filename);
                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    /*******************
    * Perform an HTTP GET operation
    *   uri: the uri you are calling.
    *   expected: the status codes you want to assert against.
    ********************/
    public httpGet<T>(uri: string, ...expected: StatusCodes[]): Promise<T> {
        return new Promise((resolve, reject) => {
            this.getAxiosResponse<T>(uri)
                .then((response: AxiosResponse<T>) => {
                    if (expected && expected.length > 0 && !expected.includes(response.status)) {
                        reject(this.getInvalidResponseCodeErrorMessage(response.status));
                    }

                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    public getInvestorAccountStatus(): Promise<InvestorAccountStatusResult> {
        return this.httpGet<InvestorAccountStatusResult>(`${this.investorInformationUrl}/AccountStatus`, StatusCodes.OK, StatusCodes.ACCEPTED);
    }

    public getInvestorDetails(): Promise<InvestorDetailsResult> {
        return this.httpGet<InvestorDetailsResult>(this.investorInformationUrl, StatusCodes.OK, StatusCodes.ACCEPTED);
    }

    public getEtlDate(): Promise<EtlInformation> {
        return new Promise((resolve, reject) => {
            this.getAxiosResponse<EtlInformation>(this.etlInformationUrl)
                .then((response: AxiosResponse<EtlInformation>) => {
                    if (response.status !== StatusCodes.OK) {
                        reject(this.getInvalidResponseCodeErrorMessage(response.status));
                    }

                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    reject(error);
                });
        });
    }

    private getApiUrl(baseUrl: string, id?: string) {
        if (id) {
            return `${baseUrl}/${id}`;
        }

        return baseUrl;
    }

    private getAxiosResponse<TResult>(baseUrl: string, id?: string): Promise<AxiosResponse<TResult>> {
        const url = this.getApiUrl(baseUrl, id);

        return Vue.prototype.$http.get(url);
    }

    private getAxiosBlobResponse<TResult>(baseUrl: string, id?: string): Promise<AxiosResponse<TResult>> {
        const url = this.getApiUrl(baseUrl, id);

        return Vue.prototype.$http.get(url, {
            responseType: "blob",
            timeout: 30000
        });
    }

    private getInvalidResponseCodeErrorMessage(statusCode: number): Error {
        return new Error(`Expected response code 200, but the response code was ${statusCode}`);
    }

    private getFileNameFromResponse(response: AxiosResponse): string {
        // The Content-Disposition header contains the filename sent by the API
        const headerval = response.request.getResponseHeader("Content-Disposition");

        // But the Content-Disposition header contains other infomation, this is used to extract the filename
        return headerval
            .split(";")[1]
            .split("=")[1]
            .replace('"', "")
            .replace('"', "");
    }

    private doFileDownload(href: string, filename: string): void {
        const fileLink = document.createElement("a");

        fileLink.href = href;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);

        fileLink.click();
        fileLink.remove();
    }
}
