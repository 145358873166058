import { Commit } from "vuex";
import InformationContentfulService from "@/services/contentful/InformationContentfulService";
import { LOAD_INFORMATION_PAGE_CONTENT } from "../action-types";
import { SAVE_INFORMATION_PAGE_CONTENT, SET_INFORMATION_PAGE_LOADED } from "../mutation-types";

import { InformationPage } from "@/typings/information-page";
import { GET_INFORMATION_PAGE_CONTENT } from "../getters";

const contentful = new InformationContentfulService();

const state = (): InformationPageState => ({
    informationPage: {
        title: "",
        body: {}
    },
    isLoaded: false
});

const getters = {
    [GET_INFORMATION_PAGE_CONTENT]: (state: InformationPageState) => {
        return state.informationPage;
    }
};

const mutations = {
    [SAVE_INFORMATION_PAGE_CONTENT](state: InformationPageState, infoPage: InformationPage) {
        state.informationPage = infoPage;
    },
    [SET_INFORMATION_PAGE_LOADED](state: InformationPageState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    async [LOAD_INFORMATION_PAGE_CONTENT]({ commit, state }: { commit: Commit; state: InformationPageState }) {
        if (!state.isLoaded) {
            contentful.getInformationPageContent().then((result: InformationPage) => {
                commit(SAVE_INFORMATION_PAGE_CONTENT, result);
                commit(SET_INFORMATION_PAGE_LOADED, true);
            });
        }
    }
};

export const InformationPageModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
