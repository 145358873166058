


















import router from "@/router";
import { Component, Vue } from "vue-property-decorator";
import { IS_WHITELABELED } from "@/store/getters";
import { Namespaces } from "@/store/namespaces";
import store from "@/store"

@Component({
    components: {}
})
export default class Logout extends Vue {
    public logout() {
        router.push({ name: "Logout" });
    }
    
    public get isWhitelabeled(): number | boolean {
        return store.getters[`${Namespaces.CompanyInformation}/${IS_WHITELABELED}`];
    }
}
