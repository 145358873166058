











import { Component, Prop, Vue } from "vue-property-decorator";
import { BNavbarToggle } from "bootstrap-vue";

@Component({
    components: {
        BNavbarToggle
    }
})
export default class NavBarHeader extends Vue {
    @Prop({ default: "#" })
    navbarCollapseId!: string;

    navbarToggleClicked(): void {
        this.$emit("closeNavbarClicked");
    }
}
