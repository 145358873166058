import { Commit } from "vuex";
import WrapperContentfulService from "@/services/contentful/WrapperContentfulService";
import { LOAD_WRAPPER_DETAILS_CONTENT } from "../action-types";
import { SAVE_WRAPPER_DETAILS_CONTENT, SET_WRAPPER_DETAILS_LOADED } from "../mutation-types";

import { WrapperPageContentHeadings, WrapperPageHeadings, WrapperTableHeadings } from "@/typings/wrapper-page";
import { WrapperDetailsState } from "../typings/wrapper-page";
import { GET_WRAPPER_DETAILS_CONTENT, GET_WRAPPER_TABLE_HEADINGS } from "../getters";

const contentful = new WrapperContentfulService();

const state = (): WrapperDetailsState => ({
    wrapperPageHeadings: {
        wrapperDetailsHeader: "",
        wrapperValueTitle: "",
        modelPortfolioTitle: "",
        sectorTableHeading: "", 
        assetNameTableHeading: "", 
        assetValueTableHeading: "", 
        percentageTableHeading: "",
        wrapperStatementButton: ""
    },
    isLoaded: false
});

const getters = {
    [GET_WRAPPER_DETAILS_CONTENT]: (state: WrapperDetailsState): WrapperPageContentHeadings => {
        return {
            wrapperDetailsHeader: state.wrapperPageHeadings.wrapperDetailsHeader,
            wrapperValueTitle: state.wrapperPageHeadings.wrapperValueTitle,
            modelPortfolioTitle: state.wrapperPageHeadings.modelPortfolioTitle,
            wrapperStatementButton: state.wrapperPageHeadings.wrapperStatementButton
        };
    },
    [GET_WRAPPER_TABLE_HEADINGS]: (state: WrapperDetailsState): WrapperTableHeadings => {
        return {
            sectorTableHeading: state.wrapperPageHeadings.sectorTableHeading,
            assetNameTableHeading: state.wrapperPageHeadings.assetNameTableHeading,
            assetValueTableHeading: state.wrapperPageHeadings.assetValueTableHeading,
            percentageTableHeading: state.wrapperPageHeadings.percentageTableHeading
        };
    }
};

const mutations = {
    [SAVE_WRAPPER_DETAILS_CONTENT](state: WrapperDetailsState, model: WrapperPageHeadings) {
        state.wrapperPageHeadings = model;
    },
    [SET_WRAPPER_DETAILS_LOADED](state: WrapperDetailsState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    [LOAD_WRAPPER_DETAILS_CONTENT]({ commit, state }: { commit: Commit; state: WrapperDetailsState }) {
        if (!state.isLoaded) {
            return contentful.getwrapperDetailsContent().then((result: WrapperPageHeadings) => {
                commit(SAVE_WRAPPER_DETAILS_CONTENT, result);
                commit(SET_WRAPPER_DETAILS_LOADED, true);
            });
        }
    }
};

export const WrapperDetailsModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
