import { Component, Vue } from "vue-property-decorator";

@Component
export default class RouteMixin extends Vue {
    public get idFromRoute(): number | null {
        if (this.$route?.params?.id) {
            return Number(this.$route.params.id);
        }

        return null;
    }
}
