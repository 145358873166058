











import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { BCollapse, BNavbarNav } from "bootstrap-vue";
import NavBarHeader from "./NavBarHeader.vue";
import WelcomeMessage from "../WelcomeMessage.vue";

@Component({
    components: {
        BCollapse,
        BNavbarNav,
        NavBarHeader,
        WelcomeMessage
    }
})
export default class NavBarCollapse extends Vue {
    @Prop()
    id?: string;
    isCollapseOpen = false;

    @Watch("$route")
    routeChanged() {
        // In responsive mode the navbar would not close automatically. We've opted to deal with this manually and emit the toggle navbar Bootstrap
        // event when the route changes
        if (this.isCollapseOpen) {
            this.$root.$emit("bv::toggle::collapse", "nav-collapse");
            this.closeNavbarClicked();
        }
    }

    closeNavbarClicked(): void {
        this.$emit("closeNavbarClicked");
    }
}
