








import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {}
})
export default class NavBarButton extends Vue {
    @Prop({ default: "#" })
    href!: string;
}
