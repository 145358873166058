import Vue from "vue";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export default class AxiosFactory {
    public getAxiosClient(baseUrl: string): AxiosInstance {
        const axiosClient = axios.create({
            baseURL: baseUrl
        });
        axiosClient.interceptors.request.use(async (config: AxiosRequestConfig) => {
            const user = await Vue.prototype.$auth.getUser();

            if (user && !user.expired) {
                config.headers.Authorization = `Bearer ${user.access_token}`;
            }

            return config;
        });

        return axiosClient;
    }
}
