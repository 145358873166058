
















































































import { Component, Mixins } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { LOAD_MONEY_IN_OUT_CONTENT, LOAD_PORTFOLIO_DETAILS } from "../store/action-types";
import { GET_MONEY_IN_OUT_CONTENT, GET_MONEY_IN_OUT_TABLE_HEADINGS, GET_PORTFOLIO_DETAILS } from "../store/getters";
import { Namespaces } from "../store/namespaces";
import _ from "lodash";

import { BRow, BCol, BTable, BPagination, BCard, BCardHeader, BButton, BCollapse } from "bootstrap-vue";
import SummaryBanner from "../components/summary-banner/SummaryBanner.vue";
import SummaryBannerHeader from "../components/summary-banner/SummaryBannerHeader.vue";
import SummaryBannerValue from "../components/summary-banner/SummaryBannerValue.vue";
import SummaryBannerContainer from "../components/summary-banner/SummaryBannerContainer.vue";
import Skeleton from "../components/Skeleton.vue";
import FluidContainer from "../components/FluidContainer.vue";
import AuthMixin from "../mixins/AuthMixin";
import StatementsMixin from "../mixins/StatementsMixin";
import NumberMixin from "../mixins/NumberMixin";

import { EtlInformation, StatementGroup, StatementsApiResult } from "../typings/api-service";
import { MoneyInOutPageContentHeadings, MoneyInOutTableHeadings } from "../typings/moneyinout-page";
import { TableField } from "../typings/table";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";
import SummaryBannerMixin from "../mixins/SummaryBannerMixin";
import PortfolioSummaryBanner from "../components/PortfolioSummaryBanner.vue";
import { Portfolio } from "../typings/portfolio";
import ReactivePageTitle from "@/components/ReactivePageTitle.vue";

@Component({
    components: {
        BRow,
        BCol,
        BTable,
        BPagination,
        BCard,
        BCardHeader,
        BButton,
        BCollapse,
        SummaryBanner,
        SummaryBannerHeader,
        SummaryBannerValue,
        SummaryBannerContainer,
        Skeleton,
        FluidContainer,
        PortfolioSummaryBanner,
        ReactivePageTitle,
    },
    computed: mapGetters({
        getMoneyInOutPageHeaders: `${Namespaces.MoneyInOut}/${GET_MONEY_IN_OUT_CONTENT}`,
        getMoneyInOutTableHeaders: `${Namespaces.MoneyInOut}/${GET_MONEY_IN_OUT_TABLE_HEADINGS}`,
        getPortfolioDetails: `${Namespaces.Portfolio}/${GET_PORTFOLIO_DETAILS}`,
    }),
})
export default class MoneyInOutPage extends Mixins(
    AuthMixin,
    NumberMixin,
    StatementsMixin,
    ErrorHandlerMixin,
    SummaryBannerMixin
) {
    private getMoneyInOutPageHeaders!: MoneyInOutPageContentHeadings;
    private getMoneyInOutTableHeaders!: MoneyInOutTableHeadings;
    private etlInformation: EtlInformation = {} as EtlInformation;
    public getPortfolioDetails!: Portfolio;

    private statements: Array<StatementsApiResult> = [];
    private perPage = 10;
    private currentPageArray = {};
    private dataLoaded = false;
    private excludeGroupNames = ["Novia Charges", "Adviser Charges", "DFM Charges"];

    public get showSkeleton(): boolean {
        return !this.dataLoaded || _.isEmpty(this.etlInformation);
    }

    public get totalValueContent(): string {
        const totalValue = this.statements.length > 0 ? _.sum(this.statements.map((value) => value.wrapperValue)) : 0;
        return this.getTotalValueContent(this.getMoneyInOutPageHeaders.totalValueTitle, totalValue);
    }

    public get asAtDateContent(): string {
        return this.getMoneyInOutPageHeaders.asAtDate.replace("#DATE#", this.etlInformation.etlDateMessage);
    }

    public getWrapperValueContent(wrapperValue: number): any {
        return {
            label: this.getMoneyInOutPageHeaders.wrapperValue,
            value: this.getMoneyValueString(wrapperValue),
        };
    }

    public getFormattedWrapperValue(wrapperValue: number): string {
        return this.getMoneyValueString(wrapperValue);
    }

    public get tableFields(): Array<TableField> {
        return [
            {
                key: "transaction",
                label: this.getMoneyInOutTableHeaders.transactionNameTableHeading,
            },
            {
                key: "date",
                label: this.getMoneyInOutTableHeaders.dateTableHeading,
            },
            {
                key: "value",
                label: this.getMoneyInOutTableHeaders.valueTableHeading,
                thClass: "align-right",
                tdClass: "align-right",
            },
        ];
    }

    public getGroupHeading(group: StatementGroup): string {
        return `${group.name}: ${this.getMoneyValueString(group.value)}`;
    }

    public async mounted(): Promise<void> {
        this.getAuthenticatedUser().then(() => {
            Promise.all([this.$api.getPortfolioStatements(), this.$api.getEtlDate()])
                .then(([portfolioStatements, etlInformation]) => {
                    const filteredResult = this.getFilteredGroupNameApiResult(portfolioStatements);
                    this.sortGroupsInApiResultByName(filteredResult);
                    this.setCurrentPagesArrayFromApiResult(filteredResult);
                    this.statements = filteredResult.sort(
                        (a, b) => a.wrapperName.localeCompare(b.wrapperName) || a.wrapperId - b.wrapperId
                    );

                    this.etlInformation = etlInformation;
                    this.dataLoaded = true;
                })
                .catch((error) => this.handleError(error.response?.status));
        });
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.MoneyInOut}/${LOAD_MONEY_IN_OUT_CONTENT}`)
            .catch((error) => this.handleError(error.response?.status));
        this.$store
            .dispatch(`${Namespaces.Portfolio}/${LOAD_PORTFOLIO_DETAILS}`)
            .catch((error) => this.handleError(error.response?.status));
    }

    private getFilteredGroupNameApiResult(result: Array<StatementsApiResult>) {
        // For every statement that's returned ensure that we filter out the groups that are to be excluded in the excludeGroupNames
        // filter
        return result.map((statement: StatementsApiResult) => {
            return {
                ...statement,
                statementGroup: statement.statementGroup.filter((group: StatementGroup) => {
                    return !this.excludeGroupNames.includes(group.name);
                }),
            } as StatementsApiResult;
        });
    }

    private sortGroupsInApiResultByName(result: Array<StatementsApiResult>) {
        const moneyInGroupName = "Money In";
        result.forEach((value: StatementsApiResult) => {
            value.statementGroup.sort((a: StatementGroup, b: StatementGroup) => {
                if (a.name === moneyInGroupName) {
                    return -1;
                } else if (b.name === moneyInGroupName) {
                    return 1;
                }

                return 0;
            });
        });
    }

    private setCurrentPagesArrayFromApiResult(result: Array<StatementsApiResult>): void {
        result.forEach((value) => {
            value.statementGroup.forEach((group) => {
                this.currentPageArray = {
                    ...this.currentPageArray,
                    [`${value.wrapperId}+${group.name}`]: 0,
                };
            });
        });
    }
}
