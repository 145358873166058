


















import SummaryBanner from "../components/summary-banner/SummaryBanner.vue";
import SummaryBannerContainer from "../components/summary-banner/SummaryBannerContainer.vue";
import Skeleton from "../components/Skeleton.vue";
import SummaryBannerHeader from "../components/summary-banner/SummaryBannerHeader.vue";
import SummaryBannerValue from "../components/summary-banner/SummaryBannerValue.vue";
import WrapperDetailsBannerActionBar from "../components/wrapper-details/WrapperDetailsBannerActionBar.vue";
import { Component, Mixins, Prop } from "vue-property-decorator";

import { WrapperPortfolioSummaryBannerLabeling } from "../typings/wrapper-portfolio-summary-banner-module";

import SummaryBannerMixin from "../mixins/SummaryBannerMixin";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";

import { mapGetters } from "vuex";
import { Actions } from "@/store/action-types";
import { Namespaces } from "../store/namespaces";
import { OffPlatformAssetsSummary } from "../typings/off-platform-assets-summary";
import { GET_WRAPPER_PORTFOLIO_BANNER_LABELS } from "@/store/getters";

@Component({
    components: {
        Skeleton,
        SummaryBanner,
        SummaryBannerHeader,
        SummaryBannerValue,
        SummaryBannerContainer,
        WrapperDetailsBannerActionBar,
    },
    computed: mapGetters({
        bannerLabels: `${Namespaces.WrapperPortfolioSummaryBanner}/${GET_WRAPPER_PORTFOLIO_BANNER_LABELS}`,
    }),
})
export default class OffPlatformAssetsSummaryBanner extends Mixins(ErrorHandlerMixin, SummaryBannerMixin) {
    @Prop()
    public offPlatformAssets?: OffPlatformAssetsSummary | null;

    @Prop()
    public showSkeleton!: boolean;

    private bannerLabels!: WrapperPortfolioSummaryBannerLabeling;

    public get offPlatformAssetsValue(): any {
        return {
            label: this.bannerLabels.wrapperSummaryTotalValueLabel,
            value: `${this.numberToCommaSeparatedString((this.offPlatformAssets?.totalValue ?? 0).toFixed(2))}`,
        };
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.WrapperPortfolioSummaryBanner}/${Actions.LOAD_CONTENT}`)
            .catch((error) => this.handleError(error.response?.status));
    }
}
