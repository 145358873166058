















































import { Component, Mixins, Prop } from "vue-property-decorator";
import { BRow, BCol } from "bootstrap-vue";
import FluidContainer from "./FluidContainer.vue";
import Logo from "./Logo.vue";
import Strapline from "./Strapline.vue";
import WelcomeMessage from "./WelcomeMessage.vue";
import NavBarToggle from "./navigation/NavBarToggle.vue";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";
import Skeleton from "../components/Skeleton.vue";
import Logout from "../components/Logout.vue";
import { IS_WHITELABELED } from "@/store/getters";
import { Namespaces } from "@/store/namespaces";
import store from "@/store"

@Component({
    components: {
        BRow,
        BCol,
        FluidContainer,
        Logo,
        Strapline,
        WelcomeMessage,
        NavBarToggle,
        Skeleton,
        Logout
    }
})
export default class InvestorZoneHeader extends Mixins(ErrorHandlerMixin) {
    @Prop()
    public navbarCollapseId?: string;

    @Prop({ default: true })
    public displayWelcomeMessage!: boolean;

    @Prop({ default: true })
    public displayMobileNavigationToggle!: boolean;

    @Prop({ default: true })
    public displayLogout!: boolean;

    public navbarToggleClicked(): void {
        this.$emit("showNavbarClicked");
    }

    public get isWhitelabeled(): number | boolean {
        return store.getters[`${Namespaces.CompanyInformation}/${IS_WHITELABELED}`];
    }
}
