import { Component, Mixins } from "vue-property-decorator";
import moment, { Moment } from "moment";
import NumberMixin from "./NumberMixin";

import { Interval, Statement, StatementGroup, StatementSummary, WrapperStatementGroup } from "@/typings/api-service";
import { StatementTableData, WrapperStatementTableData } from "@/typings/wrapper-statement";

@Component
export default class StatementsMixin extends Mixins(NumberMixin) {
    public getStatementPeriodWithDates(interval: Interval, statementPeriodText: string): string {
        const calculatedDate: Moment =
            interval === Interval.SixMonths ? moment().subtract(6, "months") : moment().subtract(12, "months");

        return statementPeriodText
            .replace("#START#", calculatedDate.format("DD/MM/YYYY"))
            .replace("#END#", moment().format("DD/MM/YYYY"));
    }

    public getTableRowsFromWrapperStatementGroup(group: StatementGroup): Array<StatementTableData> {
        return group.statements.map((statement: Statement) => ({
            transaction: statement.transactionName,
            date: moment(statement.date).format("DD/MM/YYYY"),
            value: `${this.numberToCommaSeparatedString(statement.value.toFixed(2))}`
        }));
    }

    public getCondensedTableRowsByNameFromWrapperStatementGroup(group: WrapperStatementGroup): Array<WrapperStatementTableData> {
        const resultArray: StatementSummary[] = [];
        group.statements.forEach(element => {
            const statementShell = Object.assign({}, element);
            const elementExistsInResultArray = resultArray.some(i => i.transactionName.includes(statementShell.transactionName))
            if(!elementExistsInResultArray){
                resultArray.push(statementShell);
            } else  {
                resultArray.find(i => i.transactionName == statementShell.transactionName)!.value += statementShell.value
            }
        });
        
        return resultArray.map((statement: StatementSummary) => ({
            transaction: statement.transactionName,
            value: `${this.numberToCommaSeparatedString(statement.value.toFixed(2))}`
        }));
    }

    public getWrapperSummaryTableRowsFromWrapperStatementGroup(group: WrapperStatementGroup): Array<WrapperStatementTableData> {
        return group.statements.map((statement: StatementSummary) => ({
            transaction: statement.transactionName,
            value: `${this.numberToCommaSeparatedString(statement.value.toFixed(2))}`
        }));
    }
}
