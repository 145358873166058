import { Wrapper } from "@/typings/wrapper";
import { AssetAllocation } from "./asset-allocation";
import { InvestorDocument } from "./investor-document";
import { OffPlatformAssets } from "./off-platform-assets";
import { ValuationDetails } from "./valuation-details";
import { WrapperTransaction } from "./wrapper-transaction";

export interface ApiService {
    getPortfolioAssetAllocations(): Promise<AssetAllocationApiResult>;
    getWrapperAssetAllocations(wrapperId: number): Promise<AssetAllocationApiResult>;
    getAllWrappers(): Promise<WrappersApiResult>;
    getWrapper(wrapperId: number): Promise<Wrapper>;
    getOffPlatformAssets(): Promise<OffPlatformAssetsApiResult>;
    getPortfolioStatements(): Promise<Array<StatementsApiResult>>;
    getWrapperStatements(wrapperId: number, interval: Interval): Promise<StatementsApiResult>;
    getPortfolioValuation(interval: Interval): Promise<ValuationDetails>;
    getWrapperValuation(wrapperId: number, interval: Interval): Promise<ValuationDetails>;
    getWrapperAssetAllocationAsCsv(wrapperId: number): Promise<AssetAllocationAsCsvApiResult>;
    getWrapperTransactions(wrapperId: number, interval: Interval): Promise<WrapperTransactionsApiResult>;
    getWrapperTransactionsAsCsv(wrapperId: number, interval: Interval): Promise<WrapperTransactionsAsCsvApiResult>;
    getAllDocuments(): Promise<DocumentsApiResult>;
    downloadDocument(documentId: string): Promise<DocumentDownloadResult>;
    getEtlDate(): Promise<EtlInformation>;
    getInvestorDetails(): Promise<InvestorDetailsResult>;
    getInvestorAccountStatus(): Promise<InvestorAccountStatusResult>;
}

export interface AssetAllocationApiResult {
    assetAllocation: Array<AssetAllocation>;
}

export interface AssetAllocationAsCsvApiResult {
    data: string;
}

export interface WrapperGroup {
    name: string;
    consolidatedValue: number;
    wrappers: Array<Wrapper>;
}

export interface InvestorWrappersModel {
    wrapperGroups: Array<WrapperGroup>;
}

export interface RelatedInvestorWrappersModel {
    investorName: string;
    wrapperGroups: Array<WrapperGroup>;
}

export interface WrappersApiResult {
    investorWrappersModel: InvestorWrappersModel;
    relatedInvestorsWrappersModels: Array<RelatedInvestorWrappersModel>;
}

export interface OffPlatformAssetsApiResult {
    shortCode: string;
    name: string;
    value: number;
    offPlatformAssets: Array<OffPlatformAssets>;
}

export interface StatementsApiResult {
    wrapperName: string;
    wrapperId: number;
    wrapperValue: number;
    statementGroup: Array<StatementGroup>;
}

export interface StatementGroup {
    name: string;
    value: number;
    statements: Array<Statement>;
}

export interface Statement {
    transactionName: string;
    date: string;
    value: number;
}

export interface WrapperStatementsApiResult {
    wrapperName: string;
    wrapperId: number;
    wrapperValue: number;
    statementGroup: Array<WrapperStatementGroup>;
}

export interface WrapperStatementGroup {
    name: string;
    value: number;
    statements: Array<StatementSummary>;
}

export interface StatementSummary {
    transactionName: string;
    value: number;
}

export interface RelatedInvestorDocuments {
    firstName: string;
    lastName: string;
    documents: Array<InvestorDocument>;
}

export interface DocumentsApiResult {
    documents: Array<InvestorDocument>;
    relatedInvestorDocuments: Array<RelatedInvestorDocuments>;
}

export interface DocumentDownloadResult {
    data: string;
}

export enum Interval {
    SixMonths = 6,
    TwelveMonths = 12
}

export interface WrapperTransactionsApiResult {
    wrapperId: number;
    transactions: Array<WrapperTransaction>;
}

export interface WrapperTransactionsAsCsvApiResult {
    data: string;
}

export interface InvestorDetailsResult {
    id: number;
    branchId: number;
    branchName: string;
    investorName: string;
    hasStocktradeAccount: boolean;
    existsInWarehouse: boolean;
}

export interface EtlInformation {
    logonAllowed: string;
    etlDateMessage: string;
}

export interface InvestorAccountStatusResult {
    accountStatus: string;
    reason: string;
}