

























import SummaryBanner from "./summary-banner/SummaryBanner.vue";
import SummaryBannerContainer from "./summary-banner/SummaryBannerContainer.vue";
import Skeleton from "./Skeleton.vue";
import SummaryBannerHeader from "./summary-banner/SummaryBannerHeader.vue";
import SummaryBannerValue from "./summary-banner/SummaryBannerValue.vue";
import WrapperDetailsBannerActionBar from "./wrapper-details/WrapperDetailsBannerActionBar.vue";
import { Component, Mixins, Prop } from "vue-property-decorator";

import { WrapperPortfolioSummaryBannerLabeling } from "../typings/wrapper-portfolio-summary-banner-module";
import { Wrapper } from "../typings/wrapper";

import SummaryBannerMixin from "../mixins/SummaryBannerMixin";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";

import { mapGetters } from "vuex";
import { Actions } from "@/store/action-types";
import { Namespaces } from "../store/namespaces";
import { RelatedInvestor } from "@/typings/related-investor";
import _ from "lodash";
import { GET_RELATED_INVESTOR_BY_WRAPPER_ID, GET_WRAPPER_PORTFOLIO_BANNER_LABELS } from "@/store/getters";

@Component({
    components: {
        Skeleton,
        SummaryBanner,
        SummaryBannerHeader,
        SummaryBannerValue,
        SummaryBannerContainer,
        WrapperDetailsBannerActionBar,
    },
    computed: mapGetters({
        // map to wrapper-portfolio-branner-module
        bannerLabels: `${Namespaces.WrapperPortfolioSummaryBanner}/${GET_WRAPPER_PORTFOLIO_BANNER_LABELS}`,
    }),
})
export default class WrapperPortfolioSummaryBanner extends Mixins(ErrorHandlerMixin, SummaryBannerMixin) {
    @Prop()
    public wrapper?: Wrapper | null;

    @Prop()
    public showSkeleton!: boolean;

    private bannerLabels!: WrapperPortfolioSummaryBannerLabeling;

    public get wrapperSummaryHeader(): string {
        if (this.wrapper) {
            const relatedInvestor: RelatedInvestor = this.$store.getters[
                `${Namespaces.Portfolio}/${GET_RELATED_INVESTOR_BY_WRAPPER_ID}`
            ](this.wrapper.id);
            const relatedInvestorHeader = (relatedInvestor) ? `${relatedInvestor.investorName} - ` : "";
            return `${relatedInvestorHeader}${this.wrapper.name}`
        }
        return "";
    }

    public get hasLinkedPortfolio(): boolean {
        return this.bannerLabels?.wrapperSummaryModelPortfolioLabel != null && this.wrapper?.modelPortfolioName != null;
    }

    public get modelPortfolioSummary(): string {
        return `${this.bannerLabels.wrapperSummaryModelPortfolioLabel} ${this.wrapper?.modelPortfolioName}`;
    }

    public get showWrapperSummary(): boolean {
        return !_.isEmpty(this.bannerLabels?.wrapperSummaryTotalValueLabel) && this.wrapper?.value != null;
    }

    public get wrapperSummary(): any {
        return {
            label: this.bannerLabels?.wrapperSummaryTotalValueLabel,
            value: `${this.numberToCommaSeparatedString((this.wrapper?.value ?? 0).toFixed(2))}`,
        };
    }
    
    public created(): void {
        this.$store
            .dispatch(`${Namespaces.WrapperPortfolioSummaryBanner}/${Actions.LOAD_CONTENT}`)
            .catch((error) => this.handleError(error.response?.status));
    }
}
