import { Commit } from "vuex";
import {
    SAVE_COMPANY_INFORMATION,
    SAVE_COMPANY_WHITELABELLING,
    SET_COMPANY_INFORMATION_LOADED,
    SET_COMPANY_WHITELABELLING_BRANCHID,
    SET_COMPANY_WHITELABELLING_DEFAULT,
    SET_COMPANY_WHITELABELLING_LOADED
} from "../mutation-types";
import { LOAD_COMPANY_INFORMATION } from "../action-types";
import { GET_COMPANY_INFORMATION, GET_WHITELABELLING_CONTENT, IS_WHITELABELED } from "../getters";

import { CompanyInformation } from "@/typings/company-information";
import GlobalContentfulService from "@/services/contentful/GlobalContentfulService";
import { InvestorDetailsResult } from "@/typings/api-service";
import WhiteLabellingContentfulService from "@/services/whitelabelling/WhiteLabellingContentfulService";
import { WhiteLabellingDetails } from "@/typings/whitelabelling-details";
import { CompanyInformationState } from "../typings/company-information";

const contentful = new GlobalContentfulService();
const whitelabellingService = new WhiteLabellingContentfulService();

const state = (): CompanyInformationState => ({
    companyInformation: {
        companyName: "",
        logoUrl: "",
        companyRegistration: ""
    },
    isLoaded: false,
    isWhiteLabelLoaded: false,
    whiteLabelling: {
        branchId: 0,
        enabled: false,
        branchName: "",
        logoUrl: ""
    }
});

const getters = {
    [GET_COMPANY_INFORMATION]: (state: CompanyInformationState): CompanyInformation | null => {
        return state.companyInformation;
    },
    [GET_WHITELABELLING_CONTENT]: (state: CompanyInformationState): WhiteLabellingDetails | null => {
        return state.whiteLabelling;
    },
    [IS_WHITELABELED]: (state: CompanyInformationState): number | boolean => {
        if ((state.whiteLabelling?.branchId ?? 0 > 0) && state.whiteLabelling?.enabled) {
            return true
        }

        return false;
    }
};

const mutations = {
    [SAVE_COMPANY_INFORMATION](state: CompanyInformationState, companyInformation: CompanyInformation) {
        state.companyInformation = companyInformation;
    },
    [SET_COMPANY_INFORMATION_LOADED](state: CompanyInformationState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    },
    [SAVE_COMPANY_WHITELABELLING](state: CompanyInformationState, whitelabellingDetails: WhiteLabellingDetails) {
        state.whiteLabelling.logoUrl = `https:${whitelabellingDetails.logoUrl}`;
        state.whiteLabelling.enabled = whitelabellingDetails.enabled;
    },
    [SET_COMPANY_WHITELABELLING_BRANCHID](state: CompanyInformationState, apiDetails: InvestorDetailsResult) {
        state.whiteLabelling.branchId = apiDetails.branchId;
        state.whiteLabelling.branchName = apiDetails.branchName;
    },
    [SET_COMPANY_WHITELABELLING_LOADED](state: CompanyInformationState, isWhiteLabelLoaded: boolean) {
        state.isWhiteLabelLoaded = isWhiteLabelLoaded;
    },
    [SET_COMPANY_WHITELABELLING_DEFAULT](state: CompanyInformationState) {
        state.isWhiteLabelLoaded = false;
        state.whiteLabelling.enabled = false;
    }
};

const actions = {
    async [LOAD_COMPANY_INFORMATION]({ commit, state }: { commit: Commit; state: CompanyInformationState; }, branchId: number) {
        if (!state.isLoaded) {
            contentful.getCompanyInformation().then((result: CompanyInformation) => {
                commit(SAVE_COMPANY_INFORMATION, {
                    ...result,
                    logoUrl: `https:${result.logoUrl}`
                });
                commit(SET_COMPANY_INFORMATION_LOADED, true);
            });
        }

        if (!state.isWhiteLabelLoaded) {
            whitelabellingService
                .getBranchWhiteLabelledInformation(branchId)
                .then((wlResult: WhiteLabellingDetails) => {
                    commit(SAVE_COMPANY_WHITELABELLING, {
                        ...wlResult
                    });
                    commit(SET_COMPANY_WHITELABELLING_BRANCHID, {
                        branchId
                    });
                    commit(SET_COMPANY_WHITELABELLING_LOADED, true);
                })
                .catch(() => {
                    commit(SET_COMPANY_WHITELABELLING_DEFAULT);
                });

            commit(SET_COMPANY_WHITELABELLING_DEFAULT);
        }
    }
};

export const CompanyInformationModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
