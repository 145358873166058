declare const window: any;

// default values for when running local unit tests - otherwise env.js is not loaded
window["env"] = window["env"] || {
    VUE_APP_WHITELABELLING_CONTENTFUL_SPACE: process.env.VUE_APP_WHITELABELLING_CONTENTFUL_SPACE,
    VUE_APP_WHITELABELLING_CONTENTFUL_ACCESS_TOKEN: process.env.VUE_APP_WHITELABELLING_CONTENTFUL_ACCESS_TOKEN
};

export const environment = {
    bootstrapCdnUrl: window["env"].VUE_APP_BOOTSTRAP_CDN_URL,
    materialKitCdnUrl: window["env"].VUE_APP_MATERIAL_KIT_CDN_URL,
    noviaCoreCdnUrl: window["env"].VUE_APP_NOVIA_CORE_CDN_URL,
    googleTagManagerContainerId: window["env"].VUE_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID,
    googleTagManagerEnvAuth: window["env"].VUE_APP_GOOGLE_TAG_MANAGER_ENV_AUTH,
    googleTagManagerEnvPreview: window["env"].VUE_APP_GOOGLE_TAG_MANAGER_ENV_PREVIEW,
    googleTagManagerEnvCookies: window["env"].VUE_APP_GOOGLE_TAG_MANAGER_ENV_COOKIES,
    contentfulSpace: window["env"].VUE_APP_CONTENTFUL_SPACE,
    contentfulAccessToken: window["env"].VUE_APP_CONTENTFUL_ACCESS_TOKEN,
    whitelabellingContentfulSpace: window["env"].VUE_APP_WHITELABELLING_CONTENTFUL_SPACE,
    whitelabellingContentfulAccessToken: window["env"].VUE_APP_WHITELABELLING_CONTENTFUL_ACCESS_TOKEN,
    stocktradeUrl: window["env"].VUE_APP_STOCKTRADE_URL,
    maxIdleTimeInMinutes: window["env"].VUE_APP_MAX_IDLE_TIME_IN_MINUTES,
    idleTimeoutWarningInSeconds: window["env"].VUE_APP_IDLE_TIMEOUT_WARNING_IN_SECONDS,
    datadogApplicationId: window["env"].VUE_APP_DATADOG_APPLICATIONID,
    datadogClientToken: window["env"].VUE_APP_DATADOG_CLIENTTOKEN,
    datadogService: window["env"].VUE_APP_DATADOG_SERVICE,
    datadogEnv: window["env"].VUE_APP_DATADOG_ENV,
    datadogSite: window["env"].VUE_APP_DATADOG_SITE,
    datadogVersion: window["env"].VUE_APP_DATADOG_VERSION,
    buildtype: window["env"].VUE_APP_BUILD_TYPE,
    pendoApiKey: window["env"].VUE_APP_PENDO_API_KEY,

    noviaIdentityServerUrl: window["env"].VUE_APP_NOVIA_IDENTITY_SERVER_URL,
    noviaInvestorZoneUrl: window["env"].VUE_APP_NOVIA_INVESTORZONE_URL,
    noviaInvestorZoneClientId: window["env"].VUE_APP_NOVIA_INVESTORZONE_CLIENTID,
    noviaInvestorZoneApi: window["env"].VUE_APP_NOVIA_INVESTORZONE_API
}