import { ContentfulNoBalancePage } from "@/typings/contentful";
import { NoBalancePageModel } from "@/typings/no-balance-page";
import { Entry } from "contentful";
import { ContentfulKeys } from "./contentful-keys";
import ContentfulService from "./ContentfulService";

export default class NoBalancePageContentfulService extends ContentfulService {
    public getNoBalancePageContent(): Promise<NoBalancePageModel> {
        return this.getContentfulEntry<ContentfulNoBalancePage, NoBalancePageModel>(
            ContentfulKeys.NoBalancePage,
            (result: Entry<ContentfulNoBalancePage>) => {
                return {
                    pageHeading: result.fields.pageHeading,
                    body: result.fields.body
                };
            }
        );
    }
}
