














































































import { Component, Mixins, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { BRow, BCol, BFormRadioGroup, BFormRadio, BTable, BCollapse, BCardHeader, BCard } from "bootstrap-vue";
import FluidContainer from "../components/FluidContainer.vue";

import Skeleton from "../components/Skeleton.vue";
import WrapperPortfolioSummaryBanner from "../components/WrapperPortfolioSummaryBanner.vue";

import _ from "lodash";
import { Wrapper } from "../typings/wrapper";

import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";
import AuthMixin from "../mixins/AuthMixin";
import { Namespaces } from "../store/namespaces";
import { LOAD_WRAPPER, LOAD_WRAPPER_STATEMENT_CONTENT } from "../store/action-types";
import { WrapperStatementHeaders, WrapperStatementRadioHeaders, WrapperStatementTableHeaders } from "../typings/wrapper-statement";
import {
    GET_WRAPPER_BY_ID,
    GET_WRAPPER_STATEMENT_CONTENT,
    GET_WRAPPER_STATEMENT_RADIO_HEADERS,
    GET_WRAPPER_STATEMENT_TABLE_HEADERS,
} from "../store/getters";
import RouteMixin from "../mixins/RouteMixin";
import { WrapperStatementGroup, WrapperStatementsApiResult } from "../typings/api-service";
import StatementsMixin from "../mixins/StatementsMixin";
import { TableField } from "../typings/table";
import ReactivePageTitle from "@/components/ReactivePageTitle.vue";
import { getRichTextContentWithHyperlinks } from "@/helpers/contentful-helper";

@Component({
    components: {
        FluidContainer,
        BRow,
        BCol,
        BFormRadioGroup,
        BFormRadio,
        BTable,
        BCollapse,
        BCardHeader,
        BCard,
        Skeleton,
        WrapperPortfolioSummaryBanner,
        ReactivePageTitle,
    },
    computed: mapGetters({
        getWrapperById: `${Namespaces.Wrapper}/${GET_WRAPPER_BY_ID}`,
        getWrapperStatementContent: `${Namespaces.WrapperStatement}/${GET_WRAPPER_STATEMENT_CONTENT}`,
        getWrapperStatementRadioHeadings: `${Namespaces.WrapperStatement}/${GET_WRAPPER_STATEMENT_RADIO_HEADERS}`,
        getWrapperStatementTableHeadings: `${Namespaces.WrapperStatement}/${GET_WRAPPER_STATEMENT_TABLE_HEADERS}`,
    }),
})
export default class WrapperStatementPage extends Mixins(ErrorHandlerMixin, AuthMixin, RouteMixin, StatementsMixin) {
    private getWrapperById!: Function;
    private getWrapperStatementContent!: WrapperStatementHeaders;
    private getWrapperStatementRadioHeadings!: WrapperStatementRadioHeaders;
    private getWrapperStatementTableHeadings!: WrapperStatementTableHeaders;
    private statementGroup: Array<WrapperStatementGroup> = [];
    private dataLoaded = false;

    public selectedInterval = 6;
    public noviaChargesStatementGroupName = 'Novia Charges';

    @Watch("selectedInterval")
    private selectedIntervalChanged() {
        this.getWrapperStatementsFromApi();
    }

    public get wrapper(): Wrapper {
        return this.getWrapperById(this.idFromRoute);
    }

    public get wrapperStatementGroup(): Array<WrapperStatementGroup> {
        return this.statementGroup;
    }

    public get dearCeoLetterLabel(): string{
        return getRichTextContentWithHyperlinks(this.getWrapperStatementContent.dearCeoLetterLabel);
    }

    public get showSkeleton(): boolean {
        return _.isEmpty(this.wrapper);
    }

    public get showDataSkeleton(): boolean {
        return !this.dataLoaded || _.isEmpty(this.wrapper);
    }

    public get tableFields(): Array<TableField> {
        return [
            {
                key: "transaction",
                label: this.getWrapperStatementTableHeadings.transactionNameTableHeader,
            },
            {
                key: "value",
                label: this.getWrapperStatementTableHeadings.valueTableHeader,
                thClass: "text-right",
                tdClass: "text-right",
            },
        ];
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.WrapperStatement}/${LOAD_WRAPPER_STATEMENT_CONTENT}`)
            .catch((error) => this.handleError(error.response?.status));
        this.$store
            .dispatch(`${Namespaces.Wrapper}/${LOAD_WRAPPER}`, { wrapperId: this.idFromRoute })
            .catch((error) => this.handleError(error.response?.status));
    }

    public mounted(): void {
        if (this.idFromRoute) {
            this.getWrapperStatementsFromApi();
        }
    }

    private getWrapperStatementsFromApi(): void {
        this.dataLoaded = false;
        this.$api
            .getWrapperStatements(this.idFromRoute as number, this.selectedInterval)
            .then((result: WrapperStatementsApiResult) => {
                this.statementGroup = result.statementGroup;
                this.dataLoaded = true;
            })
            .catch((error) => this.handleError(error.response?.status));
    }
}
