import NotImpersonatingContentfulService from "@/services/contentful/NotImpersonatingContentfulService";
import { NotImpersonatingContent } from "@/typings/not-impersonating-page";
import { Commit } from "vuex";
import { LOAD_NOT_IMPERSONATING_CONTENT } from "../action-types";
import { GET_NOT_IMPERSONATING_CONTENT } from "../getters";
import { SAVE_NOT_IMPERSONATING_CONTENT, SET_NOT_IMPERSONATING_LOADED } from "../mutation-types";
import { NotImpersonatingPageState } from "../typings/not-impersonating-page";

const contentful = new NotImpersonatingContentfulService();

const state = (): NotImpersonatingPageState => ({
    notImpersonatingContent: {
        pageHeading: "",
        noInvestorContextMessage: "",
        notLoggedInMessage: ""
    },
    isLoaded: false
});

const getters = {
    [GET_NOT_IMPERSONATING_CONTENT]: (state: NotImpersonatingPageState): NotImpersonatingContent => {
        return {
            pageHeading: state.notImpersonatingContent.pageHeading,
            noInvestorContextMessage: state.notImpersonatingContent.noInvestorContextMessage,
            notLoggedInMessage: state.notImpersonatingContent.notLoggedInMessage
        };
    }
};

const mutations = {
    [SAVE_NOT_IMPERSONATING_CONTENT](state: NotImpersonatingPageState, model: NotImpersonatingContent) {
        state.notImpersonatingContent = model;
    },
    [SET_NOT_IMPERSONATING_LOADED](state: NotImpersonatingPageState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    async [LOAD_NOT_IMPERSONATING_CONTENT]({ commit, state }: { commit: Commit; state: NotImpersonatingPageState }) {
        if (!state.isLoaded) {
            contentful.getNotImpersonatingPageContent().then((result: NotImpersonatingContent) => {
                commit(SAVE_NOT_IMPERSONATING_CONTENT, result);
                commit(SET_NOT_IMPERSONATING_LOADED, true);
            });
        }
    }
};

export const NotImpersonatingModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
