















































/* eslint no-use-before-define: 0 */
import { Component, Mixins } from "vue-property-decorator";
import { BRow, BCol } from "bootstrap-vue";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";
import AuthMixin from "../mixins/AuthMixin";
import _ from "lodash";
import { mapGetters } from "vuex";

import FluidContainer from "../components/FluidContainer.vue";
import SummaryBanner from "../components/summary-banner/SummaryBanner.vue";
import SummaryBannerHeader from "../components/summary-banner/SummaryBannerHeader.vue";
import SummaryBannerValue from "../components/summary-banner/SummaryBannerValue.vue";
import SummaryBannerContainer from "../components/summary-banner/SummaryBannerContainer.vue";
import AssetAllocationPanel from "../components/chart/AssetAllocationPanel.vue";
import PortfolioBreakdown from "../components/portfolio/PortfolioBreakdown.vue";
import Skeleton from "../components/Skeleton.vue";
import PortfolioChartTable from "../components/chart/PortfolioChartTable.vue";

import { Namespaces } from "../store/namespaces";
import { LOAD_INVESTOR_DETAILS, LOAD_PORTFOLIO_DETAILS, LOAD_PORTFOLIO_HEADINGS } from "../store/action-types";
import { Portfolio } from "../typings/portfolio";
import { PortfolioAssetAllocationTableHeadings, PortfolioHeadings } from "../typings/portfolio-page";
import { ChartTableDataPoint } from "../typings/chart-table";
import {
    GET_CHART_TABLE_HEADINGS,
    GET_INVESTOR_DETAILS,
    GET_PORTFOLIO_DETAILS,
    GET_PORTFOLIO_PAGE_HEADINGS,
} from "../store/getters";
import SummaryBannerMixin from "../mixins/SummaryBannerMixin";
import { EtlInformation } from "../typings/api-service";
import { InvestorDetails } from "@/typings/investor-details";
import ReactivePageTitle from "@/components/ReactivePageTitle.vue";
import PortfolioSummaryBanner from "@/components/PortfolioSummaryBanner.vue";
import { convertToCashInFlightDonut, convertToCashInFlightSectors } from "@/helpers/donut-chart-data-helper";
import { AssetAllocationDonutData } from "@/typings/asset-allocation-donut-data";

@Component({
    components: {
        BRow,
        BCol,
        FluidContainer,
        SummaryBanner,
        SummaryBannerHeader,
        SummaryBannerValue,
        SummaryBannerContainer,
        AssetAllocationPanel,
        PortfolioBreakdown,
        Skeleton,
        PortfolioChartTable,
        PortfolioSummaryBanner,
        ReactivePageTitle,
    },
    computed: mapGetters({
        getInvestorDetails: `${Namespaces.InvestorDetails}/${GET_INVESTOR_DETAILS}`,
    }),
})
export default class HomePage extends Mixins(ErrorHandlerMixin, AuthMixin, SummaryBannerMixin) {
    private etlInformation: EtlInformation = {} as EtlInformation;
    public getInvestorDetails!: InvestorDetails;

    public get currentUserName(): string {
        return this.getInvestorDetails.investorName;
    }
    public get portfolioValue(): string {
        return this.getTotalValueContent(this.portfolioHeadings.portfolioValueTitle, this.portfolioDetails.totalValue);
    }

    public get portfolioSummaryDateTitle(): string {
        return this.portfolioHeadings.portfolioSummaryDateTitle.replace("#DATE#", this.etlInformation.etlDateMessage);
    }

    public get portfolioHeadings(): PortfolioHeadings {
        return this.$store.getters[`${Namespaces.Portfolio}/${GET_PORTFOLIO_PAGE_HEADINGS}`];
    }

    public get portfolioDetails(): Portfolio {
        return this.$store.getters[`${Namespaces.Portfolio}/${GET_PORTFOLIO_DETAILS}`];
    }

    public get showSkeleton(): boolean {
        return !this.$store.state.portfolio.isPortfolioDataLoaded || _.isEmpty(this.etlInformation);
    }

    public get tableHeaders(): PortfolioAssetAllocationTableHeadings {
        return this.$store.getters[`${Namespaces.Portfolio}/${GET_CHART_TABLE_HEADINGS}`];
    }

    public get chartData(): Array<AssetAllocationDonutData> {
        const chartDataArray = convertToCashInFlightDonut(this.portfolioDetails.assetAllocation);

        return chartDataArray;
    }

    public get tableData(): Array<ChartTableDataPoint> {
        const chartTableData: Array<ChartTableDataPoint> = convertToCashInFlightSectors(
            this.portfolioDetails.assetAllocation
        );
        return chartTableData;
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.Portfolio}/${LOAD_PORTFOLIO_HEADINGS}`)
            .catch((error) => this.handleError(error.response?.status));
        this.$store
            .dispatch(`${Namespaces.InvestorDetails}/${LOAD_INVESTOR_DETAILS}`)
            .catch((error) => this.handleError(error.response?.status));
    }

    public mounted(): void {
        this.getAuthenticatedUser().then(() => {
            this.$api.getEtlDate().then((result) => (this.etlInformation = result));
            this.$store
                .dispatch(`${Namespaces.Portfolio}/${LOAD_PORTFOLIO_DETAILS}`)
                .catch((error) => this.handleError(error.response?.status));
        });
    }
}
