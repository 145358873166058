import { Entry } from "contentful";
import { ContentfulKeys } from "./contentful-keys";
import ContentfulService from "./ContentfulService";

import { ContentfulOffPlatformAssetsPage } from "@/typings/contentful";
import { OffPlatformAssetsPageHeadings } from "@/typings/off-platform-assets-page";

export default class OffPlatformAssetsContentfulService extends ContentfulService {
    public getOffPlatformAssetsPageContent(): Promise<OffPlatformAssetsPageHeadings> {
        return this.getContentfulEntry<ContentfulOffPlatformAssetsPage, OffPlatformAssetsPageHeadings>(
            ContentfulKeys.OffPlatformAssets,
            (result: Entry<ContentfulOffPlatformAssetsPage>) => {
                return {
                    offPlatformAssetsTitle: result.fields.offPlatformAssetsTitle,
                    totalValueHeading: result.fields.totalValueHeading,
                    assetDetailsHeading: result.fields.assetDetailsHeading,
                    assetNameTableHeading: result.fields.assetNameTableHeading,
                    valueTableHeading: result.fields.valueTableHeading
                };
            }
        );
    }
}
