








import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {}
})
export default class SummaryBannerActionButton extends Vue {
    @Prop({
        default: ""
    })
    public href!: string;
    public isActive!: boolean;
}
