import { Commit } from "vuex";
import ReportingPeriodContentfulService from "@/services/contentful/ReportingPeriodContentfulService";
import { LOAD_VALUATION_CONTENT } from "../action-types";
import { GET_REPORTING_PERIOD_RADIO_CONTENT, GET_VALUATION_TABLE_CONTENT } from "../getters";
import {
    SAVE_REPORTING_PERIOD_RADIO_CONTENT,
    SAVE_REPORTING_PERIOD_TABLE_CONTENT,
    SET_REPORTING_PERIOD_LOADED_FLAG
} from "../mutation-types";

import { ReportingPeriodRadioContent, ValuationTableHeadings } from "@/typings/reporting-period";
import { ValuationState } from "../typings/valuation";
import { ContentfulReportingPeriodContent } from "@/typings/contentful";

const contentful = new ReportingPeriodContentfulService();

const state = (): ValuationState => ({
    reportingPeriod: {
        reportingPeriod: "",
        lastSixMonthsRadioLabel: "",
        lastTwelveMonthsRadioLabel: "",
        valuationPeriod: ""
    },
    valuationTableContent: {
        startValue: "",
        moneyIn: "",
        moneyOut: "",
        endValue: "",
        changePercentage: ""
    },
    isLoaded: false
});

const getters = {
    [GET_REPORTING_PERIOD_RADIO_CONTENT]: (state: ValuationState): ReportingPeriodRadioContent => {
        return state.reportingPeriod;
    },
    [GET_VALUATION_TABLE_CONTENT]: (state: ValuationState): ValuationTableHeadings => {
        return state.valuationTableContent;
    }
};

const mutations = {
    [SAVE_REPORTING_PERIOD_RADIO_CONTENT](state: ValuationState, model: ReportingPeriodRadioContent) {
        state.reportingPeriod = model;
    },
    [SAVE_REPORTING_PERIOD_TABLE_CONTENT](state: ValuationState, model: ValuationTableHeadings) {
        state.valuationTableContent = model;
    },
    [SET_REPORTING_PERIOD_LOADED_FLAG](state: ValuationState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    [LOAD_VALUATION_CONTENT]({ commit, state }: { commit: Commit; state: ValuationState }) {
        if (!state.isLoaded) {
            return contentful.getReportingPeriodContent().then((result: ContentfulReportingPeriodContent) => {
                commit(SAVE_REPORTING_PERIOD_RADIO_CONTENT, {
                    reportingPeriod: result.reportingPeriod,
                    lastSixMonthsRadioLabel: result.lastSixMonthsRadioLabel,
                    lastTwelveMonthsRadioLabel: result.lastTwelveMonthsRadioLabel,
                    valuationPeriod: result.valuationPeriod
                });
                commit(SAVE_REPORTING_PERIOD_TABLE_CONTENT, {
                    startValue: result.startValue,
                    moneyIn: result.moneyIn,
                    moneyOut: result.moneyOut,
                    endValue: result.endValue,
                    changePercentage: result.changePercentage
                });
                commit(SET_REPORTING_PERIOD_LOADED_FLAG, true);
            });
        }
    }
};

export const ValuationModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
