



























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import moment from "moment";

import { BRow, BCol, BTable, BPagination, BLink, BAlert } from "bootstrap-vue";
import Skeleton from "../Skeleton.vue";

import { Namespaces } from "../../store/namespaces";
import { DocumentsTableHeadings } from "../../typings/documents-page";
import { GET_DOCUMENTS_CONTENT, GET_DOCUMENTS_TABLE_HEADINGS } from "../../store/getters";

import { InvestorDocument } from "../../typings/investor-document";

@Component({
    components: {
        BRow,
        BCol,
        BTable,
        BPagination,
        BLink,
        BAlert,
        Skeleton
    },
    computed: mapGetters({
        getDocumentsPageHeadings: `${Namespaces.Documents}/${GET_DOCUMENTS_CONTENT}`,
        getDocumentsTableHeadings: `${Namespaces.Documents}/${GET_DOCUMENTS_TABLE_HEADINGS}`
    })
})
export default class DocumentsPage extends Vue {
    public getDocumentsTableHeadings!: DocumentsTableHeadings;
    public isError = false;

    private perPage = 10;
    private currentPage = 1;
    private sortBy = "creationDate";

    public errorMessage = "Sorry, there was an error fetching the document";

    public get resultsFound() {
        return this.GetDocuments.length > 0;
    }

    public get tableFields() {
        return [
            {
                key: "documentName",
                label: this.getDocumentsTableHeadings.documentNameTableHeading,
                sortable: true,
                thAttr: {
                    "data-test-id": "document-name"
                }
            },
            {
                key: "documentType",
                label: this.getDocumentsTableHeadings.documentTypeTableHeading,
                sortable: true,
                thAttr: {
                    "data-test-id": "document-type"
                }
            },
            {
                key: "documentCategory",
                label: this.getDocumentsTableHeadings.documentCategoryTableHeading,
                sortable: true,
                thAttr: {
                    "data-test-id": "document-catagory"
                }
            },
            {
                key: "creationDate",
                label: this.getDocumentsTableHeadings.dateTableHeading,
                sortable: true,
                thAttr: {
                    "data-test-id": "document-date"
                }
            },
            {
                key: "documentId",
                label: this.getDocumentsTableHeadings.downloadTableHeading,
                sortable: false
            }
        ];
    }

    public get tableData() {
        return this.GetDocuments.map((document: InvestorDocument) => {
            return {
                documentName: document.name,
                documentType: document.documentType,
                documentCategory: document.documentCategory,
                creationDate: new Date(document.creationDate),
                documentId: document.documentId
            };
        });
    }

    public get totalRows() {
        return this.GetDocuments.length;
    }

    public getFriendlyDate(document: InvestorDocument) {
        return moment(document.creationDate, "YYYY-MM-DDTHH:mm:ssZ").format("DD/MM/YYYY");
    }

    public download(document: InvestorDocument) {
        this.$api.downloadDocument(document.documentId).catch(() => this.downloadError());
    }

    public downloadError() {
        this.isError = true;
    }

    public get showSkeleton(): boolean {
        return this.documents === null;
    }

    @Prop()
    public documents?: Array<InvestorDocument>;

    public get GetDocuments(): Array<InvestorDocument> {
        return this.documents ? this.documents : [];
    }
}
