








import { Component, Vue } from "vue-property-decorator";

import { BRow } from "bootstrap-vue";

@Component({
    components: {
        BRow
    }
})
export default class SummaryBannerActionButton extends Vue {}
