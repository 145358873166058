












import { Component, Prop, Vue } from "vue-property-decorator";
import { BNavbarToggle } from "bootstrap-vue";
import { IS_WHITELABELED } from "@/store/getters";
import { Namespaces } from "@/store/namespaces";

@Component({
    components: {
        BNavbarToggle
    }
})
export default class NavBarToggle extends Vue {
    @Prop()
    navbarCollapseId?: string;

    public get isWhitelabeled(): number | boolean {
        return this.$store.getters[`${Namespaces.CompanyInformation}/${IS_WHITELABELED}`];
    }
}
