import { Commit } from "vuex";
import WrapperPortfolioSummaryBannerContentfulService from "@/services/contentful/WrapperPortfolioSummaryBannerContentfulService";

import { Actions } from "../action-types";
import { SET_LABELLING, SET_STATE_LOADED } from "../mutation-types";

import { WrapperPortfolioSummaryBannerModuleState } from "../typings/wrapper-portfolio-summary-banner-module-state";
import { WrapperPortfolioSummaryBannerLabeling } from "@/typings/wrapper-portfolio-summary-banner-module";

import { GET_WRAPPER_PORTFOLIO_BANNER_LABELS } from "../getters";

const contentful = new WrapperPortfolioSummaryBannerContentfulService();

const moduleState = (): WrapperPortfolioSummaryBannerModuleState => ({
    labelling: {
        wrapperSummaryModelPortfolioLabel: "",
        wrapperSummaryTotalValueLabel: "",
    },
    isLoaded: false
});
const getters = {
    [GET_WRAPPER_PORTFOLIO_BANNER_LABELS]: (state: WrapperPortfolioSummaryBannerModuleState) => {
        return state.labelling;
    }
};

const mutations = {
    [SET_LABELLING](state: WrapperPortfolioSummaryBannerModuleState, labelling: WrapperPortfolioSummaryBannerLabeling) {
        state.labelling = labelling;
    },
    [SET_STATE_LOADED](state: WrapperPortfolioSummaryBannerModuleState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    async [Actions.LOAD_CONTENT]({ commit, state }: { commit: Commit; state: WrapperPortfolioSummaryBannerModuleState }) {
        if (!state.isLoaded) {
            contentful.getWrapperPortfolioSummaryBannerContent()
                .then((result: WrapperPortfolioSummaryBannerLabeling) => {
                    commit(SET_LABELLING, result);
                    commit(SET_STATE_LOADED, true);
                });
        }
    }
};

export const WrapperPortfolioSummaryBannerModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state: moduleState
};
