import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { INLINES } from "@contentful/rich-text-types";

export function getRichTextContentWithHyperlinks(input: any): string {
    const options = {
        renderNode: {
            [INLINES.HYPERLINK]: (node: any, next: any) => {
                return `<a href="${node.data.uri}" target="_blank">${next(node.content)}</a>`;
            }
        }
    };

    return documentToHtmlString(input, options);
}