import { Entry } from "contentful";
import { ContentfulKeys } from "./contentful-keys";
import ContentfulService from "./ContentfulService";
import { ContentfulInformationPage } from "@/typings/contentful";
import { InformationPage } from "@/typings/information-page";

export default class InformationContentfulService extends ContentfulService {
    public getInformationPageContent(): Promise<InformationPage> {
        return this.getContentfulEntry<ContentfulInformationPage, InformationPage>(
            ContentfulKeys.InformationPage,
            (result: Entry<ContentfulInformationPage>) => {
                return {
                    title: result.fields.title,
                    body: result.fields.body
                };
            }
        );
    }
}
