import { Commit } from "vuex";
import { SAVE_GLOBAL_CONTENT, SET_GLOBAL_CONTENT_LOADED } from "../mutation-types";
import { LOAD_GLOBAL_CONTENT } from "../action-types";
import { GET_GLOBAL_CONTENT } from "../getters";

import { GlobalContent } from "@/typings/global-content";
import { GlobalContentState } from "../typings/global-content";
import GlobalContentfulService from "@/services/contentful/GlobalContentfulService";

const contentful = new GlobalContentfulService();

const state = (): GlobalContentState => ({
    globalContent: {
        strapline: "",
        noviaBannerUrl: "",
        whitelabelledStrapline: ""
    },
    isLoaded: false
});

const getters = {
    [GET_GLOBAL_CONTENT]: (state: GlobalContentState): GlobalContent | null => {
        return state.globalContent;
    }
};

const mutations = {
    [SAVE_GLOBAL_CONTENT](state: GlobalContentState, globalContent: GlobalContent) {
        state.globalContent = globalContent;
    },
    [SET_GLOBAL_CONTENT_LOADED](state: GlobalContentState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    async [LOAD_GLOBAL_CONTENT]({ commit, state }: { commit: Commit; state: GlobalContentState }) {
        if (!state.isLoaded) {
            contentful.getGlobalContent().then((globalContent: GlobalContent) => {
                commit(SAVE_GLOBAL_CONTENT, {
                    ...globalContent,
                    noviaBannerUrl: `https:${globalContent.noviaBannerUrl}`
                });
                commit(SET_GLOBAL_CONTENT_LOADED, true);
            });
        }
    }
};

export const GlobalContentModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
