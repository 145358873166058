import {
    ContentfulPortfolioSummaryBannerLabellingContent,
    PortfolioSummaryBannerLabelling
} from "@/typings/portfolio-summary-banner-module";
import { Entry } from "contentful";
import { ContentfulKeys } from "./contentful-keys";
import ContentfulService from "./ContentfulService";

export default class PortfolioSummaryBannerContentfulService extends ContentfulService {
    public getPortfolioSummaryBannerContent(): Promise<ContentfulPortfolioSummaryBannerLabellingContent> {
        return this.getContentfulEntry<ContentfulPortfolioSummaryBannerLabellingContent, PortfolioSummaryBannerLabelling>(
            ContentfulKeys.PortfolioSummaryBannerLabelling,
            (result: Entry<PortfolioSummaryBannerLabelling>) => {
                return {
                    portfolioSummaryValueLabel: result.fields.portfolioSummaryValueLabel,
                    portfolioSummaryAsAtDateLabel: result.fields.portfolioSummaryAsAtDateLabel
                };
            }
        );
    }
}