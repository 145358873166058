import { UserManager, WebStorageStateStore, User, UserManagerSettings } from "oidc-client";
import { environment } from '../environment'

export default class AuthService {
    private userManager: UserManager;

    constructor() {
        const STS_DOMAIN = environment.noviaIdentityServerUrl;

        const settings: UserManagerSettings = {
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            authority: STS_DOMAIN,
            client_id: environment.noviaInvestorZoneClientId,
            redirect_uri: `${environment.noviaInvestorZoneUrl}/callback.html`,
            automaticSilentRenew: true,
            silent_redirect_uri: `${environment.noviaInvestorZoneUrl}/silent-renew.html`,
            response_type: "code",
            scope: "openid profile offline_access investorzone-api",
            filterProtocolClaims: true
        };

        this.userManager = new UserManager(settings);
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    public login(): Promise<void> {
        return this.userManager.signinRedirect();
    }

    public logout(): Promise<void> {
        return this.userManager.signoutRedirect();
    }

    public getAccessToken(): Promise<string | null> {
        return this.userManager.getUser().then((data: User | null) => {
            return data ? data.access_token : null;
        });
    }

    public removeInvestorContext(): Promise<User> {
        return this.userManager.signinSilent({ acr_values: "remove_investor_context" });
    }

    public removeUser(): Promise<void> {
        return this.userManager.removeUser();
    }
}
