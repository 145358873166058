






import { Component, Vue } from "vue-property-decorator";
import { BContainer } from "bootstrap-vue";

@Component({
    components: {
        BContainer
    }
})
export default class FluidContainer extends Vue {}
