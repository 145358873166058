import { ContentfulBrowserUnsupportedPageContent } from "../../typings/contentful";
import { UnsupportedBrowserPageModel } from "../../typings/unsupported-browser-page-model";

import { Entry } from "contentful";
import { ContentfulKeys } from "./contentful-keys";
import ContentfulService from "./ContentfulService";

export default class BrowserNotSupportedContentfulService extends ContentfulService {
    
    public getBrowserNotSupportedPageContent(): Promise<UnsupportedBrowserPageModel> {
        return this.getContentfulEntry<ContentfulBrowserUnsupportedPageContent, UnsupportedBrowserPageModel>(
            ContentfulKeys.BrowserNotSupportedPage,
            (result: Entry<ContentfulBrowserUnsupportedPageContent>) => {
                return {
                    header: result.fields.header,
                    body: result.fields.body
                };
            }
        );
    }
}