





















import { Component, Mixins } from "vue-property-decorator";
import { BRow, BCol } from "bootstrap-vue";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

import SummaryBanner from "../components/summary-banner/SummaryBanner.vue";
import SummaryBannerContainer from "../components/summary-banner/SummaryBannerContainer.vue";
import SummaryBannerHeader from "../components/summary-banner/SummaryBannerHeader.vue";
import FluidContainer from "../components/FluidContainer.vue";
import { mapGetters } from "vuex";
import { Namespaces } from "../store/namespaces";
import { GET_NO_BALANCE_PAGE_CONTENT } from "../store/getters";
import { LOAD_NO_BALANCE_PAGE_CONTENT } from "../store/action-types";
import ErrorHandlerMixin from "../mixins/ErrorHandlerMixin";
import { NoBalancePageModel } from "../typings/no-balance-page";

@Component({
    components: {
        BRow,
        BCol,
        SummaryBanner,
        SummaryBannerContainer,
        SummaryBannerHeader,
        FluidContainer
    },
    computed: mapGetters({
        getNoBalancePageContent: `${Namespaces.NoBalancePage}/${GET_NO_BALANCE_PAGE_CONTENT}`
    })
})
export default class NoBalancePage extends Mixins(ErrorHandlerMixin) {
    public getNoBalancePageContent!: NoBalancePageModel;

    public get body(): string {
        return documentToHtmlString(this.getNoBalancePageContent.body);
    }

    public created(): void {
        this.$store
            .dispatch(`${Namespaces.NoBalancePage}/${LOAD_NO_BALANCE_PAGE_CONTENT}`)
            .catch(error => this.handleError(error.response?.status));
    }
}
