





















import { Component, Vue } from "vue-property-decorator";
import { BRow, BCol } from "bootstrap-vue";
import SummaryBanner from "../components/summary-banner/SummaryBanner.vue";
import SummaryBannerContainer from "../components/summary-banner/SummaryBannerContainer.vue";
import SummaryBannerHeader from "../components/summary-banner/SummaryBannerHeader.vue";
import FluidContainer from "../components/FluidContainer.vue";
import { Namespaces } from "../store/namespaces";
import { ErrorPageContent } from "../typings/error-page";
import { GET_ERROR_PAGE_CONTENT } from "../store/getters";
import { mapGetters } from "vuex";
import { LOAD_ERROR_PAGE_CONTENT } from "../store/action-types";

@Component({
    components: {
        BRow,
        BCol,
        SummaryBanner,
        SummaryBannerContainer,
        SummaryBannerHeader,
        FluidContainer,
    },
    props: {
        statusCode: Number,
    },
    computed: mapGetters({
        getErrorPageContent: `${Namespaces.ErrorPage}/${GET_ERROR_PAGE_CONTENT}`,
    }),
})
export default class ErrorPage extends Vue {
    public getErrorPageContent!: ErrorPageContent;
    public unixErrorCode?: string;

    public created(): void {
        this.$store.dispatch(`${Namespaces.ErrorPage}/${LOAD_ERROR_PAGE_CONTENT}`);
    }

    public getHeading() {
        switch (this.$props.statusCode) {
            case 404:
                return this.getErrorPageContent.pageNotFoundHeading;
            case 403:
                return this.getErrorPageContent.forbiddenHeading;
            default:
                return this.getErrorPageContent.serverErrorHeading;
        }
    }

    public getBody() {
        switch (this.$props.statusCode) {
            case 404:
                return this.getErrorPageContent.pageNotFoundBody;
            case 403:
                return this.getErrorPageContent.forbiddenBody;
            default:
                this.getUnixErrorCode()
                return this.getErrorPageContent.serverErrorBody;
        }
    }

    public getUnixErrorCode(){
        const unixDate = Math.round((new Date()).getTime() / 1000);
        this.unixErrorCode = "0x" + unixDate.toString(16);
    }
}
