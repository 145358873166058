








import { Component, Prop, Vue } from "vue-property-decorator";
import IconService from "../../services/IconService";

const icon = new IconService();

@Component({
    components: {}
})
export default class PanelIcon extends Vue {
    @Prop()
    iconType?: string;

    get iconClass(): string {
        return icon.getIconClassFromIconType(this.iconType ?? "");
    }
}
