import { Commit } from "vuex";
import { LOAD_NAVIGATION_PAGES } from "../action-types";
import { SAVE_NAVIGATION_PAGES, SET_NAVIGATION_PAGES_LOADED } from "../mutation-types";

import { Page } from "@/typings/page";
import { GET_NAVIGATION_PAGES } from "../getters";
import GlobalContentfulService from "@/services/contentful/GlobalContentfulService";

const contentful = new GlobalContentfulService();

const state = (): NavigationPageState => ({
    pages: [],
    isLoaded: false
});

const getters = {
    [GET_NAVIGATION_PAGES]: (state: NavigationPageState): Page[] | null => {
        return state.pages;
    }
};

const mutations = {
    [SAVE_NAVIGATION_PAGES](state: NavigationPageState, pages: Page[]) {
        state.pages = [...pages];
    },
    [SET_NAVIGATION_PAGES_LOADED](state: NavigationPageState, isLoaded: boolean) {
        state.isLoaded = isLoaded;
    }
};

const actions = {
    async [LOAD_NAVIGATION_PAGES]({ commit, state }: { commit: Commit; state: NavigationPageState }) {
        if (!state.isLoaded) {
            contentful.getAllNavigationPages().then((result: Page[]) => {
                commit(SAVE_NAVIGATION_PAGES, result);
                commit(SET_NAVIGATION_PAGES_LOADED, true);
            });
        }
    }
};

export const NavigationPagesModule = {
    namespaced: true,
    getters,
    mutations,
    actions,
    state
};
