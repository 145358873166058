import { Entry } from "contentful";
import ContentfulService from "./ContentfulService";
import ChartColourService from "../ChartColourService";
import { ContentfulKeys } from "./contentful-keys";

import { MappedPortfolioAssetAllocationChart } from "@/typings/chart-table";
import {
    ContentfulAssetAllocationChart,
    ContentfulMoneyInOutPage,
    ContentfulPortfolio,
    ContentfulPortfolioValuationsPage
} from "@/typings/contentful";
import { PortfolioHeadings } from "@/typings/portfolio-page";
import { MoneyInOutPageHeadings } from "@/typings/moneyinout-page";
import { PortfolioValuationPageContent } from "@/typings/portfolio-valuations";

export default class PortfolioContentfulService extends ContentfulService {
    public getPortfolioHeadings(): Promise<PortfolioHeadings> {
        return this.getContentfulEntry<ContentfulPortfolio, PortfolioHeadings>(
            ContentfulKeys.Portfolio,
            (result: Entry<ContentfulPortfolio>) => {
                return {
                    portfolioSummaryTitle: result.fields.portfolioSummaryTitle,
                    portfolioSummaryDateTitle: result.fields.portfolioSummaryDateTitle,
                    portfolioAssetAllocationHeader: result.fields.portfolioAssetAllocationHeader,
                    portfolioBreakdownHeader: result.fields.portfolioBreakdownHeader,
                    portfolioValueTitle: result.fields.portfolioValueTitle,
                    sectorTableHeading: result.fields.sectorTableHeading,
                    percentageTableHeading: result.fields.percentageTableHeading,
                    consolidatedValueWrapperHeading: result.fields.consolidatedValueWrapperHeading,
                    relatedInvestorHeader: result.fields.relatedInvestorHeader,
                    offPlatformAssetsExcludedTotalMessage: result.fields.offPlatformAssetsExcludedTotalMessage
                    
                };
            }
        );
    }

    public getPortfolioAssetAllocationChart(): Promise<MappedPortfolioAssetAllocationChart> {
        return this.getContentfulEntry<ContentfulAssetAllocationChart, MappedPortfolioAssetAllocationChart>(
            ContentfulKeys.PortfolioAssetAllocationChart,
            (result: Entry<ContentfulAssetAllocationChart>) => {
                const chartColourService = new ChartColourService();
                const mappedColours = chartColourService.getColoursMapFromContentfulKeys(result.fields);

                return {
                    chartColours: mappedColours
                };
            }
        );
    }

    public getPortfolioStatementContent(): Promise<MoneyInOutPageHeadings> {
        return this.getContentfulEntry<ContentfulMoneyInOutPage, MoneyInOutPageHeadings>(
            ContentfulKeys.MoneyInOut,
            (result: Entry<ContentfulMoneyInOutPage>) => {
                return {
                    pageHeading: result.fields.pageHeading,
                    totalValueTitle: result.fields.totalValueTitle,
                    wrapperValue: result.fields.wrapperValue,
                    transactionNameTableHeading: result.fields.transactionNameTableHeading,
                    dateTableHeading: result.fields.dateTableHeading,
                    valueTableHeading: result.fields.valueTableHeading,
                    asAtDate: result.fields.asAtDate
                };
            }
        );
    }

    public getPortfolioValuationsContent(): Promise<ContentfulPortfolioValuationsPage> {
        return this.getContentfulEntry<ContentfulPortfolioValuationsPage, PortfolioValuationPageContent>(
            ContentfulKeys.PortfolioValuation,
            (result: Entry<ContentfulPortfolioValuationsPage>) => {
                return {
                    portfolioValuationTitle: result.fields.portfolioValuationTitle,
                    valuationPeriod: result.fields.valuationPeriod,
                    lastSixMonthsRadioHeading: result.fields.lastSixMonthsRadioHeading,
                    lastTwelveMonthsRadioHeading: result.fields.lastTwelveMonthsRadioHeading,
                    valuationChartTitle: result.fields.valuationChartTitle,
                    valuationChartColour: result.fields.valuationChartColour,
                    valuationChartInformation: result.fields.valuationChartInformation
                };
            }
        );
    }
}
